import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import * as firebase from 'firebase/app';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RequestOptions, Headers, Http } from '@angular/http';
import 'firebase/storage';
import { first, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OperationsService {

  item: Observable<any>;
  suppliercat: Observable<any>;
  mainstores: Observable<any>;
  templatecat: Observable<any>;
  clientVouchers: Observable<any>;
  userlist: Observable<any>;
  clientInfo: Observable<any>;
  storeslist: AngularFirestoreCollection<any>;
  user: Observable<any>;
  usersPro: AngularFirestoreDocument<any>;
  clientsPro: AngularFirestoreDocument<any>;
  clients: Observable<any>;
  suppliersRefPro: AngularFirestoreCollection<any>;
  suppliersPro: Observable<any>;
  userRef: AngularFireObject<any>;
  userDocRef: AngularFirestoreDocument<any>;
  signedInRef: AngularFireObject<any>;
  loggedInRef: AngularFireObject<any>;
  loggedIn: Observable<any>;
  signedIn: Observable<any>;
  promotionRef: AngularFireObject<any>;
  vouchers: Observable<any>;
  stores: Observable<any>;
  storeRef: AngularFireObject<any>;
  snapShot: any;
  voucherShot: any;
  vouchersShot: any;
  resetUserRef: AngularFireObject<any>;
  resetUserDocRef: AngularFirestoreDocument<any>;
  resetClientDocRef: AngularFirestoreDocument<any>;
  updateTempCatRef: AngularFireObject<any>;
  updateisBlockedRef: AngularFireObject<any>;
  updateisBlockedDocRef: AngularFirestoreDocument<any>;
  updateisFeaturedRef: AngularFireObject<any>;
  updateisFeaturedDocRef: AngularFirestoreDocument<any>;
  updateisRedeemDocRef: AngularFirestoreDocument<any>;
  signInStatusRef: AngularFireObject<any>;
  updateSuppCatRef: AngularFireObject<any>;
  loginRef: AngularFireObject<any>;
  getSuppRef: AngularFireObject<any>;
  getTempRef: AngularFireObject<any>;
  login: Observable<any>;
  companyRef: AngularFireObject<any>;
  singleVoucher: Observable<any>;
  getClientRef: AngularFireObject<any>;
  getOneVoucherRef: AngularFireObject<any>;
  getOneVoucherColRef: Observable<any>;
  getOneStoreVouchersRef: AngularFireObject<any>;
  getOneSupplierVouchersRef: Observable<any>;
  templateDocRef: Observable<any>;
  supplierDocRef: Observable<any>;
  getClientColRef: Observable<any>;
  updateSupplierDoc: AngularFirestoreDocument<any>;
  promotionDocRef: AngularFirestoreDocument<any>;

  constructor(private afd: AngularFireDatabase, private afs: AngularFirestore, public ht: Http) { }
  resetPassword(uid) {
    this.resetUserDocRef = this.afs.collection('/supplierProfile/').doc(uid);

    this.afs.collection('/supplierProfile/').doc(uid).valueChanges().pipe(take(1)).toPromise().then(data => {
      if (!data) {

        this.resetClientDocRef = this.afs.collection('/userProfile/').doc(uid);
        this.resetClientDocRef.update({
          restrictedPassword: '123456',
          tempPassword: 'modified'
        });
      } else {
        this.resetUserDocRef.update({
          restrictedPassword: '123456',
          tempPassword: 'modified'
        });
      }
    });
  }
  updateSupplierCategory(uid, cat) {
    this.afs.collection('/suppliercategories/').doc(uid).update({
      category: cat
    });
  }
  updateIsBlocked(uid, status) {
    this.updateisBlockedDocRef = this.afs.collection('/supplierProfile/').doc(uid);
    this.updateisBlockedDocRef.update({
      isBlocked: status
    });
  }
  updateisFeatured(uid, status) {
    this.updateisFeaturedDocRef = this.afs.collection('/supplierProfile/').doc(uid);
    this.updateisFeaturedDocRef.update({
      featured: status
    });
  }

  updateisRedeem(uid, status) {
    console.log(status);
    this.updateisRedeemDocRef = this.afs.collection('/supplierProfile/').doc(uid);
    return this.updateisRedeemDocRef.update({
      isRedeem: status
    });
  }
  getLoggedInStatus(uid) {
    this.signedInRef = this.afd.object('/signedIn/' + uid);
    return this.signedIn = this.signedInRef.valueChanges();
  }
  updatedLoggedStatus(uid) {
    this.loggedInRef = this.afd.object('/signedIn/' + uid);
    return this.loggedIn = this.loggedInRef.valueChanges();
  }
  updateSignInStatus(uid, trueOrFalse) {
    this.signInStatusRef = this.afd.object('/signedIn/' + uid);
    this.signInStatusRef.update({
      signedIn: trueOrFalse
    });
  }
  updateTemplateCategory(uid, cat) {
    this.updateTempCatRef = this.afd.object('/templatecategories/' + uid);
    this.updateTempCatRef.update({
      category: cat
    });
  }
  getOneSuppCatKey(cat) {
    return this.afs.collection('/suppliercategories', ref => ref.where('category', '==', cat)).snapshotChanges();
  }
  getOneTempCatKey(cat) {
    this.getTempRef = this.afd.object('/templatecategories');

    this.snapShot = this.getTempRef.query.orderByChild('category').equalTo(cat).once('child_added', (snapshot) => {

    });
    console.log(this.snapShot);
    return this.snapShot;
  }
  // checkAvailability() {
  //   var ref = firebase.database().ref('/userProfile');
  //   ref.once("value", (snapshot) => {
  //     console.log("Am I inside yet?");
  //     console.log(snapshot.hasChild('logbookHistory'));
  //   });
  // }
  getGarages() {
    this.item = this.afd.list('/garages').valueChanges();

    return this.item;
  }
  getVouchers() {
    this.vouchers = this.afs.collection('/vouchers').valueChanges();
    return this.vouchers;
  }
  getStores() {
    this.stores = this.afd.list('/clients').valueChanges();
    return this.stores;
  }
  getClientsUid() {

    const playersRef = firebase.database().ref('userProfile/');

    const uref = playersRef.orderByKey().on('child_added', function (data) {
      console.log(data.key);
    });
    // this.userRef = this.afd.object('/userProfile');
    return uref;
  }
  getClients() {
    this.userRef = this.afd.object('/userProfile');
    return this.user = this.userRef.valueChanges();
  }
  getClientsList() {
    this.userlist = this.afd.list('/userProfile').valueChanges();
    return this.user = this.userlist;
  }
  getClientsListId() {
    // this.userlist = this.afd.list('/userProfile').snapshotChanges();
    return this.stores = this.afs.collection('/supplierProfile').snapshotChanges();
    // return this.user = this.storeslist;
  }
  countClientVouchers(uid) {
    // return this.clientVouchers = this.afd.list('/userProfile/' + uid + '/vouchers/').valueChanges();
    return this.clientVouchers = this.afs.collection('/supplierVouchers/').doc(uid).collection('/vouchers/').valueChanges();
  }
  getTemplatesCat() {
    // this.templatecat = this.afd.list('/templatecategories').valueChanges();
    this.templatecat = this.afs.collection('/templatecategories').valueChanges();
    return this.templatecat;
  }
  getSupplierCat() {
    // this.suppliercat = this.afd.list('/suppliercategories').valueChanges();
    this.suppliercat = this.afs.collection('/suppliercategories').valueChanges();
    return this.suppliercat;
  }
  getMainshops() {
    return this.afs.collection('/mainstores').valueChanges();
  }
  getDetails(uid) {
    // this.userRef = this.afd.object('/userProfile/' + uid);
    this.usersPro = this.afs.collection('/userProfile/').doc(uid);
    return this.user = this.usersPro.valueChanges();
  }

  getClientsDetails(uid) {
    // this.userRef = this.afd.object('/userProfile/' + uid);
    this.clientsPro = this.afs.collection('/supplierProfile/').doc(uid);
    return this.clients = this.clientsPro.snapshotChanges();
  }
  getUserbyemail(email) {
    return this.ht.get('https://cors-anywhere.herokuapp.com/https://us-central1-voucher24-6e09b.cloudfunctions.net/getUserbyemail?email=' + email).pipe(
      map(res => res.json()) // or any other operator
    );
  }
  checkIfClientRegistered(uid) {
    return this.afs.collection('/userProfile/').doc(uid).valueChanges();
  }

  checkIfSupplierRegistered(uid) {
    return this.afs.collection('/supplierProfile/').doc(uid).valueChanges();
  }


  getAllSuppliers() {
    // this.userlist = this.afd.list('/userProfile').snapshotChanges();
    this.suppliersRefPro = this.afs.collection('/supplierProfile');
    return this.suppliersPro = this.suppliersRefPro.valueChanges();
    // return this.user = this.storeslist;
  }

  updateAddress(logourl, address) {
    const formData1 = new FormData();
    formData1.append('logourl', logourl);
    formData1.append('address', address);
    const response = this.ht.post('https://voucher24.co.za/voucher24/updateAddress.php', formData1).pipe(
      map(res => res.json()) // or any other operator
    ).subscribe(d => {
      console.log(d);
    });
    return response;
  }

  updateSupplierEmailAuth(key, email) {
    const response = this.ht.get('https://cors-anywhere.herokuapp.com/https://us-central1-voucher24-6e09b.cloudfunctions.net/updateEmail?uid=' + key + '&email=' + email).subscribe(dd => {
      console.log(dd);
    });
  }
  // tslint:disable-next-line:max-line-length
  updateSupplier(uid, address, cellno, email, category, file, commission, discount, serviceCharge, isComing, storename, isFeatured, specialText, promotionalText, isMain, selectedShop, mainFranchiseUrl, logourl, isUser) {
    if (!specialText) {
      specialText = ' store.';
    } else {
      specialText = specialText;
    }
    console.log(logourl);
    this.updateAddress(logourl, address);
    this.updateSupplierEmailAuth(uid, email);
    if (!file) {
      if (!promotionalText) {
        console.log(promotionalText);
        // this.afs.collection('/promotions/').doc(uid).delete();
        this.afd.object('/promotions/' + uid).remove();
        this.userDocRef = this.afs.collection('/supplierProfile/').doc(uid);
        return this.userDocRef.update({
          storename: storename,
          address: address,
          storecellno: cellno,
          catType: category,
          category: category,
          email: email,
          commission: commission,
          discount: discount,
          serviceCharge: serviceCharge,
          specialText: specialText,
          isMain: isMain,
          isUser: isUser,
          mainFranchise: selectedShop,
          mainFranchiseUrl: mainFranchiseUrl
        });
      } else {
        const promRef = this.afd.object('/promotions/' + uid);
        this.afd.object('/promotions/' + uid).valueChanges().pipe(take(1)).toPromise().then((dat: any) => {
          console.log(dat);
          if (!dat) {
            console.log(promRef);
            promRef.set({
              companyName: storename,
              promotionalText: promotionalText
            });
          } else {
            promRef.update({
              companyName: storename,
              promotionalText: promotionalText
            });
          }
        });
        this.userDocRef = this.afs.collection('/supplierProfile/').doc(uid);
        return this.userDocRef.update({
          storename: storename,
          address: address,
          storecellno: cellno,
          catType: category,
          category: category,
          email: email,
          commission: commission,
          discount: discount,
          serviceCharge: serviceCharge,
          specialText: specialText,
          isMain: isMain,
          isUser: isUser,
          mainFranchise: selectedShop,
          mainFranchiseUrl: mainFranchiseUrl
        });
      }
    } else {
      if (!promotionalText) {
        this.getUpdateStoreUid(uid, file, storename, isMain, mainFranchiseUrl);
        // this.afs.collection('/promotions/').doc(uid).delete();
        this.afd.object('/promotions/' + uid).remove();
        this.userDocRef = this.afs.collection('/supplierProfile/').doc(uid);
        return this.userDocRef.update({
          storename: storename,
          address: address,
          storecellno: cellno,
          catType: category,
          category: category,
          email: email,
          commission: commission,
          discount: discount,
          serviceCharge: serviceCharge,
          specialText: specialText,
          isMain: isMain,
          isUser: isUser,
          mainFranchise: selectedShop,
          mainFranchiseUrl: mainFranchiseUrl
        });
      } else {
        console.log('Promotions');
        const promRef = this.afd.object('/promotions/' + uid);
        this.afd.object('/promotions/' + uid).valueChanges().pipe(take(1)).toPromise().then((dat: any) => {
          console.log(dat);
          if (!dat) {
            console.log(promRef);
            promRef.set({
              companyName: storename,
              promotionalText: promotionalText
            });
          } else {
            promRef.update({
              companyName: storename,
              promotionalText: promotionalText
            });
          }
        });

        this.getUpdateStoreUid(uid, file, storename, isMain, mainFranchiseUrl);
        this.userDocRef = this.afs.collection('/supplierProfile/').doc(uid);
        return this.userDocRef.update({
          storename: storename,
          address: address,
          storecellno: cellno,
          catType: category,
          category: category,
          email: email,
          commission: commission,
          discount: discount,
          serviceCharge: serviceCharge,
          specialText: specialText,
          isMain: isMain,
          isUser: isUser,
          mainFranchise: selectedShop,
          mainFranchiseUrl: mainFranchiseUrl
        });
      }
    }
  }
  getPromotionalText(uid) {
    return this.afd.object('/promotions/' + uid).valueChanges();
  }
  updateCharges(uid, vuid, commission, discount, serviceCharge, specialText) {
    if (!specialText) {
      specialText = ' store.';
    } else {
      specialText = specialText;
    }
    this.updateSupplierDoc = this.afs.collection('/supplierVouchers/').doc(uid).collection('/vouchers/').doc(vuid);
    return this.updateSupplierDoc.update({
      commission: commission,
      discount: discount,
      serviceCharge: serviceCharge,
      specialText: specialText
    });
  }
  searchWithEmailAndPassword(email, password) {
    this.userRef = this.afd.object('/userProfile');
    const userInfo = this.userRef.query.orderByChild('email').equalTo(email).once('child_added', (snapshot) => {
      console.log('Nothing ' + snapshot);
    });
    return userInfo;
  }

  getVouchersUid(uid, vname) {
    console.log(vname);
    this.companyRef = this.afd.object('/userProfile/' + uid + '/vouchers' );
    const userInfo = this.companyRef.query.orderByChild('companyName').equalTo(vname).once('child_added', (snapshot) => {
    });
    return userInfo;
  }

  getClientByLogourl(url) {
    console.log(url);
    // this.companyRef = this.afd.object('/userProfile');
    // const userInfo = this.userRef.query.orderByChild('logoUrl').equalTo(url).once('child_added', (snapshot) => {
    // });

    this.clientInfo = this.afs.collection('/supplierProfile', ref => ref.where('logoUrl', '==', url)).snapshotChanges();
    return this.clientInfo;
  }
  getWithEmailAndPassword(key) {
    this.loginRef = this.afd.object('/userProfile/' + key);
    return this.login = this.loginRef.valueChanges();

  }
  getUpdateStoreUid(snapshotKey, file, storename, isMain, mainFranchiseUrl) {
    console.log(file);
    // this.storeRef = this.afd.object('/supplierProfile');

    console.log(snapshotKey);
    console.log(mainFranchiseUrl);
    const storageRef = firebase.storage().ref('/supplierProfile');
    console.log(storageRef);
    console.log(file);
    const uploadLogo = storageRef.child(snapshotKey).child('logo').child(file.name);
    console.log(uploadLogo);

    const uploadTaskLg = uploadLogo.put(file).then(data => {
      console.log(data);

      const storages = firebase.storage();
      const gsReference = storages.refFromURL('gs://voucher24-6e09b.appspot.com/supplierProfile/'
        + snapshotKey + '/logo/' + file.name);
      gsReference.getDownloadURL().then((url) => {
        console.log(url);
        const formData1 = new FormData();
        formData1.append('logoUrl', url);
        formData1.append('storename', storename);

        const res = this.ht.post('https://voucher24.co.za/voucher24/updateUrl.php', formData1).subscribe(data5 => {
          console.log(data5);
        });
        const storRef = this.afs.collection('/supplierProfile/').doc(snapshotKey);

        if (isMain === true) {
          storRef.update({
            logoUrl: url,
            mainFranchiseUrl: url
          });

          // firebase
          //   .database()
          //   .ref('/mainstores')
          //   .child(snapshotKey)
          //   .set({
          //     storename: storename.toLowerCase(), // shoprite
          //     logoUrl: url
          //   });
          this.afs.collection('/mainstores/').doc(snapshotKey)
            .set({
              storename: storename.toLowerCase(), // shoprite
              logoUrl: url
            });
        } else {
          storRef.update({
            logoUrl: url,
            mainFranchiseUrl: mainFranchiseUrl
          });
        }
      });
    });

    console.log(this.snapShot);
    return this.snapShot;
  }
  getStoreUid(snapshotKey, file, category, isFeatured, isComing, storename, isMain, mainFranchiseUrl, address) {
    console.log(file);
    // this.storeRef = this.afd.object('/supplierProfile');

    console.log(snapshotKey);

    const storageRef = firebase.storage().ref('/supplierProfile');
      console.log(storageRef);
      console.log(file);
    const uploadLogo = storageRef.child(snapshotKey).child('logo').child(file.name);
      console.log(uploadLogo);

      const uploadTaskLg = uploadLogo.put(file).then(data => {
        console.log(data);

        const storages = firebase.storage();
        const gsReference = storages.refFromURL('gs://voucher24-6e09b.appspot.com/supplierProfile/'
         + snapshotKey + '/logo/' + file.name);
        gsReference.getDownloadURL().then((url) => {
          console.log(url);
          const formData1 = new FormData();
          formData1.append('category', category);
          formData1.append('isComing', isComing);
          formData1.append('logoUrl', url);
          formData1.append('featured', isFeatured);
          formData1.append('storename', storename);
          formData1.append('address', address);

          const res = this.ht.post('https://voucher24.co.za/voucher24/postcat.php', formData1).subscribe(data5 => {
            console.log(data5);
          });
          const storRef = this.afs.collection('/supplierProfile/').doc(snapshotKey);

          if (isMain === true) {
            storRef.update({
              logoUrl: url,
              mainFranchiseUrl: url
            });

            // firebase
            //   .database()
            //   .ref('/mainstores')
            //   .child(snapshotKey)
            //   .set({
            //     storename: storename.toLowerCase(), // shoprite
            //     logoUrl: url
            //   });
              this.afs.collection('/mainstores/').doc(snapshotKey)
              .set({
                storename: storename.toLowerCase(), // shoprite
                logoUrl: url
              });
          } else {
            storRef.update({
              logoUrl: url,
              mainFranchiseUrl: mainFranchiseUrl
            });
          }
        });
      });

    console.log(this.snapShot);
    return this.snapShot;
  }

  getcamera64() {
    const response = this.ht.get('./assets/camera64.json').pipe(
      map(res => res.json()) // or any other operator
    );
    return response;
  }
  updateComingsoon(data) {
    const response = this.ht.post('https://voucher24.co.za/voucher24/updateiscoming.php', data).pipe(
      map(res => res.json()) // or any other operator
    );
    return response;
  }
  updateCat(data) {
    const response = this.ht.post('https://voucher24.co.za/voucher24/updatecat.php', data).pipe(
      map(res => res.json()) // or any other operator
    );
    return response;
  }
  updateName(data) {
    const response = this.ht.post('https://voucher24.co.za/voucher24/updatename.php', data).pipe(
      map(res => res.json()) // or any other operator
    );
    return response;
  }
  updateFeatured(data) {
    const response = this.ht.post('https://voucher24.co.za/voucher24/updateFeatured.php', data).pipe(
      map(res => res.json()) // or any other operator
    );
    return response;
  }
  blockVouchers(data) {
    const response = this.ht.post('https://voucher24.co.za/voucher24/blockVouchers.php', data).pipe(
      map(res => res.json()) // or any other operator
    );
    return response;
  }
  getBlockedVouchers(data) {
    const response = this.ht.post('https://voucher24.co.za/voucher24/getBlockedshop.php', data).pipe(
      map(res => res.json()) // or any other operator
    );
    return response;
  }
  getLockedUser(email) {
    const response = this.ht.get('https://voucher24.co.za/voucher24/getblockeduser.php?email_address=' + email).pipe(
      map(res => res.json()) // or any other operator
    );
    return response;
  }
  lockUser(data) {
    const response = this.ht.post('https://voucher24.co.za/voucher24/blockuser.php', data).pipe(
      map(res => res.json()) // or any other operator
    );
    return response;
  }
  getUsers() {
    const response = this.ht.get('https://voucher24.co.za/voucher24/getusers.php').pipe(
      map(res => res.json()) // or any other operator
    );
    return response;
  }
  unlockUser(email) {
    const response = this.ht.get('https://voucher24.co.za/voucher24/unlockuser.php?email_address=' + email).pipe(
      map(res => res.json()) // or any other operator
    );
    return response;
  }
  // https://voucher24.co.za/voucher24//airtime.php
  getKey() {
    const response = this.ht.get('https://applord-db.co.za/voucher24/key.txt').pipe(
      map(res => res.json()) // or any other operator
    );
    return response;
  }
  getBlueLabelCharge() {
    return this.afd.object('/bluelabelcharge').valueChanges();
  }
  deleteShop(logourl) {
    const formData1 = new FormData();
    formData1.append('logourl', logourl);
    const response = this.ht.post('https://applord-db.co.za/voucher24/deleteshop.php', formData1).pipe(
      map(res => res.json()) // or any other operator
    );
    return response;
  }
  getStats() {
    const response = this.ht.get('https://cors-anywhere.herokuapp.com/https://voucher24.co.za/voucher24//getstats.php').pipe(
      map(res => res.json()) // or any other operator
    );
    return response;
  }
  getTopselling() {
    const response = this.ht.get('https://cors-anywhere.herokuapp.com/https://voucher24.co.za/voucher24//getstores.php').pipe(
      map(res => res.json()) // or any other operator
    );
    return response;
  }
  sendTemppassword(email, key) {

    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + key);
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });

    const response = this.ht.post('https://cors-anywhere.herokuapp.com/https://api.sendgrid.com/v3/mail/send', email , options).pipe(
      map(res => res.text()) // or any other operator
    );
    return response;
  }
  sendAttachment(email, key) {

    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + key);
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });

    const response = this.ht.post('https://cors-anywhere.herokuapp.com/https://api.sendgrid.com/v3/mail/send', email, options).pipe(
      map(res => res.text()) // or any other operator
    );
    return response;
  }
  getVoucher(uid) {
    return this.singleVoucher = this.afs.collection('/supplierVouchers/' + uid + '/vouchers').valueChanges();
  }
  getAmounts(uid) {
    console.log(uid);
    return this.afs.collection('supplierVouchers').doc(uid).collection('amounts').snapshotChanges();
  }

  getVouchersToredeem(uid) {
    console.log(uid);
    // return this.singleVoucher = this.afd.list('/userProfile/' + uid + '/vouchers').valueChanges();

    return this.afs.collection('/redeemableVouchers/' + uid + '/vouchers').valueChanges();
  }
  sendVouchersToRedeemed(uid) {
    console.log(uid);
    // return this.singleVoucher = this.afd.list('/userProfile/' + uid + '/vouchers').valueChanges();

    return this.afs.collection('/supplierVouchers/' + uid + '/vouchers').valueChanges();
  }
  checkOneVoucherRedeem(uniqueno) {
    return this.afs.collection('/vouchers', ref => ref.where('uniqueno', '==', uniqueno)).valueChanges();
  }
  getSubshops(mainFranchiseUrl) {
    return this.afs.collection('/supplierProfile', ref => ref.where('mainFranchiseUrl', '==', mainFranchiseUrl)).valueChanges();
  }
  getOneVoucher(reff) {
    console.log(reff);
    this.getOneVoucherColRef = this.afs.collection('/vouchers', ref => ref.where('ref', '==', reff)).snapshotChanges();
    this.getOneVoucherColRef.forEach(dd => {
      console.log(dd);
      this.removeOneVoucher(dd[0].payload.doc.id);
    });

    // this.voucherShot = this.getOneVoucherRef.query.orderByChild('ref').equalTo(ref).once('child_added', (snapshot) => {
    //   console.log(snapshot.key);
    //   this.removeOneVoucher(snapshot.key);

    // });
    // return this.voucherShot;
  }
  removeOneVoucher(key) {
    this.afs.collection('/vouchers/').doc(key).delete().then(data => {
      console.log(data);
    });
  }

  getOneStoreVouchers(reff, date) {
    console.log(reff);
    this.getOneSupplierVouchersRef = this.afs.collection('/supplierVouchers/').doc(reff).collection('/vouchers', ref => ref.where('date', '==', date)).snapshotChanges();
    this.getOneSupplierVouchersRef.forEach(dd => {
      console.log(dd);
      this.removeOneStoreVouchers(reff, dd[0].payload.doc.id);
    });

    // .query.orderByChild('date').equalTo(date).once('child_added', (snapshot) => {
    //   console.log(snapshot.key);
    //   this.removeOneStoreVouchers(ref, snapshot.key);

    // });
    return this.vouchersShot;
  }
  removeOneStoreVouchers(ref1, ref2) {
    this.afs.collection('/supplierVouchers/').doc(ref1).collection('/vouchers/').doc(ref2).delete().then(data => {
      console.log(data);
    });
  }

  getOneClient(url) {
    console.log(url);
    // this.getClientColRef = this.afs.collection('/supplierProfile');

    this.getClientColRef = this.afs.collection('/supplierProfile', ref => ref.where('logoUrl', '==', url)).snapshotChanges();
    this.getClientColRef.forEach(dd => {
      console.log(dd);
      this.removeClient(dd[0].payload.doc.id);
    });
    return this.getClientColRef;

    // this.snapShot = this.getClientRef.query.orderByChild('logoUrl').equalTo(url).once('child_added', (snapshot) => {
    //   console.log(snapshot.key);
    //   this.removeClient(snapshot.key);

    // });
    // return this.snapShot;
  }
  getOneSupplier(cat) {
    // this.getClientRef = this.afd.object('/suppliercategories');

    // this.snapShot = this.getClientRef.query.orderByChild('category').equalTo(cat).once('child_added', (snapshot) => {
    //   console.log(snapshot.key);
    //   this.removeCardCat(snapshot.key);

    // });
    // return this.snapShot;

    this.supplierDocRef = this.afs.collection('/suppliercategories', ref => ref.where('category', '==', cat)).snapshotChanges();
    this.supplierDocRef.forEach(dd => {
      console.log(dd);
      this.removeCardCat(dd[0].payload.doc.id);
    });
    return this.supplierDocRef;
  }
  getOneTemplate(cat) {
    // this.getClientRef = this.afd.object('/templatecategories');

    // this.snapShot = this.getClientRef.query.orderByChild('category').equalTo(cat).once('child_added', (snapshot) => {
    //   console.log(snapshot.key);
    //   this.removeTemplateCat(snapshot.key);

    // });
    // return this.snapShot;

    this.templateDocRef = this.afs.collection('/templatecategories', ref => ref.where('category', '==', cat)).snapshotChanges();
    this.templateDocRef.forEach(dd => {
      console.log(dd);
      this.removeTemplateCat(dd[0].payload.doc.id);
    });
    return this.templateDocRef;
  }
  removeCardCat(key) {
    const del = this.afs.collection('/suppliercategories/').doc(key).delete().then(data => {
      console.log(data);
    });
  }
  removeTemplateCat(key) {
    const del = this.afs.collection('/templatecategories/').doc(key).delete().then(data => {
      console.log(data);
    });
  }
  removeClient(key) {
    // firebase
    //   .database()
    //   .ref('/mainstores')
    //   .child(key)
    //   .remove();
    console.log(key);
    const response = this.ht.get('https://cors-anywhere.herokuapp.com/https://us-central1-voucher24-6e09b.cloudfunctions.net/deleteSupplier?uid=' + key).subscribe(dd => {
      console.log(dd);
    });

    this.afs.collection('/mainstores').doc(key).delete();
    this.afs.collection('/supplierProfile/').doc(key).delete().then(data => {
      console.log(data);
    });
  }
}
