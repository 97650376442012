import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';
import 'firebase/storage';

@Injectable({
  providedIn: 'root'
})
export class TemplateServiceService {
  templateRef: AngularFireObject<any>;
  templateColuRef: AngularFirestoreCollection<any>;
  snapShot: any;
  item: Observable<any>;
  templateDocRef: Observable<any>;
  getTemplateRef: AngularFireObject<any>;
  getTemplateColRef: AngularFirestoreCollection<any>;

  constructor(private afd: AngularFireDatabase, private afs: AngularFirestore) { }

  getOneTemplate(url) {
    console.log(url);
   // this.getTemplateRef = this.afd.object('/templates');

    this.templateDocRef = this.afs.collection('/templates', ref => ref.where('logoUrl', '==', url)).snapshotChanges();
    console.log(this.templateDocRef);
    this.templateDocRef.forEach(dd => {
      console.log(dd);
      this.removeTemplate(dd[0].payload.doc.id);
    });
    return this.templateDocRef;
    // this.snapShot = this.getTemplateRef.query.orderByChild('logoUrl').equalTo(url).once('child_added', (snapshot) => {
    //   console.log(snapshot.key);
    //   this.removeTemplate(snapshot.key);

    // });
    // return this.snapShot;
  }

  removeTemplate(key) {
    console.log(key);
    // this.afd.object('/templates/' + key).remove().then(data => {
    //   console.log(data);
    // });

    this.afs.collection('/templates/').doc(key).delete().then(data => {
      console.log(data);
    });
  }

  getTemplates() {
    // this.item = this.afd.list('/templates').valueChanges();

    this.item = this.afs.collection('/templates').valueChanges();
    return this.item;
  }

  getTemplateUid(snapshotKey, file) {
    console.log(file);
    // this.templateColuRef = this.afs.collection('/templates');

      const storageRef = firebase.storage().ref('/templates');
      console.log(storageRef);
      console.log(file);
      const uploadLogo = storageRef.child(snapshotKey).child('logo').child(file.name);
      console.log(uploadLogo);

      const uploadTaskLg = uploadLogo.put(file).then(data => {
        console.log(data);

        const storages = firebase.storage();
        const gsReference = storages.refFromURL('gs://voucher24-6e09b.appspot.com/templates/' + snapshotKey + '/logo/' + file.name);
        console.log(gsReference);
        gsReference.getDownloadURL().then((url) => {
          console.log(url);
          const storRef = this.afs.collection('/templates/').doc(snapshotKey);
          storRef.update({
            logoUrl: url
          });
        });
      });
    return this.snapShot;
  }
}
