import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { OperationsService } from '../shared/operations.service';
import * as XLSX from 'xlsx';
import { AngularFireDatabase } from 'angularfire2/database';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { AngularFireAuth } from 'angularfire2/auth';
import { first, take } from 'rxjs/operators';

@Component({
    selector: 'app-viewclickedvouchers',
    templateUrl: './viewclickedvouchers.component.html',
    styleUrls: ['./viewclickedvouchers.component.css']
})
export class ViewclickedvouchersComponent implements OnInit {

    public isCollapsed = true;
    public isGarageCollapsed = true;
    public isAddCollapsed = true;
    public isVoucherCollapsed = true;
    public isRedeemed = true;
    closeResult: string;
    uid: any;
    name: string;
    searchText: any;
    userDetails: any;
    file: File;
    arrayBuffer: any;
    comuid: any;
    message: any;
    TypeOfUser: any;
    logoUrl: any;
    currentVouchers: any;
    loadedVouchers: any;
    numVouchers: any;
    checkVouchersLength: any;
    storename: any;
    topDate: any;

    countAmts = [];
    getResult: any;
    vouchersSold: any;
    countAmt: any;
    hideloading: boolean;

    unsub: any;

    constructor(private router: Router, private route: ActivatedRoute, private operationsService: OperationsService
        , private afd: AngularFireDatabase, private modalService: NgbModal, private afAuth: AngularFireAuth) {
        this.hideloading = true;
        $(document).ready(function () {

            // $('#sidebar').mCustomScrollbar({
            //   theme: 'minimal'
            // });

            $('#sidebarCollapse').on('click', function () {
                // open or close navbar
                $('#sidebar').toggleClass('active');
                // close dropdowns
                $('.collapse.in').toggleClass('in');
                // and also adjust aria-expanded attributes we use for the open/closed arrows
                // in our CSS
                $('a[aria-expanded=true]').attr('aria-expanded', 'false');
            });

        });
        this.route.queryParams.subscribe(params => {
            this.uid = params['uid'];
            this.comuid = params['comuid'];
            this.logoUrl = params['logoUrl'];
        });

    }

    spreadsheetUpload(event: any, content) {
        const storeVouchers = [];
        let extractedExcel = [];
        const voucherAmt = [];
        const row1 = [];
        const row2 = [];
        const row3 = [];
        const storeAllVouchers = [];
        this.file = event.target.files[0];
        console.log(this.file);
        const fileReader = new FileReader();
        console.log(fileReader);
        fileReader.onload = (e) => {
            console.log(e);
            this.arrayBuffer = fileReader.result;

            const data = new Uint8Array(this.arrayBuffer);
            const arr = new Array();
            for (let i = 0; i !== data.length; ++i) {
                arr[i] = String.fromCharCode(data[i]);
            }
            const bstr = arr.join('');
            console.log(bstr);
            const workbook = XLSX.read(bstr, { type: 'binary' });
            console.log(workbook);
            const first_sheet_name = workbook.SheetNames[0];
            console.log(first_sheet_name);
            const worksheet = workbook.Sheets[first_sheet_name];
            console.log(worksheet);
            const res = this.convertToTable(worksheet);
            console.log(res);
            for (let x = 0; x < res[3].length; x += 3) {
                console.log(x);
                const amt = res[3][x];
                console.log(amt);
                if (amt === undefined) {

                } else {

                    console.log(amt.length);
                    const stripAmt = amt.substr(4, amt.length);
                    console.log(stripAmt);
                    let lastStrip = stripAmt;
                    lastStrip = lastStrip.slice(0, -3);
                    console.log(lastStrip);
                    voucherAmt.push(lastStrip);
                }

            }
            console.log(voucherAmt);
            console.log(res[3].length);
            for (let y = 5; y !== res.length; ++y) {
                for (let z = 0; z < res[3].length; z += 3) {
                    const addOne = z + 1;
                    if (res[y][addOne] === undefined) {

                    } else {
                        const amt = res[3][z];
                        console.log(amt);
                        if (amt === undefined) {

                        } else {
                            console.log(amt.length);
                            const stripAmt = amt.substr(4, amt.length);
                            let lastStrip = stripAmt;
                            lastStrip = lastStrip.slice(0, -3);
                            storeAllVouchers.push({ cardNumber: res[y][z], cvv: res[y][addOne], amount: lastStrip });
                        }
                    }
                }

            }
            console.log(storeAllVouchers);
            const newObject = {
                companyName: res[0][0],
                campaignNo: res[1][0],
            };
            console.log(newObject);
            extractedExcel = XLSX.utils.sheet_to_json(worksheet, { raw: true });
            console.log(extractedExcel);
            for (let i = 3; i !== extractedExcel.length; ++i) {
                // console.log(i);
                // console.log(JSON.parse(JSON.stringify(extractedExcel[i]) + '.Woolworths'));
                console.log(worksheet.A1.h);
                if (worksheet.A1.h === 'Woolworths') {
                    storeVouchers.push({ cvv: extractedExcel[i]['5%'], cardNumber: extractedExcel[i].Woolworths, amount: worksheet.A4.v });
                } else if (worksheet.A1.h === 'Shoprite') {
                    storeVouchers.push({ cvv: extractedExcel[i]['5%'], cardNumber: extractedExcel[i].Shoprite, amount: worksheet.A4.v });
                } else if (worksheet.A1.h === 'Takealot') {
                    storeVouchers.push({ cvv: extractedExcel[i]['5%'], cardNumber: extractedExcel[i].Takealot, amount: worksheet.A4.v });
                }

            }
            console.log(storeVouchers);
            const com = res[0][1];
            const slicedCom = com.slice(0, -1);
            const dis = res[0][2];
            const slicedDis = dis.slice(0, -1);
            console.log(slicedDis);

            const voucherObj = {
                companyName: res[0][0],
                campaignNo: res[1][0],
                commission: slicedCom,
                discount: slicedDis,
                voucherAmt,
                storeAllVouchers
            };
            console.log(voucherObj);
            this.afd.database
                .ref('/userProfile')
                .child(this.comuid)
                .child('/vouchers')
                .push({
                    companyName: res[0][0],
                    campaignNo: res[1][0],
                    commission: slicedCom,
                    discount: slicedDis,
                    voucherAmt,
                    storeAllVouchers
                }).then(data1 => {
                    console.log(data1);
                    const navigationExtras: NavigationExtras = {
                        queryParams: {
                            uid: this.uid
                        }, skipLocationChange: true
                    };
                    this.router.navigate(['/availvouchers'], navigationExtras);
                    this.message = 'Successfully added';

                    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                        this.closeResult = `Closed with: ${result}`;
                    }, (reason) => {
                        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                    });
                });
        };
        fileReader.readAsArrayBuffer(this.file);
        console.log(fileReader);
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    public convertToTable(sheet: any) {
        const result = [];
        let row;
        let rowNum;
        let colNum;
        const range = XLSX.utils.decode_range(sheet['!ref']);
        for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
            row = [];
            for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
                const nextCell = sheet[
                    XLSX.utils.encode_cell({ r: rowNum, c: colNum })
                ];
                if (typeof nextCell === 'undefined') {
                    row.push(void 0);
                } else {
                    row.push(nextCell.w);
                }
            }
            result.push(row);
        }
        return result;
    }

    ngOnInit() {
        let counter = 0;
        console.log(this.comuid);
        this.numVouchers = 0;
        let loadedVouchers = [];

        this.operationsService.getVoucher(this.comuid).pipe(take(1)).toPromise().then((data: any) => {
            console.log("getVoucher DATA ", data);
            this.checkVouchersLength = data.length;
            console.log("checkVouchersLength ", this.checkVouchersLength);
            
            if (this.checkVouchersLength === 0) {
                // this.numVouchers = 0;
            } else {
                this.currentVouchers = _.first(data);
                // loadedVouchers.push(this.currentVouchers.storeAllVouchers);

                this.loadedVouchers = this.currentVouchers.storeAllVouchers;
                loadedVouchers = this.currentVouchers.storeAllVouchers;
                const a = loadedVouchers.slice(2, 10);
                console.log(a);
                console.log(this.loadedVouchers);
                this.getResult = _.chain(this.loadedVouchers)
                    .groupBy('amount')
                    .toPairs()
                    .map((currentItem) => {
                        console.log(currentItem);
                        counter = counter + 1;
                        const getItems = {
                            i: counter,
                            amount: currentItem[0],
                            numberOfVouchers: currentItem[1].length
                        };
                        this.countAmts.push(getItems);
                        return _.toPlainObject(_.zip(['amount', 'cardNumber', 'cvv'], currentItem));
                    })
                    .value();

                this.countAmts = _.orderBy(this.countAmts, ['numberOfVouchers'], ['desc']);
                // this.vouchersBought = data1.length;

                // =================================================================

                console.log(this.countAmts);
                // this.numVouchers = this.currentVouchers.storeAllVouchers.length;
                this.topDate = data[0].date;
                console.log("topDate", this.topDate);
                for (let z = 0; z < data.length; z++) {
                    console.log(data[z]);
                    this.numVouchers = this.numVouchers + data[z].storeAllVouchers.length;
                    console.log("Vouchers Remaining: ", this.numVouchers);
                }
            }


            // loadedVouchers.push(this.currentVouchers.storeAllVouchers);

            // this.loadedVouchers = this.currentVouchers.storeAllVouchers;
            // console.log(this.loadedVouchers);
            // this.getResult = _.chain(this.loadedVouchers)
            //   .groupBy('amount')
            //   .toPairs()
            //   .map((currentItem) => {
            //     console.log(currentItem);
            //     counter = counter + 1;
            //     const getItems = {
            //       i: counter,
            //       amount: currentItem[0],
            //       numberOfVouchers: currentItem[1].length
            //     };
            //     this.countAmts.push(getItems);
            //     return _.toPlainObject(_.zip(['amount', 'cardNumber', 'cvv'], currentItem));
            //   })
            //   .value();

            // this.countAmts = _.orderBy(this.countAmts, ['numberOfVouchers'], ['desc']);
            // // this.vouchersBought = data1.length;
            // console.log(this.countAmts);
            // this.numVouchers = this.currentVouchers.storeAllVouchers.length;

            // for (let i = 0; i !== data.length; ++i) {
            //   console.log(data[i]);
            //   this.currentVouchers = data[i];
            //   loadedVouchers.push(this.currentVouchers.storeAllVouchers);
            //   console.log(loadedVouchers);
            //   // for (let x = 0; x !== this.currentVouchers.storeAllVouchers.length; ++x) {
            //   //   console.log(this.currentVouchers.storeAllVouchers);
            //   // }
            // }
        });
        this.unsub = this.operationsService.getClientsDetails(this.comuid).subscribe(data => {
            console.log(data.payload.data());
            this.storename = data.payload.data().storename;
            this.operationsService.getVouchers().pipe(first()).toPromise().then(data1 => {
                this.hideloading = false;
                console.log(data1);
                this.countAmt = _.filter(data1, (x) => {
                    return x.store === this.storename;
                });
                console.log(this.countAmt);

                this.getResult = _.chain(this.countAmt)
                    .groupBy('originalAmount')
                    .toPairs()
                    .map((currentItem) => {
                        console.log(currentItem);
                        counter = counter + 1;
                        const getItems = {
                            i: counter,
                            amount: currentItem[0],
                            numberOfVouchers: currentItem[1].length
                        };
                        this.countAmts.push(getItems);
                        return _.toPlainObject(_.zip(['date', 'ref', 'store', 'transactionAmmount'], currentItem));
                    })
                    .value();

                this.countAmts = _.orderBy(this.countAmts, ['numberOfVouchers'], ['desc']);
                this.vouchersSold = this.countAmt.length;
            });
        });

        this.operationsService.getDetails(this.uid).pipe(first()).toPromise().then(data => {
            console.log(data);
            this.userDetails = data;
            this.name = this.userDetails.name;
            this.TypeOfUser = this.userDetails.userType;
            if (!this.name) {
                this.name = this.userDetails.fname + ' ' + this.userDetails.sname;
            }
        });
    }
    gotoLockuser() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/lockeduser'], navigationExtras);
    }
    gotoCharge() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/bluelabel'], navigationExtras);
    }
    gotoViewVouchers(x) {
        console.log(x);
        console.log(this.logoUrl);
        console.log(this.comuid);
        this.operationsService.getClientByLogourl(this.logoUrl).pipe(first()).toPromise().then(data => {
            console.log(data);
        });
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid,
                comuid: this.comuid,
                logoUrl: this.logoUrl,
                amount: x,
                boughtVouchers: JSON.stringify(this.countAmt)
            }, skipLocationChange: true
        };
        this.router.navigate(['/clickvouchers'], navigationExtras);
    }
    gotoPromotional() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/specials'], navigationExtras);
    }

    gotoLogin() {
        this.operationsService.updateSignInStatus(this.uid, false);
        this.afAuth.auth.signOut();
        this.router.navigateByUrl('/login');
    }

    gotohome() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/home'], navigationExtras).then(dd => {
            console.log('Unsub');
            this.unsub.unsubscribe();
        });
    }
    gotoVouchersBought() {
        if (this.userDetails.userType === 'superAdmin') {
            const navigationExtras: NavigationExtras = {
                queryParams: {
                    uid: this.uid
                }, skipLocationChange: true
            };
            this.router.navigate(['selectbought'], navigationExtras);
        } else {
            const navigationExtras: NavigationExtras = {
                queryParams: {
                    uid: this.uid
                }, skipLocationChange: true
            };
            this.router.navigate(['/vouches'], navigationExtras).then(dd => {
                console.log('Unsub');
                this.unsub.unsubscribe();
            });
        }
    }
    gotoAddClients() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/add-client'], navigationExtras).then(dd => {
            console.log('Unsub');
            this.unsub.unsubscribe();
        });
    }
    gotoClients() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/clients'], navigationExtras).then(dd => {
            console.log('Unsub');
            this.unsub.unsubscribe();
        });
    }
    gotoAddNewVouchers() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/add-new-vouchers'], navigationExtras);
    }
    gotoCardTemplates() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/card-templates'], navigationExtras);
    }
    gotoAddCardTemplate() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/add-card-template'], navigationExtras);
    }
    gotoAvailableVouchers() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/availvouchers'], navigationExtras);
    }
    gotoAddCategory() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/addsuppcat'], navigationExtras);
    }
    gotoAddtemplateCategory() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/addtempcat'], navigationExtras);
    }
    goBack() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/selectbought'], navigationExtras);
    }
    gotoDeltemplateCategory() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/deltempcat'], navigationExtras);
    }
    gotoDelsupplierCategory() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/delsuppcat'], navigationExtras);
    }
    gotoReemvouchers() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/redeem'], navigationExtras);
    }
    gotoRedeemedvouchers() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/redeemedvouchers'], navigationExtras);
    }

}

