// export const FIREBASE_CONFIG = {
//     apiKey: "AIzaSyBRlSDucnpWPiPCoISggN-aMSqKDHlmsiI",
//     authDomain: "voucher24-dc804.firebaseapp.com",
//     databaseURL: "https://voucher24-dc804.firebaseio.com",
//     projectId: "voucher24-dc804",
//     storageBucket: "voucher24-dc804.appspot.com",
//     messagingSenderId: "99118142434",
//     appId: "1:99118142434:web:959e270495fa3c42"
// };

// export const FIREBASE_CONFIG = {
//     apiKey: "AIzaSyA3MpNNWp3zPorEuU79WouyFv5_Q8BbPX8",
//     authDomain: "testvoucher.firebaseapp.com",
//     databaseURL: "https://testvoucher.firebaseio.com",
//     projectId: "testvoucher",
//     storageBucket: "testvoucher.appspot.com",
//     messagingSenderId: "1076539939053",
//     appId: "1:1076539939053:web:b55b08e688b29997"
// };

export const FIREBASE_CONFIG = {
    apiKey: 'AIzaSyCSPbDGfHNX9_pvIyFFuGAPoASw2uv4ObM',
    authDomain: 'voucher24-6e09b.firebaseapp.com',
    databaseURL: 'https://voucher24-6e09b.firebaseio.com',
    projectId: 'voucher24-6e09b',
    storageBucket: 'voucher24-6e09b.appspot.com',
    messagingSenderId: '660762213407',
    appId: '1:660762213407:web:226a567bab202dac'
};

// export const FIREBASE_CONFIG = {
//     apiKey: "AIzaSyA9knrzMPW1qRziXzt-jPb1qJ7rbvI2E48",
//     authDomain: "baby-names-app-db-5cf54.firebaseapp.com",
//     databaseURL: "https://baby-names-app-db-5cf54.firebaseio.com",
//     projectId: "baby-names-app-db-5cf54",
//     storageBucket: "baby-names-app-db-5cf54.appspot.com",
//     messagingSenderId: "649903390196",
//     appId: "1:649903390196:web:aef053bd9fff7e71"
// };

