import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { Client } from '../../models/clients';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as firebase from 'firebase';
import { OperationsService } from '../shared/operations.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-blue-service-charge',
  templateUrl: './blue-service-charge.component.html',
  styleUrls: ['./blue-service-charge.component.css']
})
export class BlueServiceChargeComponent implements OnInit {

  public isCollapsed = true;
  public isGarageCollapsed = true;
  public isAddCollapsed = true;
  public isVoucherCollapsed = true;
  public isRedeemed = true;
  closeResult: string;
  stores: any;
  uid: any;
  name: string;
  searchText: any;
  userDetails: any;
  TypeOfUser: any;
  category: any;
  message: any;
  charge: any;
  discount: any;
  prepaidCharge: any;
  hideloading: boolean;

  constructor(private router: Router, private route: ActivatedRoute, private afAuth: AngularFireAuth, private afd: AngularFireDatabase
    , private modalService: NgbModal, private operationsService: OperationsService) {
    this.hideloading = true;
    $(document).ready(function () {

      // $('#sidebar').mCustomScrollbar({
      //   theme: 'minimal'
      // });

      $('#sidebarCollapse').on('click', function () {
        // open or close navbar
        $('#sidebar').toggleClass('active');
        // close dropdowns
        $('.collapse.in').toggleClass('in');
        // and also adjust aria-expanded attributes we use for the open/closed arrows
        // in our CSS
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
      });

    });

    this.route.queryParams.subscribe(params => {
      this.uid = params['uid'];
    });
  }
  gotoPromotional() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/specials'], navigationExtras);
  }

  gotoLockuser() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/lockeduser'], navigationExtras);
  }

  addTemplateCat(content) {
    console.log(this.charge);
    if (!this.charge) {
      this.message = 'Please enter commission';
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    } else if (!this.discount) {
      this.message = 'Please enter discount';
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    } else if (!this.prepaidCharge) {
      this.message = 'Please enter service charge';
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    } else {
      this.afd.database
        .ref('/bluelabelcharge')
        .update({
          bluelabelcharge: parseFloat(this.prepaidCharge),
          bluelabeldiscount: parseFloat(this.discount),
          prepaidServiceCharge: parseFloat(this.charge)
        }).then(data => {
          this.message = 'Successfully updated';

          this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
        });

    }
  }
  gotoCharge() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/bluelabel'], navigationExtras);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  gotoEdit(x) {
    console.log(x);
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid,
        storeobj: JSON.stringify(x)
      }, skipLocationChange: true
    };
    this.router.navigate(['/editsupplier'], navigationExtras);
  }

  delete(x) {
    console.log(x);
    this.operationsService.getOneClient(x.logoUrl);
  }

  ngOnInit() {

    const unsub = this.operationsService.getBlueLabelCharge().subscribe((data: any) => {
      console.log(data);
      if (!data) {
        this.charge = 0;
        this.discount = 0;
        this.prepaidCharge = 0;
        unsub.unsubscribe();
      } else {
        this.charge = data.prepaidServiceCharge;
        this.discount = data.bluelabeldiscount;
        this.prepaidCharge = data.bluelabelcharge;
        unsub.unsubscribe();
      }
    });

    this.operationsService.getDetails(this.uid).subscribe(data => {
      console.log(data);
      this.hideloading = false;
      this.userDetails = data;
      this.name = this.userDetails.name;
      this.TypeOfUser = this.userDetails.userType;
      if (!this.name) {
        this.name = this.userDetails.fname + ' ' + this.userDetails.sname;
      }
    });

    // this.operationsService.getClients().subscribe(data => {
    //   console.log(data);
    //   this.stores = _.filter(data, (x) => {
    //     return x.isClient === 'true';
    //   });
    // });
  }
  gotoLogin() {
    this.operationsService.updateSignInStatus(this.uid, false);
    this.afAuth.auth.signOut();
    this.router.navigateByUrl('/login');
  }

  gotohome() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/home'], navigationExtras);
  }
  gotoVouchersBought() {
    if (this.userDetails.userType === 'superAdmin') {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['selectbought'], navigationExtras);
    } else {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['/vouches'], navigationExtras);
    }
  }
  gotoAddClients() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-client'], navigationExtras);
  }
  gotoClients() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/clients'], navigationExtras);
  }
  gotoAddNewVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-new-vouchers'], navigationExtras);
  }
  gotoCardTemplates() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/card-templates'], navigationExtras);
  }
  gotoAddCardTemplate() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-card-template'], navigationExtras);
  }
  gotoAvailableVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/availvouchers'], navigationExtras);
  }
  gotoAddCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addsuppcat'], navigationExtras);
  }
  gotoAddtemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addtempcat'], navigationExtras);
  }
  gotoDeltemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/deltempcat'], navigationExtras);
  }
  gotoDelsupplierCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/delsuppcat'], navigationExtras);
  }
  gotoReemvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeem'], navigationExtras);
  }
  gotoRedeemedvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeemedvouchers'], navigationExtras);
  }

}
