import { ElementRef, NgZone, Component, OnInit, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { Person } from '../../models/person';
import { Garage } from '../../models/garage';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { OperationsService } from '../shared/operations.service';
import { FormControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as firebase from 'firebase/app';
import { first, take } from 'rxjs/operators';

declare const google: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public person = {} as Person;
  public garage = {} as Garage;
  public isCollapsed = true;
  public isGarageCollapsed = true;
  public isAddCollapsed = true;
  public isVoucherCollapsed = true;
  public isRedeemed = true;
  isRedeem: any;
  closeResult: string;
  message: string;
  garages: any;
  uid: string;
  userDetails: any;
  name: string;
  storeLat: number;
  storeLongi: number;
  storeAddress: string;
  markers: any;

  stores: any;
  storesCount: any;
  countShops = [];

  getResult: any;
  vouchersBought: number;
  searchText: any;

  clients: any;
  countVouchers: any;

  userType: any;
  index: any = 0;
  numbers: any;
  shopVouchers: any;
  hideloading: any;

  password: any;
  vcount: any;
  isLogging: boolean;

  constructor(private router: Router, private afAuth: AngularFireAuth, private afd: AngularFireDatabase, private modalService: NgbModal,
    private operationsService: OperationsService, private route: ActivatedRoute, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone) {

    // $(document).ready(function () {

    //   $('#sidebarCollapse').on('click', function () {
    //     $('#sidebar').toggleClass('active');
    //   });

    // });
    this.hideloading = true;
    this.route.queryParams.subscribe(params => {
      this.uid = params['uid'];
      this.password = params['password'];
      this.isLogging = params['isLogging'];
    });

    console.log(moment().format('MMMM Do YYYY, h:mm:ss'));

    $(document).ready(function () {

      // $('#sidebar').mCustomScrollbar({
      //   theme: 'minimal'
      // });

      $('#sidebarCollapse').on('click', function () {
        // open or close navbar
        $('#sidebar').toggleClass('active');
        // close dropdowns
        $('.collapse.in').toggleClass('in');
        // and also adjust aria-expanded attributes we use for the open/closed arrows
        // in our CSS
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
      });

    });
  }
  gotoPromotional() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/specials'], navigationExtras);
  }
  gotoCharge() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/bluelabel'], navigationExtras);
  }
  gotoLockuser() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/lockeduser'], navigationExtras);
  }
  gotoLogin() {
    this.operationsService.updateSignInStatus(this.uid, false);
    this.afAuth.auth.signOut();
    this.router.navigateByUrl('/login').then((d) =>
    console.log(d)
      // this.service.emmiter.emit(data)
    );
  }
  gotoClients() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/clients'], navigationExtras);
  }
  gotoAddClients() {

    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-client'], navigationExtras);
  }
  gotoVouchersBought() {
    if (this.userDetails.userType === 'superAdmin') {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['selectbought'], navigationExtras);
    } else {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['/vouches'], navigationExtras);
    }
  }
  gotoOutofStock(content) {
    if (this.vcount === 0) {
      this.message = 'Not available';

      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    } else {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['/outofstock'], navigationExtras);
    }
  }
  gotoAddNewVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-new-vouchers'], navigationExtras);
  }
  gotoCardTemplates() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/card-templates'], navigationExtras);
  }
  gotoAddCardTemplate() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-card-template'], navigationExtras);
  }
  gotoAvailableVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/availvouchers'], navigationExtras);
  }
  gotoViewCurrentStock() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/viewvouchersbyshop'], navigationExtras);
  }

  gotoAddCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addsuppcat'], navigationExtras);
  }
  gotoAddtemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addtempcat'], navigationExtras);
  }
  gotoDeltemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/deltempcat'], navigationExtras);
  }
  gotoDelsupplierCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/delsuppcat'], navigationExtras);
  }
  gotoReemvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeem'], navigationExtras);
  }
  gotoRedeemedvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeemedvouchers'], navigationExtras);
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  loadVouchers(storename) {
    let counter = 0;
    this.vcount = 0;
    let totalLength = 0;
    this.hideloading = false;
    this.isRedeem = this.userDetails.isRedeem;
    if (!this.isRedeem) {
      this.isRedeem = false;
      console.log(this.isRedeem);
    } else {
      this.isRedeem = true;
      console.log(this.isRedeem);
    }
    this.operationsService.getVouchers().pipe(first()).toPromise().then(data => {
      // console.log(data);
      this.shopVouchers = _.filter(data, (x) => {
        // console.log(x);
        return x.store === storename;
      });
      // console.log(this.shopVouchers);
      this.getResult = _.chain(this.shopVouchers)
        .groupBy('store')
        .toPairs()
        .map((currentItem) => {
          counter = counter + 1;
          const getItems = {
            i: counter,
            name: currentItem[0],
            numberOfShops: currentItem[1].length
          };
          this.countShops.push(getItems);
          return _.toPlainObject(_.zip(['date', 'store', 'transactionAmmount', 'ref'], currentItem));
        })
        .value();
        // console.log(this.countShops);
      this.vouchersBought = this.shopVouchers.length;
    });

    this.operationsService.countClientVouchers(this.uid).subscribe((vdata: any) => {
      console.log(vdata);
      for (let z = 0; z < vdata.length; z++) {
        const length = vdata[z].storeAllVouchers.length;
        totalLength = totalLength + vdata[z].storeAllVouchers.length;

        if (vdata.length - 1 === z) {
          if (totalLength <= 10) {
            this.vcount = this.vcount + 1;
          }
          totalLength = 0;
        }
      }
    });
  }

  ngOnInit() {

    this.vcount = 0;
    let counter = 0;
    let totalLength = 0;

    this.operationsService.getClientsDetails(this.uid).pipe(first()).toPromise().then((dat: any) => {
      console.log(dat.payload.data());
      const dataa = dat.payload.data();
      if (!dataa) {
        this.operationsService.getDetails(this.uid).pipe(first()).toPromise().then((data: any) => {
          console.log(data);
          if (!data) {

          } else {
            if (!data.tempPassword) {
              alert('Only admins allowed here');

              this.hideloading = false;
              const navigationExtras: NavigationExtras = {
                queryParams: {
                  uid: this.uid
                }, skipLocationChange: true
              };
              this.router.navigate(['login'], navigationExtras);
            } else if (data.tempPassword === '123456') {
              this.hideloading = false;
              const navigationExtras: NavigationExtras = {
                queryParams: {
                  uid: this.uid
                }, skipLocationChange: true
              };
              this.router.navigate(['reset'], navigationExtras);
            } else if (data.tempPassword === this.password) {
              this.hideloading = false;
              const navigationExtras: NavigationExtras = {
                queryParams: {
                  uid: this.uid
                }, skipLocationChange: true
              };
              this.router.navigate(['reset'], navigationExtras);
            } else if (data.tempPassword === 'modified') {
              // this.hideloading = false;
              if (this.password === data.restrictedPassword) {
                const navigationExtras: NavigationExtras = {
                  queryParams: {
                    uid: this.uid
                  }, skipLocationChange: true
                };
                this.router.navigate(['reset'], navigationExtras);
              } else {
                // this.hideloading = false;
                // const navigationExtras: NavigationExtras = {
                //   queryParams: {
                //     uid: this.uid
                //   }, skipLocationChange: true
                // };
                // this.router.navigate(['home'], navigationExtras);
                this.operationsService.updatedLoggedStatus(this.uid).subscribe(data1 => {
                  console.log(data1);
                  if (!data1) {

                  } else {
                    if (data1.signedIn === false) {
                      this.message = 'There is a device logging in to the account, you will be logged off';
                      console.log(this.message);
                      // alert(this.message);
                      this.router.navigate(['login']);
                      this.afAuth.auth.signOut();
                    } else {

                    }
                  }

                });
                console.log(data);
                this.userDetails = data;
                this.name = this.userDetails.name;
                this.userType = this.userDetails.userType;
                if (!this.name) {
                  this.name = this.userDetails.fname + ' ' + this.userDetails.sname;
                }
                if (data.userType === 'superAdmin') {
                  this.operationsService.getClientsListId().subscribe(data3 => {
                    // console.log(data3);
                    // this.clients = _.filter(data3, (y) => {
                    //   console.log(y.payload.val());
                    //   return y.payload.val().isClient === 'true';
                    // });
                    this.clients = data3;
                    // console.log(this.clients);
                    this.countVouchers = _.filter(this.clients, (y) => {
                      // console.log(y.payload.doc.id);
                      this.operationsService.countClientVouchers(y.payload.doc.id).subscribe((vdata: any) => {
                        // console.log(vdata);
                        for (let z = 0; z < vdata.length; z++) {
                          const length = vdata[z].storeAllVouchers.length;
                          totalLength = totalLength + vdata[z].storeAllVouchers.length;

                          if (vdata.length - 1 === z) {
                            if (totalLength <= 10) {
                              this.vcount = this.vcount + 1;
                            }
                            totalLength = 0;
                          }
                        }
                        console.log('Kuta');
                      });
                      return y;
                    });
                    this.storesCount = this.clients.length;
                    this.hideloading = false;
                  });
                  // ===================================

                  this.operationsService.getVouchers().pipe(first()).toPromise().then(data1 => {

                    this.getResult = _.chain(data1)
                      .groupBy('store')
                      .toPairs()
                      .map((currentItem) => {
                        counter = counter + 1;
                        const getItems = {
                          i: counter,
                          name: currentItem[0],
                          numberOfShops: currentItem[1].length
                        };
                        this.countShops.push(getItems);
                        return _.toPlainObject(_.zip(['date', 'store', 'transactionAmmount', 'ref'], currentItem));
                      })
                      .value();

                    this.countShops = _.orderBy(this.countShops, ['numberOfShops'], ['desc']);
                    this.vouchersBought = data1.length;
                  });

                } else {
                  this.loadVouchers(data.storename);
                }
              }
            }
          }
        });
      } else {
        console.log(dataa);
        if (dataa.tempPassword === '123456') {
          this.hideloading = false;
          const navigationExtras: NavigationExtras = {
            queryParams: {
              uid: this.uid
            }, skipLocationChange: true
          };
          this.router.navigate(['reset'], navigationExtras);
        } else if (dataa.tempPassword === this.password) {
          this.hideloading = false;
          const navigationExtras: NavigationExtras = {
            queryParams: {
              uid: this.uid
            }, skipLocationChange: true
          };
          this.router.navigate(['reset'], navigationExtras);
        } else if (dataa.tempPassword === 'modified') {
          console.log(dataa);
          // this.hideloading = false;
          if (this.password === dataa.restrictedPassword) {
            const navigationExtras: NavigationExtras = {
              queryParams: {
                uid: this.uid
              }, skipLocationChange: true
            };
            this.router.navigate(['reset'], navigationExtras);
          } else {
            // this.hideloading = false;
            // const navigationExtras: NavigationExtras = {
            //   queryParams: {
            //     uid: this.uid
            //   }, skipLocationChange: true
            // };
            // this.router.navigate(['home'], navigationExtras);
            this.operationsService.updatedLoggedStatus(this.uid).subscribe(data1 => {
              console.log(data1);
              if (!data1) {

              } else {
                if (data1.signedIn === false) {
                  this.message = 'There is a device logging in to the account, you will be logged off';
                  console.log(this.message);
                  // alert(this.message);
                  this.router.navigate(['login']);
                  this.afAuth.auth.signOut();
                } else {

                }
              }

            });
            console.log(dataa);
            this.userDetails = dataa;
            this.name = this.userDetails.name;
            this.userType = this.userDetails.userType;

            if (!this.name) {
              this.name = this.userDetails.fname + ' ' + this.userDetails.sname;
            }
            if (dataa.userType === 'superAdmin') {
              this.operationsService.getClientsListId().subscribe(data3 => {
                console.log(data3);
                // this.clients = _.filter(data3, (y) => {
                //   console.log(y.payload.val());
                //   return y.payload.val().isClient === 'true';
                // });
                this.clients = data3;
                console.log(this.clients);
                this.countVouchers = _.filter(this.clients, (y) => {
                  console.log(y.payload.doc.id);
                  this.operationsService.countClientVouchers(y.payload.doc.id).subscribe((vdata: any) => {
                    console.log(vdata);
                    for (let z = 0; z < vdata.length; z++) {
                      const length = vdata[z].storeAllVouchers.length;
                      totalLength = totalLength + vdata[z].storeAllVouchers.length;

                      if (vdata.length - 1 === z) {
                        if (totalLength <= 10) {
                          this.vcount = this.vcount + 1;
                        }
                        totalLength = 0;
                      }
                    }
                    console.log('Kuta');
                  });
                  return y;
                });
                this.storesCount = this.clients.length;
                this.hideloading = false;
              });
              // ===================================

              this.operationsService.getVouchers().pipe(first()).toPromise().then(data1 => {

                this.getResult = _.chain(data1)
                  .groupBy('store')
                  .toPairs()
                  .map((currentItem) => {
                    counter = counter + 1;
                    const getItems = {
                      i: counter,
                      name: currentItem[0],
                      numberOfShops: currentItem[1].length
                    };
                    this.countShops.push(getItems);
                    return _.toPlainObject(_.zip(['date', 'store', 'transactionAmmount', 'ref'], currentItem));
                  })
                  .value();

                this.countShops = _.orderBy(this.countShops, ['numberOfShops'], ['desc']);
                this.vouchersBought = data1.length;
              });

            } else {
              this.loadVouchers(dataa.storename);
            }
          }
        }
      }
    });

    // this.operationsService.getGarages().subscribe(data => {
    //   this.garages = data;
    //   this.markers = data;
    // });

    // this.operationsService.getStores().subscribe(data => {
    //   this.stores = data;
    //   this.storesCount = data.length;
    // });
  }

}
