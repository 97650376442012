import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { OperationsService } from '../shared/operations.service';
import * as XLSX from 'xlsx';
import { AngularFireDatabase } from 'angularfire2/database';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';
import { first, take } from 'rxjs/operators';
import { AngularFireAuth } from 'angularfire2/auth';

@Component({
  selector: 'app-outofstock',
  templateUrl: './outofstock.component.html',
  styleUrls: ['./outofstock.component.css']
})
export class OutofstockComponent implements OnInit {

  public isCollapsed = true;
  public isGarageCollapsed = true;
  public isAddCollapsed = true;
  public isVoucherCollapsed = true;
  public isRedeemed = true;
  isRedeem: any;
  closeResult: string;
  uid: any;
  name: string;
  searchText: any;
  userDetails: any;
  file: File;
  arrayBuffer: any;
  comuid: any;
  message: any;
  TypeOfUser: any;
  logoUrl: any;
  currentVouchers: any;
  loadedVouchers: any;
  numVouchers: any;
  storename: any;
  hideloading: boolean;

  countAmts = [];
  getResult: any;

  amount: any;
  boughtVouchers: any;
  filterObj: any;
  filterNumber: any;
  storeVouchers: any;
  vcount: any;
  clients: any;
  countVouchers: any;

  constructor(private router: Router, private route: ActivatedRoute, private operationsService: OperationsService
    , private afd: AngularFireDatabase, private modalService: NgbModal, private afAuth: AngularFireAuth) {
    this.hideloading = true;
    this.filterObj = [{ number: 10 }
      , { number: 20 }
      , { number: 50 }
      , { number: 70 }
      , { number: 100 }];
    $(document).ready(function () {

      // $('#sidebar').mCustomScrollbar({
      //   theme: 'minimal'
      // });

      $('#sidebarCollapse').on('click', function () {
        // open or close navbar
        $('#sidebar').toggleClass('active');
        // close dropdowns
        $('.collapse.in').toggleClass('in');
        // and also adjust aria-expanded attributes we use for the open/closed arrows
        // in our CSS
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
      });

    });
    this.route.queryParams.subscribe(params => {
      this.uid = params['uid'];
      console.log(this.uid);
      this.comuid = params['comuid'];
      this.amount = params['amount'];
      console.log(this.amount);
      // this.boughtVouchers = JSON.parse(params['boughtVouchers']);
      // console.log(this.boughtVouchers);
      // this.loadedVouchers = _.filter(this.boughtVouchers, (x: any) => {
      //   console.log(x);
      //   return x.transactionAmmount === parseFloat(this.amount);
      // });
      // console.log(this.loadedVouchers);
      // this.storeVouchers = this.loadedVouchers;
    });

  }
  gotoCharge() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/bluelabel'], navigationExtras);
  }

  getNumber(e) {
    console.log(e);
    this.filterNumber = e.srcElement.value;
    this.storeVouchers = this.loadedVouchers.slice(0, this.filterNumber);
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  public convertToTable(sheet: any) {
    const result = [];
    let row;
    let rowNum;
    let colNum;
    const range = XLSX.utils.decode_range(sheet['!ref']);
    for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
      row = [];
      for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
        const nextCell = sheet[
          XLSX.utils.encode_cell({ r: rowNum, c: colNum })
        ];
        if (typeof nextCell === 'undefined') {
          row.push(void 0);
        } else {
          row.push(nextCell.w);
        }
      }
      result.push(row);
    }
    return result;
  }
  gotoLockuser() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/lockeduser'], navigationExtras);
  }

  gotoLowStock(x) {
    console.log(x);
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid,
        comuid: x
      }, skipLocationChange: true
    };
    this.router.navigate(['/lowstockdom'], navigationExtras);
  }
  checkSupplier(data) {
    this.vcount = 0;
    let totalLength = 0;
    const loadedVouchers = [];

    this.userDetails = data;
    this.name = this.userDetails.name;
    this.TypeOfUser = this.userDetails.userType;
    this.isRedeem = this.userDetails.isRedeem;
    if (!this.isRedeem) {
      this.isRedeem = false;
      console.log(this.isRedeem);
    } else {
      this.isRedeem = true;
      console.log(this.isRedeem);
    }
    if (!this.name) {
      this.name = this.userDetails.fname + ' ' + this.userDetails.sname;
    }
    if (data.userType === 'superAdmin') {
      this.operationsService.getClientsListId().pipe(first()).toPromise().then(data3 => {
        console.log(data3);
        // this.clients = _.filter(data3, (y) => {
        //   console.log(y.payload.val());
        //   return y.payload.val().isClient === 'true';
        // });
        console.log(this.clients);
        this.clients = data3;
        this.countVouchers = _.filter(this.clients, (y) => {
          console.log(y);
          this.comuid = y.key;
          this.operationsService.countClientVouchers(y.payload.doc.id).pipe(first()).toPromise().then((vdata: any) => {
            console.log(vdata);
            this.hideloading = false;
            for (let z = 0; z < vdata.length; z++) {
              const length = vdata[z].storeAllVouchers.length;
              totalLength = totalLength + vdata[z].storeAllVouchers.length;
              console.log(totalLength);
              if (vdata.length - 1 === z) {
                if (totalLength <= 10) {
                  this.vcount = this.vcount + 1;
                  loadedVouchers.push({ companyName: vdata[z].companyName, numberLeft: totalLength, uid: y.payload.doc.id });
                  console.log(loadedVouchers);
                  this.loadedVouchers = loadedVouchers;
                }
                totalLength = 0;
              }
            }
          });
          return y;
        });
      });
    } else {
      this.operationsService.countClientVouchers(this.uid).pipe(first()).toPromise().then((vdata: any) => {
        console.log(vdata);
        this.hideloading = false;
        for (let z = 0; z < vdata.length; z++) {
          const length = vdata[z].storeAllVouchers.length;
          totalLength = totalLength + vdata[z].storeAllVouchers.length;
          console.log(totalLength);
          if (vdata.length - 1 === z) {
            if (totalLength <= 10) {
              this.vcount = this.vcount + 1;
              loadedVouchers.push({ companyName: vdata[z].companyName, numberLeft: totalLength, uid: this.uid });
              console.log(loadedVouchers);
              this.loadedVouchers = loadedVouchers;
            }
            totalLength = 0;
          }
        }
      });
    }
  }

  ngOnInit() {
    this.vcount = 0;
    // let totalLength = 0;
    const loadedVouchers = [];
    console.log(this.comuid);

    // this.operationsService.getDetails(this.comuid).subscribe(data => {
    //   // this.storename = data.storename;
    // });

    this.operationsService.getDetails(this.uid).subscribe(data => {
      console.log(data);
      if (!data) {
        this.operationsService.getClientsDetails(this.uid).subscribe(data2 => {
          this.checkSupplier(data2.payload.data());
        });
      } else {
        this.checkSupplier(data);
      }
    });
  }
  gotoPromotional() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/specials'], navigationExtras);
  }

  gotoLogin() {
    this.operationsService.updateSignInStatus(this.uid, false);
    this.afAuth.auth.signOut();
    this.router.navigateByUrl('/login');
  }

  gotohome() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/home'], navigationExtras);
  }
  gotoVouchersBought() {
    if (this.userDetails.userType === 'superAdmin') {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['selectbought'], navigationExtras);
    } else {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['/vouches'], navigationExtras);
    }
  }
  gotoAddClients() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-client'], navigationExtras);
  }
  gotoClients() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/clients'], navigationExtras);
  }
  gotoAddNewVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-new-vouchers'], navigationExtras);
  }
  gotoCardTemplates() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/card-templates'], navigationExtras);
  }
  gotoAddCardTemplate() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-card-template'], navigationExtras);
  }
  gotoAvailableVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/availvouchers'], navigationExtras);
  }
  gotoAddCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addsuppcat'], navigationExtras);
  }
  gotoAddtemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addtempcat'], navigationExtras);
  }
  gotoDeltemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/deltempcat'], navigationExtras);
  }
  gotoDelsupplierCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/delsuppcat'], navigationExtras);
  }
  gotoReemvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeem'], navigationExtras);
  }
  gotoRedeemedvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeemedvouchers'], navigationExtras);
  }
}
