import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFirestore } from 'angularfire2/firestore';
import { Client } from '../../models/clients';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as firebase from 'firebase';
import { OperationsService } from '../shared/operations.service';
import { first, take } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-edit-suppliers',
  templateUrl: './edit-suppliers.component.html',
  styleUrls: ['./edit-suppliers.component.css']
})
export class EditSuppliersComponent implements OnInit {
  public isCollapsed = true;
  public isGarageCollapsed = true;
  public isAddCollapsed = true;
  public isVoucherCollapsed = true;
  public isRedeemed = true;
  closeResult: string;
  public client = {} as Client;
  file: any;
  message: string;
  categories: any;
  selectedCat: any;
  uid: any;
  name: string;
  searchText: any;
  userDetails: any;
  userType: any;
  selectedUserType: any;
  TypeOfUser: any;
  storeobj: any;
  storestr: any;
  key: any;
  catType: any;
  storecellno: any;
  commission: any;
  discount: any;
  serviceCharge: any;
  isFeatured: any;
  isComing: any;
  isRedeem: any;
  storecat: any;
  specialText: any;
  isBlocked: any;
  isBlockedd: any;
  promotionalText: any;
  hideloading: boolean;
  selectedShop: any;
  isMain: any;
  mainStores: any;
  mainFranchiseUrl: any;
  selectedShopObj: any;
  tempPassword: any;
  checkIfClicked: any;
  checkTempPassword: any;
  isUser: any;
  customerNo: any;

  constructor(private router: Router, private route: ActivatedRoute, private afAuth: AngularFireAuth, private afd: AngularFireDatabase
    , private modalService: NgbModal, private operationsService: OperationsService, private afs: AngularFirestore) {
    this.hideloading = true;
    this.isFeatured = false;
    this.isBlockedd = false;
    this.isComing = undefined;
    this.isMain = false;
    this.isRedeem = false;
    this.isUser = false;
    $(document).ready(function () {

      // $('#sidebar').mCustomScrollbar({
      //   theme: 'minimal'
      // });

      $('#sidebarCollapse').on('click', function () {
        // open or close navbar
        $('#sidebar').toggleClass('active');
        // close dropdowns
        $('.collapse.in').toggleClass('in');
        // and also adjust aria-expanded attributes we use for the open/closed arrows
        // in our CSS
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
      });

    });
    // this.categories = [{ categoryName: 'TOP 20 Featured' }
    //   , { categoryName: 'House & Home' }
    //   , { categoryName: 'Prepaid services' }
    //   , { categoryName: 'Kids Toys' }
    //   , { categoryName: 'Restaurants' }
    //   , { categoryName: 'Beauty & Care' }
    //   , { categoryName: 'Baby' }
    //   , { categoryName: 'Sports' }
    //   , { categoryName: 'Computer HW' }];

    this.userType = [{ type: 'Franchise' }
      , { type: 'Shop' }];

    this.route.queryParams.subscribe(params => {
      this.uid = params['uid'];
      this.storestr = params['storeobj'];
    });
     }

  checkFeatured(e) {
    console.log(e);
    // if (e === true) {
    //   this.isFeatured = true;
    //   console.log(this.isFeatured);
    // } else if (e === false) {
    //   this.isFeatured = false;
    // }

    if (e === true) {
      this.isFeatured = true;
      const formData1 = new FormData();
      formData1.append('logoUrl', this.storeobj.logoUrl);
      formData1.append('featured', this.isFeatured);
      this.operationsService.updateisFeatured(this.key, this.isFeatured);
      this.operationsService.updateFeatured(formData1).subscribe(data => {
        console.log(data);
      });
    } else if (e === false) {
      this.isFeatured = false;
      const formData1 = new FormData();
      formData1.append('logoUrl', this.storeobj.logoUrl);
      formData1.append('featured', this.isFeatured);
      this.operationsService.updateisFeatured(this.key, this.isFeatured);
      this.operationsService.updateFeatured(formData1).subscribe(data => {
        console.log(data);
      });
    }
  }
  checkMain(e) {
    console.log(e);
    if (e === true) {
      this.isMain = true;
    } else if (e === false) {
      this.isMain = false;
    }
  }

  checkUser(e) {
    console.log(e);
    if (e === true) {
      this.isUser = true;
    } else if (e === false) {
      this.isUser = false;
    }
  }

  checkRedeem(e) {
    console.log(e);
    this.checkIfClicked = e;
    if (e === true) {
      this.isRedeem = true;
      // this.operationsService.updateisRedeem(this.key, this.isRedeem).then(data => {
      //   console.log(data);
      // });
    } else if (e === false) {
      this.isRedeem = false;
      // this.operationsService.updateisRedeem(this.key, this.isRedeem).then(data => {
      //   console.log(data);
      // });
    }
  }

  checkComingSoon(e) {
    console.log(e);
    if (e === true) {
      this.isComing = true;
      console.log(this.isComing);
      const formData1 = new FormData();
      formData1.append('logoUrl', this.storeobj.logoUrl);
      formData1.append('isComing', this.isComing);
      this.operationsService.updateComingsoon(formData1).subscribe(data => {
        console.log(data);
      });
    } else if (e === false) {
      this.isComing = undefined;
        const formData1 = new FormData();
        formData1.append('logoUrl', this.storeobj.logoUrl);
        formData1.append('isComing', this.isComing);
        this.operationsService.updateComingsoon(formData1).subscribe(data => {
          console.log(data);
        });
    }
  }
  goBack() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/clients'], navigationExtras);
  }
  gotoPromotional() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/specials'], navigationExtras);
  }
  dataChanged(x) {
    this.selectedCat = x.srcElement.value;
  }
  updateClientInfo(client, content) {

    if (this.selectedCat === 'TOP 20 Featured') {
      this.storecat = 'topfeatured';
    } else if (this.selectedCat === 'House & Home') {
      this.storecat = 'houseandhome';
    } else if (this.selectedCat === 'Prepaid services') {
      this.storecat = 'prepaid';
    } else if (this.selectedCat === 'Kids Toys') {
      this.storecat = 'kids';
    } else if (this.selectedCat === 'Restaurants') {
      this.storecat = 'restaurants';
    } else if (this.selectedCat === 'Beauty & Care') {
      this.storecat = 'beauty';
    } else if (this.selectedCat === 'Baby') {
      this.storecat = 'baby';
    } else if (this.selectedCat === 'Sports') {
      this.storecat = 'sports';
    } else if (this.selectedCat === 'Computer HW') {
      this.storecat = 'computer';
    }

    console.log(this.specialText);
    console.log(this.isMain);
    console.log(this.mainFranchiseUrl);
    if (client.storename === this.selectedShop) {
      this.selectedShop = null;
    }
    if (!this.selectedShop && this.isMain === false) {
      this.message = 'Please check main shop or select select a shop';

      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    } else {
      // this.selectedCat = this.storecat;
      if (!this.client.storename) {
        this.message = 'Please enter store name';

        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      } else if (!this.client.email) {
        this.message = 'Please enter email';

        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      } else if (!this.storecellno) {
        this.message = 'Please enter cell number';

        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      } else if (!this.client.address) {
        this.message = 'Please enter physical address';

        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      } else if (!this.commission) {
        this.message = 'Please enter commission';

        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      } else if (!this.serviceCharge) {
        this.message = 'Please enter service charge';

        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      } else if (!this.discount) {
        this.message = 'Please enter discount';

        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      } else {
        console.log(this.isUser);
        const ref1 = Math.floor((Math.random() * 1000000) + 10000);
        const ref2 = Math.floor((Math.random() * 100000) + 10000);
        console.log(this.storecellno);
        this.customerNo = "219" + ref1.toString().substring(3, 2) + this.storecellno.substring(5, 3) + ref2.toString().substring(1, 3);
        if (this.isUser === true) {
          this.afs.collection('userProfile')
            .doc(this.key)
            .set({
              name: this.storeobj.fname + ' ' + this.storeobj.sname,
              cellno: this.storecellno,
              email: client.email,
              type: 'customer',
              pin: "00000",
              walletBalance: 0,
              customerNo: this.customerNo,
              option: true,
              v24user: true
            });
        } else if (this.isUser === false) {
          this.afs.collection('userProfile')
            .doc(this.key)
            .delete();
        }
        if (this.isMain === true) {
          this.selectedShop = client.storename;
          this.mainFranchiseUrl = this.storeobj.logoUrl;
          console.log(this.selectedShop);

          this.afs
          .collection('/mainstores')
            .doc(this.key)
            .set({
              storename: client.storename.toLowerCase(), // shoprite
              logoUrl: this.storeobj.logoUrl
            });
        } else {
          if (!this.selectedShop) {
            this.selectedShop = client.storename;
          } else {
            this.selectedShop = this.selectedShop;
          }
          this.afs
            .collection('/mainstores')
            .doc(this.key)
            .delete();
        }
        console.log(this.isRedeem);
        console.log(this.checkIfClicked);
        if (this.checkIfClicked === false || this.checkIfClicked === true) {
          console.log(this.checkIfClicked);
            console.log(this.isRedeem);
            if (this.isRedeem === false) {
              console.log(this.isRedeem);
            this.operationsService.updateisRedeem(this.key, this.isRedeem).then(data => {
              console.log(data);
            });

          } else if (this.isRedeem === true) {
              if (this.checkTempPassword === 'modified') {
                console.log(this.isRedeem);
                this.operationsService.updateisRedeem(this.key, this.isRedeem).then(data => {
                  console.log(data);
                });
                // this.message = 'Supplier will not receive One time PIN because they have already changed their password, supplier can change their password by clicking the reset link on login screen';

                // this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                //   this.closeResult = `Closed with: ${result}`;
                // }, (reason) => {
                //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                // });
                alert('Supplier will not receive One time PIN because they have already changed their password, supplier can change their password by clicking the reset link on login screen');
              } else {
                console.log(this.isRedeem);
                this.operationsService.updateisRedeem(this.key, this.isRedeem).then(data => {
                  console.log(data);
                });
                const emailBody = `{"personalizations": [{"to": [{"email": "` + this.client.email.toLowerCase().trim() + `"}]}],"from":
    { "email": "Admin@voucher24.co.za"  },
    "subject": "Voucher24 temporary password", "content":
    [{ "type":  "text/html", "value": "<p>Hi ` + this.storeobj.fname + ` </p> </br> <p> Here is your temporary password ` + this.storeobj.tempPassword + `</p> </br> <p>Your Voucher24 team</p></br> <p>Website: https://www.voucher24.co.za</p><img class='max-width' border='0' style='display:block;color:#000000;text-decoration:none;font-family:Helvetica, arial, sans-serif;font-size:16px;max-width:50% !important;width:50%;height:auto !important;' src='https://marketing-image-production.s3.amazonaws.com/uploads/acdcbead2e22931a554192093e3dcefc0a5289173988f81eab6e635f1748c3e9940e4a745b7f56de687b3356f58c2f7da1e65de44e792838fc1f7f22035a5846.png' alt='' width='300'></div>" }]}`;
                console.log(emailBody);
                this.operationsService.getKey().subscribe(data2 => {
                  this.operationsService.sendTemppassword(emailBody, data2.key).subscribe(data4 => {
                    console.log(data4);
                  });
                });
              }
            }
        }

        if (this.specialText === ' store.') {
          this.specialText = null;
        } else {
          this.specialText = this.specialText;
        }

        this.operationsService.updateSupplier(this.key, this.client.address
          , this.storecellno, this.client.email.toLowerCase().trim(), this.selectedCat, this.file
          , this.commission, this.discount, this.serviceCharge, this.isComing
          , this.client.storename, this.isFeatured, this.specialText, this.promotionalText, this.isMain, this.selectedShop
          , this.mainFranchiseUrl, this.storeobj.logoUrl, this.isUser).then(data => {

            const formData1 = new FormData();
            console.log(this.storeobj.logoUrl);
            formData1.append('logoUrl', this.storeobj.logoUrl);
            formData1.append('category', this.selectedCat);
            formData1.append('storename', this.client.storename);
            console.log(this.client.storename);
            this.operationsService.updateCat(formData1).subscribe(data2 => {
              console.log(data2);
            });
            const unsubb = this.afs.collection('supplierVouchers').doc(this.key).collection('vouchers').snapshotChanges().subscribe(data1 => {
              console.log(data1);
              for (let j = 0; j < data1.length; j++) {
                this.operationsService.updateCharges(this.key, data1[j].payload.doc.id, this.commission, this.discount, this.serviceCharge, this.specialText).then(data3 => {
                console.log(data3);
                unsubb.unsubscribe();
              });
              }
            });
            // firebase.database().ref('userProfile/' + this.key + '/vouchers').orderByKey().on('child_added', (data1) => {
            //   console.log(data1.key);
            //   // tslint:disable-next-line:max-line-length
            //   this.operationsService.updateCharges(this.key, data1.key, this.commission, this.discount, this.serviceCharge, this.specialText).then(data3 => {
            //     console.log(data3);
            //   });

            // });
            this.message = 'Successfully updated';

            this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
              this.goBack();
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          });
      }
    }
  }
  gotoCharge() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/bluelabel'], navigationExtras);
  }
  blockVouchers(e) {

    console.log(e);
    if (e === true) {
      this.isBlockedd = true;
      console.log(this.storeobj.logoUrl);
      const formData1 = new FormData();
      formData1.append('logoUrl', this.storeobj.logoUrl);
      formData1.append('isBlocked', this.isBlockedd);
      this.operationsService.updateIsBlocked(this.key, this.isBlockedd);
      this.operationsService.blockVouchers(formData1).subscribe(data => {
        console.log(data);
      });
    } else if (e === false) {
      this.isBlockedd = false;
      const formData1 = new FormData();
      formData1.append('logoUrl', this.storeobj.logoUrl);
      formData1.append('isBlocked', this.isBlockedd);
      this.operationsService.updateIsBlocked(this.key, this.isBlockedd);
      this.operationsService.blockVouchers(formData1).subscribe(data => {
        console.log(data);
      });
    }

  }
  selectShop(x) {
    console.log(x);
    this.selectedShop = x.srcElement.value;
    this.selectedShopObj = _.filter(this.mainStores, (y) => {
      return y.storename === this.selectedShop;
    });
    console.log(this.selectedShopObj);
    this.mainFranchiseUrl = this.selectedShopObj[0].logoUrl;
  }

  ngOnInit() {
    console.log(this.storestr);

    if (!this.uid) {

    } else {
      this.operationsService.getSupplierCat().subscribe(data => {
        console.log(data);
        this.categories = data;
        console.log(this.categories);
      });
      this.operationsService.getMainshops().subscribe(data => {
        console.log(data);
        this.mainStores = data;
        console.log(this.mainStores);
      });
      this.storeobj = JSON.parse(this.storestr);
      console.log(this.storeobj);
      this.isMain = this.storeobj.isMain;
      this.isUser = this.storeobj.isUser;
      if (!this.storeobj.isUser) {
        this.isUser = false;
      } else {
        this.isUser = this.storeobj.isUser;
      }
      this.isBlockedd = this.storeobj.isBlocked;
      console.log(this.isBlockedd);
      this.client.address = this.storeobj.address;
      this.client.storename = this.storeobj.storename;
      this.storecellno = this.storeobj.storecellno;
      this.selectedCat = this.storeobj.catType;
      this.checkTempPassword = this.storeobj.tempPassword;
      console.log(this.checkTempPassword);
      console.log(this.selectedCat);
      this.client.email = this.storeobj.email;
      // ============================
      if (!this.storeobj.specialText) {

      } else {
        if (this.storeobj.specialText === ' store.') {

        } else {
          this.specialText = this.storeobj.specialText;
        }
      }
      // =================
      // if (!this.storeobj.promotionalText) {

      // } else {
      //   if (this.storeobj.promotionalText === 'No promotional text') {
      //   } else {
      //     this.promotionalText = this.storeobj.promotionalText;
      //   }
      // }

      // ============================================
      if (!this.storeobj.commission) {

      } else {
        this.commission = this.storeobj.commission;
      }
      // ================----------==================
      if (!this.storeobj.discount) {

      } else {
        this.discount = this.storeobj.discount;
      }

      if (!this.storeobj.serviceCharge) {

      } else {
        this.serviceCharge = this.storeobj.serviceCharge;
      }
      console.log(this.storeobj.logoUrl);
      this.operationsService.getClientByLogourl(this.storeobj.logoUrl).subscribe(data => {
        this.hideloading = false;
        console.log(data);
        this.key = data[0].payload.doc.id;
        console.log(this.key);
        console.log(this.key);
        this.operationsService.getPromotionalText(this.key).pipe(take(1)).toPromise().then((data2: any) => {
          console.log(data2);
          this.promotionalText = data2.promotionalText;
        });
        // this.operationsService.getVouchersUid(this.key, this.client.storename).then(data1 => {
        //   console.log(data1);
        //   const key1 = data1.key;
        //   console.log(key1);

        // });
    });
      const formData2 = new FormData();
      formData2.append('logoUrl', this.storeobj.logoUrl);
      this.operationsService.getBlockedVouchers(formData2).subscribe(data => {
        console.log(data);
        if (data.isBlocked === 'true') {
          this.isBlockedd = true;
          console.log(this.isBlockedd);
        } else if (data.isBlocked === 'false') {
          this.isBlockedd = false;
          console.log(this.isBlockedd);
        } else {
          this.isBlockedd = false;
          console.log(this.isBlockedd);
        }

        this.isRedeem = this.storeobj.isRedeem;
        if (!this.isRedeem) {
          this.isRedeem = false;
          console.log(this.isRedeem);
        } else if (this.isRedeem === true) {
          this.isRedeem = true;
          console.log(this.isRedeem);
        } else if (this.isRedeem === false) {
          this.isRedeem = false;
          console.log(this.isRedeem);
        }


        if (data.featured === 'true') {
          this.isFeatured = true;
          console.log(this.isFeatured);
        } else if (data.featured === 'false') {
          this.isFeatured = false;
          console.log(this.isFeatured);
        } else {
          this.isFeatured = false;
          console.log(this.isFeatured);
        }

        if (data.isComing === 'true') {
          this.isComing = true;
          console.log(this.isComing);
        } else if (data.isComing === 'false') {
          this.isComing = false;
          console.log(this.isComing);
        } else {
          this.isComing = false;
          console.log(this.isComing);
        }
      });
      this.operationsService.getDetails(this.uid).pipe(take(1)).toPromise().then(data => {
        console.log(data);
        this.userDetails = data;
        this.name = this.userDetails.name;
        this.TypeOfUser = this.userDetails.userType;
        if (!this.name) {
          this.name = this.userDetails.fname + ' ' + this.userDetails.sname;
        }
      });
    }
  }
  gotoLockuser() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/lockeduser'], navigationExtras);
  }
  logoUpload(event: any, content) {
    this.file = event.target.files[0];
    if (this.isMain === true) {
      this.message = 'You are seeing this message because you are about to update the logo of the main supplier, which may break linked suppliers that fall under this supplier, be sure to update suppliers that fall under this supplier';

      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  gotoLogin() {
    this.operationsService.updateSignInStatus(this.uid, false);
    this.afAuth.auth.signOut();
    this.router.navigateByUrl('/login');
  }

  gotohome() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/home'], navigationExtras);
  }
  gotoVouchersBought() {
    if (this.userDetails.userType === 'superAdmin') {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['selectbought'], navigationExtras);
    } else {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['/vouches'], navigationExtras);
    }
  }
  gotoAddClients() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-client'], navigationExtras);
  }
  gotoClients() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/clients'], navigationExtras);
  }
  gotoAddNewVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-new-vouchers'], navigationExtras);
  }
  gotoCardTemplates() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/card-templates'], navigationExtras);
  }
  gotoAddCardTemplate() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-card-template'], navigationExtras);
  }
  gotoAvailableVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/availvouchers'], navigationExtras);
  }
  gotoAddCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addsuppcat'], navigationExtras);
  }
  gotoAddtemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addtempcat'], navigationExtras);
  }
  gotoDeltemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/deltempcat'], navigationExtras);
  }
  gotoDelsupplierCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/delsuppcat'], navigationExtras);
  }
  gotoReemvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeem'], navigationExtras);
  }
  gotoRedeemedvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeemedvouchers'], navigationExtras);
  }

}
