import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { OperationsService } from '../shared/operations.service';
import { VouchersService } from '../services/vouchers.service';
import * as _ from 'lodash';
import { AngularFireAuth } from 'angularfire2/auth';
import * as moment from 'moment';
// import * as pdfmake from 'pdfmake';
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
// pdfmake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-redeemedvouchers',
  templateUrl: './redeemedvouchers.component.html',
  styleUrls: ['./redeemedvouchers.component.css']
})
export class RedeemedvouchersComponent implements OnInit {

  public isCollapsed = true;
  public isGarageCollapsed = true;
  public isAddCollapsed = true;
  public isVoucherCollapsed = true;
  public isRedeemed = true;
  closeResult: string;
  stores: any;
  uid: any;
  name: string;
  searchText: any;
  userDetails: any;
  TypeOfUser: any;
  cats: any;
  hideloading: boolean;
  vouchers: any;
  base64: any;

  constructor(private router: Router, private route: ActivatedRoute, private operationsService: OperationsService,
    private afAuth: AngularFireAuth, private vs: VouchersService) {
     //  console.log(pdfmake);
    this.hideloading = true;
    this.route.queryParams.subscribe(params => {
      this.uid = params['uid'];
    });
    $(document).ready(function () {

      // $('#sidebar').mCustomScrollbar({
      //   theme: 'minimal'
      // });

      $('#sidebarCollapse').on('click', function () {
        // open or close navbar
        $('#sidebar').toggleClass('active');
        // close dropdowns
        $('.collapse.in').toggleClass('in');
        // and also adjust aria-expanded attributes we use for the open/closed arrows
        // in our CSS
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
      });

    });

    // pdfmake.fonts = {
    //   Roboto: {
    //     normal: 'Roboto-Regular.ttf',
    //     bold: 'Roboto-Medium.ttf',
    //     italics: 'Roboto-Italic.ttf',
    //     bolditalics: 'Roboto-Italic.ttf'
    //   },
    //   Courier: {
    //     normal: 'Courier',
    //     bold: 'Courier-Bold',
    //     italics: 'Courier-Oblique',
    //     bolditalics: 'Courier-BoldOblique'
    //   },
    //   Helvetica: {
    //     normal: 'Helvetica',
    //     bold: 'Helvetica-Bold',
    //     italics: 'Helvetica-Oblique',
    //     bolditalics: 'Helvetica-BoldOblique'
    //   },
    //   Times: {
    //     normal: 'Times-Roman',
    //     bold: 'Times-Bold',
    //     italics: 'Times-Italic',
    //     bolditalics: 'Times-BoldItalic'
    //   },
    //   Symbol: {
    //     normal: 'Symbol'
    //   },
    //   ZapfDingbats: {
    //     normal: 'ZapfDingbats'
    //   }
    // };

    // console.log(pdfmake);

  }
  gotoLockuser() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/lockeduser'], navigationExtras);
  }
  gotoPromotional() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/specials'], navigationExtras);
  }
  gotoCharge() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/bluelabel'], navigationExtras);
  }

  delete(x) {
    console.log(x);
    this.operationsService.getOneTemplate(x.category);
  }

  gotoEdit(x) {
    console.log(x);
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid,
        cat: JSON.stringify(x)
      }, skipLocationChange: true
    };
    this.router.navigate(['/editsupptemp'], navigationExtras);
  }
  checkSupplier(data) {
    this.hideloading = false;
    this.userDetails = data;
    this.name = this.userDetails.name;
    this.TypeOfUser = this.userDetails.userType;
    if (!this.name) {
      this.name = this.userDetails.fname + ' ' + this.userDetails.sname;
    }
  }
  ngOnInit() {

    this.vs.getBase64().subscribe(data => {
      // console.log(data);
      this.base64 = data.replace(/(\r\n|\n|\r)/gm, '');
      // console.log(this.base64);
    });

    this.operationsService.getDetails(this.uid).subscribe(data => {
      if (!data) {
        this.operationsService.getClientsDetails(this.uid).subscribe(data2 => {
          this.checkSupplier(data2.payload.data());
        });
      } else {
        this.checkSupplier(data);
      }
    });
    console.log(this.uid);
    this.vs.getAllRedeemedvouchers(this.uid).subscribe(data => {
      console.log(data);
      this.vouchers = data;
    });
  }
  gotoLogin() {
    this.operationsService.updateSignInStatus(this.uid, false);
    this.afAuth.auth.signOut();
    this.router.navigateByUrl('/login');
  }

  gotohome() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/home'], navigationExtras);
  }
  gotoVouchersBought() {
    if (this.userDetails.userType === 'superAdmin') {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['selectbought'], navigationExtras);
    } else {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['/vouches'], navigationExtras);
    }
  }
  gotoAddClients() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-client'], navigationExtras);
  }
  gotoClients() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/clients'], navigationExtras);
  }
  gotoAddNewVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-new-vouchers'], navigationExtras);
  }
  gotoCardTemplates() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/card-templates'], navigationExtras);
  }
  gotoAddCardTemplate() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-card-template'], navigationExtras);
  }
  gotoAvailableVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/availvouchers'], navigationExtras);
  }
  gotoAddCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addsuppcat'], navigationExtras);
  }
  gotoAddtemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addtempcat'], navigationExtras);
  }
  gotoDeltemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/deltempcat'], navigationExtras);
  }
  gotoDelsupplierCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/delsuppcat'], navigationExtras);
  }
  gotoReemvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeem'], navigationExtras);
  }
  gotoRedeemedvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeemedvouchers'], navigationExtras);
  }
  // email(x) {
  //   let totalAmount = 0;
  //   const transactions = [];
  //   this.vouchers.forEach((item) => {
  //     console.log(item);
  //     totalAmount = totalAmount + parseFloat(item.amount);
  //     transactions.push({
  //       amount: item.amount,
  //       cardNumber: item.cardNumber,
  //       date: moment(item.date.toDate()).format('YYYY/MM/DD')
  //     });
  //     console.log(transactions);
  //   });

  //   const items = transactions.map((item) => {
  //     console.log(item);
  //     return [item.amount, item.cardNumber, item.date];
  //   });


  //   // Generate PDF

  //   const docDefinition = {

  //     footer: (currentPage, pageCount) => {
  //       return {
  //         text: (currentPage.toString() + ' of ' + pageCount) + ' Powered by Voucher24',
  //         style: ['footer']
  //       }; /* currentPage.toString() + ' of ' + pageCount;*/
  //     },
  //     pageOrientation: 'portrait',
  //     content: [

  //       {
  //         columns: [
  //           [{
  //             width: 200,
  //             image: this.base64
  //           },

  //           ],
  //           [
  //             // second column consists of paragraphs
  //             {
  //               text: 'Voucher24, 34 traderoute, Mayersdal, Johannesburg, 2091',
  //               style: ['anotherStyle']
  //             },
  //             {
  //               text: 'South Africa',
  //               style: ['anotherStyle']
  //             },
  //             {
  //               text: '(011) 210 - 3515',
  //               style: ['anotherStyle']
  //             },
  //             {
  //               text: 'Email Info@voucher24.co.za',
  //               style: ['anotherStyle']
  //             },
  //             {
  //               text: 'www.voucher24.co.za',
  //               style: ['anotherStyle']
  //             },
  //           ]
  //         ],
  //         style: ['lineSpacing']
  //       },

  //       {
  //         columns: [
  //           [{
  //             text: this.userDetails.fname + ' ' + this.userDetails.sname
  //           },
  //           {
  //             text: this.userDetails.email
  //           },
  //           ]
  //         ],
  //         style: ['lineSpacing']
  //       },

  //       {
  //         text: 'Terms and Conditions ',
  //         style: ['terms', 'textS']
  //       },
  //       {
  //         text: 'You may access our terms and conditions on our website',
  //         style: ['lineSpacing']
  //       },
  //       {
  //         text: [
  //           'Statement Date: ',
  //           {
  //             text: moment(new Date()).format('DD MMM YYYY HH:mm:ss'),
  //             style: ['anotherStyle']
  //           }
  //         ],
  //         style: ['lineSpacing']
  //       },

  //       {
  //         table: {

  //           body: [
  //             ['Please contact us within 30 days from your statement date, should you wish to query an entry on this statement.Should we not hear from you, we will assume that you have received the statement and it is correct.']
  //           ]
  //         },
  //         style: ['lineSpacing']
  //       },
  //       {
  //         text: 'Total Amount R' + totalAmount.toFixed(2),
  //         style: ['terms', 'lineSpacing', 'textS']
  //       },
  //       {

  //         table: {
  //           // headers are automatically repeated if the table spans over multiple pages
  //           // you can declare how many rows should be treated as headers
  //           headerRows: 1,
  //           // widths: ['*', 'auto', 100, '*'],

  //           body: [

  //             [{
  //               text: 'Amount',
  //               style: 'tableHeader'
  //             }, {
  //                 text: 'Voucher code',
  //               style: 'tableHeader'
  //             }, {
  //                 text: 'Date',
  //               style: 'tableHeader'
  //             }]


  //             // ['10/05/2018', 'Merchant receiving Payment from customer', '234536ghdew', 'R500.00', 'R2.00'],
  //             // ['10/05/2018', 'Merchant withdrawing funds to bank acct', '098938g7344', 'R350.00', 'R2.00'],
  //             // ['10/05/2018', 'Customer withdrawing funds to bank acct', '448ghgh3434', 'R700.00', 'R2.00'],


  //           ].concat(items)
  //         }
  //       }
  //     ],

  //     styles: {
  //       header: {
  //         fontSize: 22,
  //         bold: true
  //       },
  //       terms: {
  //         bold: true
  //       },
  //       anotherStyle: {
  //         italic: true,
  //         alignment: 'right'
  //       },
  //       'lineSpacing': {
  //         margin: [0, 0, 0, 20] // change number 6 to increase nspace
  //       },
  //       'footer': {
  //         background: '#000000',
  //         color: 'white',
  //         border: '5px'
  //       },
  //       'longtext': {
  //         margin: [0, 0, 0, 20], // change number 6 to increase nspace,
  //         border: true
  //       },
  //       'textS': {
  //         color: '#000000'
  //       },
  //       'tableHeader': {
  //         color: '#000000',
  //         bold: true
  //       }
  //     }
  //   };
  //   console.log(docDefinition);

  //   const pdfDocGenerator = pdfmake.createPdf(docDefinition);
  //   console.log(pdfDocGenerator);

  //   const datee = new Date();
  //   //// console.log(date);
  //   const dat = moment(datee).format('DD_MMM_YYYY_HH_mm_ss');
  //   pdfDocGenerator.open();
  // }

}
