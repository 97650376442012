import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';
import 'firebase/storage';
import { RequestOptions, Headers, Http } from '@angular/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VouchersService {
  item: Observable<any>;
  voucherRef: AngularFireObject<any>;
  postcreate: AngularFirestoreCollection<any>;
  redeemedVoucher: Observable<any>;
  allRedeemedVouchers: Observable<any>;
  insertPosts: AngularFirestoreCollection<any>;

  constructor(private afd: AngularFireDatabase, private afs: AngularFirestore, public ht: Http) { }

  getVouchers() {
    this.item = this.afd.list('/availableVouchers').valueChanges();

    return this.item;
  }
    trigger() {
      const response = this.ht.get('https://cors-anywhere.herokuapp.com/http://voucher24.co.za/voucher24/cron/cron.html').pipe(
        map(res => res.text()) // or any other operator
      );
      return response;
    }
  getBase64() {
    const response = this.ht.get('https://cors-anywhere.herokuapp.com/https://www.voucher24.co.za/voucher24/base64.txt').pipe(
      map(res => res.text()) // or any other operator
    );
    return response;
  }

  insertDoc(uid, url, name) {
    this.insertPosts = this.afs.collection('mainstores');
    return this.insertPosts.doc(uid).set({
      logoUrl: url,
      storename: name
    });
  }
  insertTemplateCard(url, templateType) {
    this.insertPosts = this.afs.collection('templates');
    return this.insertPosts.add({
      logoUrl: url,
      templateType: templateType
    });
  }
  insertVouchers(dt, logoUrl, ref, shop, amt, uniqueNo) {
    this.insertPosts = this.afs.collection('vouchers');
    return this.insertPosts.add({
      date: dt,
      logoUrl: logoUrl,
      ref: ref,
      store: shop,
      transactionAmmount: amt,
      uniqueno: uniqueNo
    });
  }

  insertNewVouchers(obj) {
    console.log(obj);
    this.insertPosts = this.afs.collection('vouchers');
    if (!obj.TransRef) {
      return this.insertPosts.add({
        "date": obj.date,
        "logoUrl": obj.logoUrl,
        "ref": obj.ref,
        "store": obj.store,
        "transactionAmmount": obj.transactionAmmount,
        "uniqueno": obj.uniqueno
      });
    } else {
      return this.insertPosts.add({
        "TransRef": obj.TransRef,
        "date": obj.date,
        "logoUrl": obj.logoUrl,
        "ref": obj.ref,
        "store": obj.store,
        "transactionAmmount": obj.transactionAmmount,
        "uniqueno": obj.uniqueno
      });
    }

  }

  insertSupplier() {
    this.insertPosts = this.afs.collection('userProfile');
    return this.insertPosts.doc('yjQdNM6nvQNBz1sZ9dmRnCzLB0o2').set({
      "cellno": "0839485722",
      "customerNo": "21909420",
      "email": "Delon@iafrica.con",
      "name": "Delon",
      "option": true,
      "pin": "00000",
      "type": "customer",
      "v24user": true,
      "walletBalance": 0
    }).then(dat => {
      console.log(dat);
    });
  }
  insertSuppVouchers(obj) {
    console.log(obj);
    this.insertPosts = this.afs.collection('supplierVouchers');
    return this.insertPosts.doc('e32oCfBGq6UdsJvfPyxDyGyG3Sq2').set({
      obj
    });
  }
  insertSuppVoucher (obj) {

    // .add({
    //   "TransRef": obj.TransRef,
    //   "date": obj.date,
    //   "fee": obj.fee,
    //   "isComplete": obj.isComplete,
    //   "ref": obj.ref,
    //   "store": obj.store,
    //   "transactionAmmount": obj.transactionAmmount,
    //   "transactionType": obj.transactionType
    // });

    console.log('insert');
    if (!obj.TransRef) {
      this.insertPosts = this.afs.collection('usersTransaction');
      return this.insertPosts.doc('xgyhrC686mhJMUf0RZt7LDhp6FG3')
        .collection('transactionHistory')
        .add({
          "date": obj.date,
          "fee": obj.fee,
          "ref": obj.ref,
          "store": obj.store,
          "transactionAmmount": obj.transactionAmmount,
          "transactionType": obj.transactionType
        });
    } else {
      this.insertPosts = this.afs.collection('usersTransaction');
      return this.insertPosts.doc('xgyhrC686mhJMUf0RZt7LDhp6FG3')
        .collection('transactionHistory')
        .add({
          "TransRef": obj.TransRef,
          "date": obj.date,
          "fee": obj.fee,
          "isComplete": obj.isComplete,
          "ref": obj.ref,
          "store": obj.store,
          "transactionAmmount": obj.transactionAmmount,
          "transactionType": obj.transactionType
        });
    }
  }
  insertMainstores(obj) {
    console.log(obj);
    this.insertPosts = this.afs.collection('mainstores');
    return this.insertPosts.doc(obj.uid).set({
      logoUrl: obj.logoUrl,
      storename: obj.storename
    });
  }

  insertSupplierCat(obj) {
    console.log(obj);
    this.insertPosts = this.afs.collection('suppliercategories');
    return this.insertPosts.add({
      category: obj.category
    });
  }
  insertTemplateCat(obj) {
    console.log(obj);
    this.insertPosts = this.afs.collection('templatecategories');
    return this.insertPosts.add({
      category: obj.category
    });
  }
  insertTemplates(obj) {
    console.log(obj);
    this.insertPosts = this.afs.collection('templates');
    return this.insertPosts.add({
      logoUrl: obj.logoUrl,
      templateType: obj.templateType
    });
  }

  setRedeemed(cardNumber, amt, date, uid, email, fullname) {
    return this.afs.collection('redeemed').doc(uid).collection('vouchers').add({
      cardNumber: cardNumber,
      amount: amt,
      redeemed: true,
      date: date,
      email: email,
      fullname: fullname
    });
  }
  getRedeemedVouchers(cardNumber, uid) {
    return this.redeemedVoucher = this.afs.collection('redeemed').doc(uid).collection('vouchers', ref => ref.where('cardNumber', '==', cardNumber)).valueChanges();
  }
  getAllRedeemedvouchers(uid) {
    return this.allRedeemedVouchers = this.afs.collection('redeemed').doc(uid).collection('vouchers').valueChanges();
  }
  getSuppliersVouchers() {
    return this.afs.collection('supplierVouchers').valueChanges();
  }

  getVouchersUid(snapshotKey, file) {
    console.log(file);
    this.voucherRef = this.afd.object('/availableVouchers');

    const storageRef = firebase.storage().ref('/availableVouchers');
    console.log(storageRef);
    console.log(file);
    const uploadLogo = storageRef.child(snapshotKey).child('logo').child(file.name);
    console.log(uploadLogo);

    const uploadTaskLg = uploadLogo.put(file).then(data => {
      console.log(data);

      const storages = firebase.storage();
      // tslint:disable-next-line:max-line-length
      const gsReference = storages.refFromURL('gs://baby-names-app-db-5cf54.appspot.com/availableVouchers/' + snapshotKey + '/logo/' + file.name);
      console.log(gsReference);
      gsReference.getDownloadURL().then((url) => {
        console.log(url);
        const storRef = this.afd.object('/availableVouchers/' + snapshotKey);
        storRef.update({
          logoUrl: url
        });
      });
    });
  }
}
