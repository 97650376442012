import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';

import { FIREBASE_CONFIG } from './app.firebase.config';

import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HashLocationStrategy, Location, LocationStrategy } from '@angular/common';

import { AgmCoreModule } from '@agm/core';
import { OperationsService } from './shared/operations.service';
import { FilterPipe } from './filter.pipe';
import { VouchesBoughtComponent } from './vouches-bought/vouches-bought.component';
import { ClientsComponent } from './clients/clients.component';
import { AddClientComponent } from './add-client/add-client.component';
import { AddNewVouchersComponent } from './add-new-vouchers/add-new-vouchers.component';
import { CardTemplatesComponent } from './card-templates/card-templates.component';
import { EditVoucherComponent } from './edit-voucher/edit-voucher.component';
import { EditCardComponent } from './edit-card/edit-card.component';
import { AddCardTemplateComponent } from './add-card-template/add-card-template.component';
import { AvailableVouchersComponent } from './available-vouchers/available-vouchers.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { FilterstoresPipe } from './pipes/filterstores.pipe';
import { FiltertemplatesPipe } from './pipes/filtertemplates.pipe';
import { SelectstorevouchersComponent } from './selectstorevouchers/selectstorevouchers.component';
import { ViewvouchersComponent } from './viewvouchers/viewvouchers.component';
import { ClickvouchersComponent } from './clickvouchers/clickvouchers.component';
import { ViewclickedvouchersComponent } from './viewclickedvouchers/viewclickedvouchers.component';
import { SelectboughtvouchersComponent } from './selectboughtvouchers/selectboughtvouchers.component';
import { EditSuppliersComponent } from './edit-suppliers/edit-suppliers.component';
import { HttpModule } from '@angular/http';
import { AddtemplatecategoryComponent } from './addtemplatecategory/addtemplatecategory.component';
import { AddsuppliercategoryComponent } from './addsuppliercategory/addsuppliercategory.component';
import { DeletetempcatComponent } from './deletetempcat/deletetempcat.component';
import { DeletesuppliercatComponent } from './deletesuppliercat/deletesuppliercat.component';
import { ViewnewervouchersComponent } from './viewnewervouchers/viewnewervouchers.component';
import { EditsuppcategoryComponent } from './editsuppcategory/editsuppcategory.component';
import { EdittemplatecategoryComponent } from './edittemplatecategory/edittemplatecategory.component';
import { BlockeduserComponent } from './blockeduser/blockeduser.component';
import { BlueServiceChargeComponent } from './blue-service-charge/blue-service-charge.component';
import { OutofstockComponent } from './outofstock/outofstock.component';
import { SpecialsComponent } from './specials/specials.component';
import { ViewvouchersbyshopComponent } from './viewvouchersbyshop/viewvouchersbyshop.component';
import { ViewvouchershopComponent } from './viewvouchershop/viewvouchershop.component';
import { DeletevouchersComponent } from './deletevouchers/deletevouchers.component';
import { ViewlowstockvouchersComponent } from './viewlowstockvouchers/viewlowstockvouchers.component';
import { LowstockdenomComponent } from './lowstockdenom/lowstockdenom.component';
import { GroupshopsComponent } from './groupshops/groupshops.component';
import { RedeemComponent } from './redeem/redeem.component';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { RedeemedvouchersComponent } from './redeemedvouchers/redeemedvouchers.component';
import { ConvertdatePipe } from './pipes/convertdate.pipe';
import { AllvouchersComponent } from './allvouchers/allvouchers.component';
import { ViewallvouchersComponent } from './viewallvouchers/viewallvouchers.component';
import { ViewallvouchersboughtComponent } from './viewallvouchersbought/viewallvouchersbought.component';
import { AllnewervouchersComponent } from './allnewervouchers/allnewervouchers.component';
import { ViewredeemedvouchersComponent } from './viewredeemedvouchers/viewredeemedvouchers.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    FilterPipe,
    VouchesBoughtComponent,
    ClientsComponent,
    AddClientComponent,
    AddNewVouchersComponent,
    CardTemplatesComponent,
    EditVoucherComponent,
    EditCardComponent,
    AddCardTemplateComponent,
    AvailableVouchersComponent,
    ResetPasswordComponent,
    FilterstoresPipe,
    FiltertemplatesPipe,
    SelectstorevouchersComponent,
    ViewvouchersComponent,
    ClickvouchersComponent,
    ViewclickedvouchersComponent,
    SelectboughtvouchersComponent,
    EditSuppliersComponent,
    AddtemplatecategoryComponent,
    AddsuppliercategoryComponent,
    DeletetempcatComponent,
    DeletesuppliercatComponent,
    ViewnewervouchersComponent,
    EditsuppcategoryComponent,
    EdittemplatecategoryComponent,
    BlockeduserComponent,
    BlueServiceChargeComponent,
    OutofstockComponent,
    SpecialsComponent,
    ViewvouchersbyshopComponent,
    ViewvouchershopComponent,
    DeletevouchersComponent,
    ViewlowstockvouchersComponent,
    LowstockdenomComponent,
    GroupshopsComponent,
    RedeemComponent,
    RedeemedvouchersComponent,
    ConvertdatePipe,
    AllvouchersComponent,
    ViewallvouchersComponent,
    ViewallvouchersboughtComponent,
    AllnewervouchersComponent,
    ViewredeemedvouchersComponent,
  ],
  imports: [
    BrowserModule,
    routing,
    HttpModule,
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(FIREBASE_CONFIG),
    AngularFireAuthModule,
    AngularFirestoreModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBPNsqrbfZeshrSyuoMmUn7pmQCRD-9RNU',
      libraries: ['places']
    })
  ],
  providers: [
    OperationsService,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
