import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';

import { AngularFireAuth } from 'angularfire2/auth';
import { Person } from '../../models/person';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { OperationsService } from '../shared/operations.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  email: any;
  message: any;
  closeResult: string;
  uid: any;
  msg1: string;
  msg2: string;

  constructor(private router: Router, private afAuth: AngularFireAuth, private modalService: NgbModal
    , private operationsService: OperationsService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.uid = params['uid'];
    });
     }

  resetPassword(content) {
    this.afAuth.auth.sendPasswordResetEmail((this.email.toLowerCase()).trim())
      .then((user) => {
        console.log(user);
        this.message = 'We just sent you a reset link to your email';
        if (!this.uid) {
          // this.afAuth.auth
        } else {
          this.operationsService.resetPassword(this.uid);
        }

        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
          this.router.navigate(['login']);
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });

      }).catch(error => {
        console.log(error);
        this.message = error.message;
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    console.log(this.uid);
    if (!this.uid) {
      this.msg2 = 'Reset password';
    } else {
      this.msg1 = 'You appear to be using default password';
      this.msg2 = 'Reset password to continue';
    }
  }

}
