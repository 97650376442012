import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';

import { AngularFireAuth } from 'angularfire2/auth';
import { Person } from '../../models/person';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { OperationsService } from '../shared/operations.service';
import { VouchersService } from '../services/vouchers.service';
import { first, take } from 'rxjs/operators';
import * as firebase from 'firebase/app';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    public person = {} as Person;
    closeResult: string;
    message: string;
    hideloading: any;
    fullvoucher: string;

    constructor(private router: Router,
        private afAuth: AngularFireAuth,
        private modalService: NgbModal,
        private operationsService: OperationsService,
        private vs: VouchersService) {

        this.closeResult = '';
        this.message = '';
        // window.close();
        // this.invokeTemplates();
        // this.invokeVouchers();
        // this.invokeVoucher();

        // this.invokeVoucher();
        this.fullvoucher = '32456FDSARE34WRDS';
        console.log(this.fullvoucher.length);
        const getIndex = this.fullvoucher.length - 5;
        console.log(getIndex);
        const newStr = this.fullvoucher.substr(0, getIndex);

        // this.fullvoucher.replace()
        console.log(newStr);

        const hashedString = this.fullvoucher.replace(newStr, '**********');
        console.log(hashedString);

        // this.person.email = 'Lefanyathi@gmail.com';
        // this.person.password = '1234567';
    }

    gotoHome(person: Person, content: any) {
        // person.email = 'lefanyathi@gmail.com';
        // person.password = '12345678';

        // person.email = 'Sikamarazor@outlook.com';
        // person.password = '1234567';
        this.hideloading = true;
        if (!person.email) {
            this.hideloading = false;
        } else if (!person.password) {
            this.hideloading = false;
        } else {
            this.operationsService.getLockedUser(person.email.toLowerCase()).subscribe(data => {
                if (data.status === 'Not available') {
                    this.afAuth.auth.signInWithEmailAndPassword((person.email.toLowerCase()).trim(), person.password).then((result: any) => {
                        console.log(result);
                        if (result) {
                            console.log(result);
                            this.operationsService.getLoggedInStatus(result.user.uid).pipe(take(1)).toPromise().then(data1 => {
                                console.log(data1);
                                if (!data1) {

                                    this.hideloading = false;
                                    const navigationExtras: NavigationExtras = {
                                        queryParams: {
                                            uid: result.user.uid,
                                            password: person.password,
                                            isLogging: true
                                        }, skipLocationChange: true
                                    };
                                    this.router.navigate(['home'], navigationExtras);
                                } else {
                                    if (data1.signedIn === true) {
                                        this.hideloading = false;
                                        this.operationsService.updateSignInStatus(result.user.uid, true);
                                        this.message = `There is an already an account logged in with
                     the same account, please logoff from the logged in device`;
                                        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((results) => {
                                            console.log(results);
                                            if (results === 'logoff') {
                                                this.lockOutdevice(result.user.uid);
                                            }
                                            this.closeResult = `Closed with: ${results}`;
                                        }, (reason) => {
                                            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                                        });
                                    } else {
                                        this.operationsService.updateSignInStatus(result.user.uid, true);

                                        this.hideloading = false;
                                        const navigationExtras: NavigationExtras = {
                                            queryParams: {
                                                uid: result.user.uid,
                                                password: person.password,
                                                isLogging: true
                                            }, skipLocationChange: true
                                        };
                                        this.router.navigate(['home'], navigationExtras);
                                    }
                                }
                            });
                        }
                    }).catch(error => {
                        console.log(error);
                        this.hideloading = false;
                        this.message = error.message;
                        if (error.code === 'auth/too-many-requests') {
                            const formData1 = new FormData();
                            console.log(person.email.toLowerCase());
                            formData1.append('email_address', person.email.toLowerCase());
                            formData1.append('isLocked', 'true');
                            this.operationsService.lockUser(formData1).subscribe(data1 => {
                                console.log(data1);
                            });
                        } else {

                        }
                        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                            this.closeResult = `Closed with: ${result}`;
                        }, (reason) => {
                            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                        });
                    });
                } else {
                    this.hideloading = false;
                    this.message = 'You have been locked after multiple login attempts, please contact the administrator';
                    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                        this.closeResult = `Closed with: ${result}`;
                    }, (reason) => {
                        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                    });
                }
            });
        }
    }

    lockOutdevice(uid: any) {
        console.log('closed');
        this.operationsService.updateSignInStatus(uid, false);
    }

    open(content: any) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    gotoReset() {
        this.router.navigate(['reset']);
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    invokeMainstores() {
        // const obj = [
        //   {
        //     'uid': "-LTbfQgdJ_F2CEcPNqcT",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2F-LTbfQgdJ_F2CEcPNqcT%2Flogo%2Ftakealotapp.jpg?alt=media&token=165980fc-3a5d-4ae9-8bce-f0ccab0a3f5b",
        //     "storename": "takealot"
        //   }, {
        //     'uid': "-LTcuaeq5wKP36UoQLum",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2F-LTcuaeq5wKP36UoQLum%2Flogo%2Fwoolworthsapp.jpg?alt=media&token=652c6d98-7044-492c-926f-cc2fad1f5a15",
        //     "storename": "woolworths"
        //   }, {
        //     'uid': "0RR6iOzcrXNhinivR4R5XoPhFEC3",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2F0RR6iOzcrXNhinivR4R5XoPhFEC3%2Flogo%2Fincredibleconnection.jpg?alt=media&token=df637ee9-2cdf-4ece-b4d5-cf0f19b32835",
        //     "storename": "incredible connection"
        //   }, {
        //     'uid': "0qe3qYTGm4gXJLLcsWquuMRcrYk2",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2F0qe3qYTGm4gXJLLcsWquuMRcrYk2%2Flogo%2Fwimpylogo.jpg?alt=media&token=4300475b-929f-4306-9f96-010cd69863b2",
        //     "storename": "wimpy"
        //   }, {
        //     'uid': "2R8ZEUeOpuPBwe8kgM4wTtdnYCh2",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2F2R8ZEUeOpuPBwe8kgM4wTtdnYCh2%2Flogo%2FEmpire%20Gaming.PNG?alt=media&token=e0179a2b-5bac-4fe5-8f18-56376243b936",
        //     "storename": "empire gaming"
        //   }, {
        //     'uid': "5Z7zLaf4AwhOnQNZ1epGLpv0x5w2",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2F5Z7zLaf4AwhOnQNZ1epGLpv0x5w2%2Flogo%2Fboardmans.jpg?alt=media&token=c6b0c339-469d-4b2b-9b9e-253a68db8cdf",
        //     "storename": "boardmans"
        //   }, {
        //     'uid': "90MjxfKtWvXbXo1pDfT54tVrjWQ2",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2F90MjxfKtWvXbXo1pDfT54tVrjWQ2%2Flogo%2FIrene%20Westerdale%20Fitness.PNG?alt=media&token=25f0afd0-309b-40fc-888a-68dd8a7ec722",
        //     "storename": "irene westerdale fitness"
        //   }, {
        //     'uid': "H99APNZHwWcPELPg4fndv0Ki9o32",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2FH99APNZHwWcPELPg4fndv0Ki9o32%2Flogo%2Ftotalsportslogo.jpg?alt=media&token=6dc48e4d-2e90-4432-b0ae-00a57978b54d",
        //     "storename": "totalsports"
        //   }, {
        //     'uid': "KVkW29u09kWRFBCYq3G7Pnq8RmS2",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2FKVkW29u09kWRFBCYq3G7Pnq8RmS2%2Flogo%2FPecanwood%20Estate%20Golf%20Club.PNG?alt=media&token=21225e49-1ae3-4513-8208-4e30d8bb05e7",
        //     "storename": "pecanwood golf club"
        //   }, {
        //     'uid': "KkLBTpYERjQiY7OAMMYrjiSgzGF2",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2FKkLBTpYERjQiY7OAMMYrjiSgzGF2%2Flogo%2Fcaltonhair.jpg?alt=media&token=a7f3732f-e41e-4235-b148-0d690134fe34",
        //     "storename": "carlton hair"
        //   }, {
        //     'uid': "KyBdhN7bo9RGT9xWvUp5XDDafVs1",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2FKyBdhN7bo9RGT9xWvUp5XDDafVs1%2Flogo%2FKFC.PNG?alt=media&token=b6901b64-8daa-41ae-88c4-cb5c6df6b8a9",
        //     "storename": "kfc"
        //   }, {
        //     'uid': "UHIyl3e5NgVWiVcPv8XFgVdDpTy2",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2FUHIyl3e5NgVWiVcPv8XFgVdDpTy2%2Flogo%2Fsteerslogo.jpg?alt=media&token=3e7ac2b6-12c8-4fef-bc38-eab8d8f745d4",
        //     "storename": "steers"
        //   }, {
        //     'uid': "Vq1dyBYSnUSEU9iSBI5Fmhn4OI73",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2FVq1dyBYSnUSEU9iSBI5Fmhn4OI73%2Flogo%2FCradle%20health%20Spa.PNG?alt=media&token=3b9eb20f-b334-400c-8de7-2da59348628e",
        //     "storename": "cradle health spa"
        //   }, {
        //     'uid': "WVbUsCI80neZGQpLYhNKFxUFhWi2",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2FWVbUsCI80neZGQpLYhNKFxUFhWi2%2Flogo%2Fdeboniarslogo.jpg?alt=media&token=92ab600a-c2ef-4ac0-b9b3-54e3c0bc02c5",
        //     "storename": "debonairs"
        //   }, {
        //     'uid': "Y4BfEbTMgcPGhuRWeoPYJ9RkFGj2",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2FY4BfEbTMgcPGhuRWeoPYJ9RkFGj2%2Flogo%2FNailed%20It%20Boutqiue.PNG?alt=media&token=d1be9e55-4c2c-406d-a5fb-2e43ec85cf75",
        //     "storename": "nailed it boutique"
        //   },
        //   {
        //     'uid': "bK8GaxCorhdViaAgN8VQAtFWQGh2",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2FbK8GaxCorhdViaAgN8VQAtFWQGh2%2Flogo%2Ftoysrus.jpg?alt=media&token=9ab634c3-84e3-4fa4-980b-f3e39c1eca98",
        //     "storename": "toysrus"
        //   }, {
        //     'uid': "bqtdnugBaKTz5PT8JvFI5uTKvfg2",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2FbqtdnugBaKTz5PT8JvFI5uTKvfg2%2Flogo%2Fmrprice.jpg?alt=media&token=535f512f-93e0-46ef-b204-07f384ef8883",
        //     "storename": "mrpricehome"
        //   }, {
        //     'uid': "hChF8bapk9a8v0JVJJI6O9TjAe03",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2FhChF8bapk9a8v0JVJJI6O9TjAe03%2Flogo%2Fhouseandhome.jpg?alt=media&token=07ede96d-e19a-4b6d-b640-86f16d0aa202",
        //     "storename": "houseandhome"
        //   }, {
        //     'uid': "izsJ4X7vyrYlDJazua7v9Z9D6wD3",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2FizsJ4X7vyrYlDJazua7v9Z9D6wD3%2Flogo%2FShoprite-Bursaries.png?alt=media&token=d427e9b1-38b7-4201-9a71-03119399a31e",
        //     "storename": "shoprite"
        //   }, {
        //     'uid': "jmNOFtl1ApWij1MEVJag3USHK443",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2FjmNOFtl1ApWij1MEVJag3USHK443%2Flogo%2Frocologo.jpg?alt=media&token=ca1f45ad-8180-4251-8474-53955a62b4a9",
        //     "storename": "rocomamas"
        //   }, {
        //     'uid': "mC29HEbA8pRLa7vldoj2MofdexH2",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2FmC29HEbA8pRLa7vldoj2MofdexH2%2Flogo%2Fbabycity.jpg?alt=media&token=65332034-5822-4fe4-b572-93bf39f2f2a8",
        //     "storename": "babycity"
        //   }, {
        //     'uid': "pC7a6A2ROLMHDOev7QrAHiSWikz2",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2FpC7a6A2ROLMHDOev7QrAHiSWikz2%2Flogo%2Fsportsscene.jpg?alt=media&token=e028c745-8e84-4b68-b3f4-b5cf3e495100",
        //     "storename": "sportscene"
        //   }, {
        //     'uid': "vsS2D7dU5aPRqjtwdv6W65mn5E92",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2FvsS2D7dU5aPRqjtwdv6W65mn5E92%2Flogo%2FEmpire%20Toyland.PNG?alt=media&token=853e9383-2c3a-4973-8c77-a277b64a1ebc",
        //     "storename": "empire toyland"
        //   }, {
        //     'uid': "wJL9lHLKqwPhG8lAcC9e18rLZKz2",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2FwJL9lHLKqwPhG8lAcC9e18rLZKz2%2Flogo%2Ftashaslogo.jpg?alt=media&token=5001dc17-295a-4d1e-a699-69dab131a341",
        //     "storename": "tashas"
        //   }, {
        //     'uid': "xOuJ1h4c1SOH4sVBXWm8gRO8uty1",
        //     "logoUrl": "https://firebasestorage.googleapis.com/v0/b/voucher24-6e09b.appspot.com/o/userProfile%2FxOuJ1h4c1SOH4sVBXWm8gRO8uty1%2Flogo%2Ffishawayslogo.jpg?alt=media&token=cf101b5e-c0ad-4236-b10d-f51975484b8e",
        //     "storename": "fishaways"
        //   }
        // ];

        // console.log(obj);
        // for (let i = 0; i < obj.length; i++) {
        //   // tslint:disable-next-line:max-line-length
        //   console.log(obj[i]);
        //   this.vs.insertMainstores(obj[i]);
        // }
    }
    invokeVoucher() {
        // const obj = [
        //   {
        //     "date": "Fri Apr 19 2019 7:56:25",
        //     "fee": 10.5,
        //     "ref": "37358999656",
        //     "store": "woolworths",
        //     "transactionAmmount": 315.5,
        //     "transactionType": "Payfast"
        //   }
        // ];

        // firebase.database().ref('userMessages')
        //   .child('xgyhrC686mhJMUf0RZt7LDhp6FG3')
        //   .child('messages')
        //   .push({
        //     fdghfd: 'dfgv'
        //   });

        // console.log(obj);
        // for (let i = 0; i < obj.length; i++) {
        //   // tslint:disable-next-line:max-line-length
        //   console.log(obj[i]);
        //   this.vs.insertSuppVoucher(obj[i]);
        // }
    }

    ngOnInit() {
    }

}
