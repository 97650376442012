import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFirestore } from 'angularfire2/firestore';
import { Client } from '../../models/clients';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as firebase from 'firebase';
import { OperationsService } from '../shared/operations.service';
import { VouchersService } from '../services/vouchers.service';
import * as _ from 'lodash';
import { first, take } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-redeem',
  templateUrl: './redeem.component.html',
  styleUrls: ['./redeem.component.css']
})
export class RedeemComponent implements OnInit {
  public isCollapsed = true;
  public isGarageCollapsed = true;
  public isAddCollapsed = true;
  public isVoucherCollapsed = true;
  public isRedeemed = true;
  closeResult: string;
  stores: any;
  uid: any;
  name: string;
  searchText: any;
  userDetails: any;
  TypeOfUser: any;
  category: any;
  message: any;
  hideloading: boolean;
  voucherCode: any;
  vouchersToRedeem: any;
  voucherObj: any;
  hidetable: any;
  amount: any;
  vouchercode: any;
  userType: any;
  todaysDate: any;
  fullvoucher: string;

  constructor(private router: Router, private route: ActivatedRoute, private afAuth: AngularFireAuth, private afd: AngularFireDatabase
    , private modalService: NgbModal, private operationsService: OperationsService, private vs: VouchersService) {
    // console.log(moment(new Date()).format('YYYY-MM-DD'));
    this.todaysDate = moment(new Date()).format('YYYY-MM-DD');
    console.log(this.todaysDate);
      this.closeResult = '';
      this.name = '';
    this.hidetable = false;
    if (!this.voucherObj) {
      this.hidetable = false;
    }
    this.hideloading = true;
    $(document).ready(function () {

      // $('#sidebar').mCustomScrollbar({
      //   theme: 'minimal'
      // });

      $('#sidebarCollapse').on('click', function () {
        // open or close navbar
        $('#sidebar').toggleClass('active');
        // close dropdowns
        $('.collapse.in').toggleClass('in');
        // and also adjust aria-expanded attributes we use for the open/closed arrows
        // in our CSS
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
      });

    });

    // const obj = [
    //     {
    //     'uid': "e32oCfBGq6UdsJvfPyxDyGyG3Sq2",
    //     "address": "1234 slovo",
    //     "catType": "BEAUTY",
    //     "category": "BEAUTY",
    //     "cellno": "0123456789",
    //     "commission": "1",
    //     "discount": "1",
    //     "email": "Sikamarazor@outlook.com",
    //     "featured": true,
    //     "fname": "Lefa",
    //     "isBlocked": true,
    //     "isClient": "true",
    //     "isMain": true,
    //     "logoUrl": "https://fireba",
    //     "mainFranchise": "lefa's haircut",
    //     "mainFranchiseUrl": "https://fireba",
    //     "promotionalText": "No promotional text",
    //     "restrictedPassword": "123456",
    //     "serviceCharge": "1",
    //     "sname": "Nyathi",
    //     "specialText": " store.",
    //     "storecellno": "0123456789",
    //     "storecode": "32431",
    //     "storename": "lefa's haircut",
    //     "tempPassword": "modified",
    //     "userType": "Franchise"
    //   }
    // ];
    // console.log(obj);

    // const obj = [
    //   {
    //     "campaignNo": "Campaign 004",
    //     "commission": "1",
    //     "companyName": "lefa's haircut",
    //     "date": "July 11th 2019, 11:56:47",
    //     "discount": "1",
    //     "serviceCharge": "1",
    //     "specialText": " store.",
    //     "storeAllVouchers": [{
    //       "amount": "50",
    //       "cardNumber": "9200496000037463",
    //       "isBought": false,
    //       "isRedeemed": false
    //     }, {
    //       "amount": "100",
    //       "cardNumber": "9201013000045776",
    //       "isBought": false,
    //       "isRedeemed": false
    //     }, {
    //       "amount": "200",
    //       "cardNumber": "9202019200024467",
    //       "isBought": false,
    //       "isRedeemed": false
    //     }, {
    //       "amount": "50",
    //       "cardNumber": "9200491600037464",
    //       "isBought": false,
    //       "isRedeemed": false
    //     }, {
    //       "amount": "100",
    //       "cardNumber": "9201015200045777",
    //       "isBought": false,
    //       "isRedeemed": false
    //     }, {
    //       "amount": "200",
    //       "cardNumber": "9202019600024468",
    //       "isBought": false,
    //       "isRedeemed": false
    //     }],
    //     "voucherAmt": ["50", "100", "200"]
    //   }
    // ];
    // console.log(obj);
    // for (let i = 0; i < obj.length; i++) {
    //   // tslint:disable-next-line:max-line-length
    //   console.log(obj[i]);
    //   this.vs.insertSupplier();
    // }

    this.vs.getSuppliersVouchers().subscribe(data => {
      console.log(data);
    });

    this.route.queryParams.subscribe(params => {
      this.uid = params['uid'];
    });
  }
  gotoLockuser() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/lockeduser'], navigationExtras);
  }
  redeemVoucher(content: any) {
    console.log(moment(new Date()).format('YYYY/MM/DD'));
    if (!this.voucherCode) {
      this.message = 'Please enter a voucher code to redeem';
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    } else {
      this.operationsService.checkOneVoucherRedeem(this.voucherCode).pipe(take(1)).toPromise().then(data4 => {
        console.log(data4);
        if (data4.length === 0) {
            this.message = 'Voucher not found or bought';

            this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        } else {
          console.log(this.vouchersToRedeem);
          this.voucherObj = _.filter(this.vouchersToRedeem, (x) => {
            console.log(x);
            return x.cardNumber === this.voucherCode;
          });
          console.log(this.voucherObj[0].expiryDate);
          console.log(new Date(this.todaysDate));

          if (!this.voucherObj[0].expiryDate || this.voucherObj[0].expiryDate === 'N/A') {
            if (this.voucherObj.length === 0) {
              this.hidetable = false;
              this.message = 'Voucher not found';

              this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
              }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
              });
            } else if (this.voucherObj) {

              this.vs.getRedeemedVouchers(this.voucherObj[0].cardNumber, this.uid).pipe(take(1)).toPromise().then(data => {
                console.log(data);
                if (data.length === 0) {
                  const date = new Date();
                  console.log(moment(date).format('YYYY/MM/DD'));
                  const fullname = this.userDetails.fname + ' ' + this.userDetails.sname;
                  console.log(fullname);
                  // tslint:disable-next-line:max-line-length
                  this.vs.setRedeemed(this.voucherObj[0].cardNumber, this.voucherObj[0].amount, date, this.uid, this.userDetails.email, fullname).then(data1 => {
                    console.log(data1);
                    this.message = 'Successfully redeemed';

                    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                      this.closeResult = `Closed with: ${result}`;
                    }, (reason) => {
                      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                    });
                    this.hidetable = true;
                    this.amount = this.voucherObj[0].amount;
                    console.log(this.amount);
                    this.voucherCode = this.voucherObj[0].cardNumber;

                    this.fullvoucher = this.voucherCode;
                    console.log(this.fullvoucher.length);
                    const getIndex = this.fullvoucher.length - 5;
                    console.log(getIndex);
                    const newStr = this.fullvoucher.substr(0, getIndex);

                    // this.fullvoucher.replace()
                    console.log(newStr);

                    const hashedString = this.fullvoucher.replace(newStr, '**********');
                    console.log(hashedString);

                    const emailBody = `{"personalizations": [{"to": [{"email": "` + this.userDetails.email.toLowerCase().trim() + `"}]}],"from":
    { "email": "Admin@voucher24.co.za"  },
    "subject": "Voucher24 supplier transaction", "content":
     [{ "type":  "text/html", "value": "<div><p>Dear ` + this.userDetails.fname + ` ` + this.userDetails.sname + ` </p> </br> <p> Voucher has been succesfully redeemed</p> <p> Voucher code : ` + this.voucherCode + ` </p> <p> Amount : ` + this.amount + ` </p> <p> Date : ` + moment(date).format('YYYY/MM/DD, h:mm:ss a') + ` </p> </br> <p>Your Voucher24 team</p></br> <p>Website: https://www.voucher24.co.za</p> <img class='max-width' border='0' style='display:block;color:#000000;text-decoration:none;font-family:Helvetica, arial, sans-serif;font-size:16px;max-width:50% !important;width:50%;height:auto !important;' src='https://marketing-image-production.s3.amazonaws.com/uploads/acdcbead2e22931a554192093e3dcefc0a5289173988f81eab6e635f1748c3e9940e4a745b7f56de687b3356f58c2f7da1e65de44e792838fc1f7f22035a5846.png' alt='' width='300'></div>" }]}`;
                    console.log(emailBody);
                    this.operationsService.getKey().subscribe(data2 => {
                      console.log(data2);
                      this.operationsService.sendAttachment(emailBody, data2.key).subscribe(data3 => {
                        console.log(data3);
                      });
                    });
                  });
                } else {
                  this.message = 'Voucher already redeemed';

                  this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                  }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                  });
                }
              });
            }
          } else {
            const d1 = new Date(this.voucherObj[0].expiryDate);
            const d2 = new Date(this.todaysDate);

            console.log(d1.getTime() >= d2.getTime());

            if (d1.getTime() < d2.getTime()) {
              this.hidetable = false;
              this.message = 'Voucher expired';

              this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
              }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
              });
            } else {
              if (this.voucherObj.length === 0) {
                this.hidetable = false;
                this.message = 'Voucher not found';

                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                  this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
              } else if (this.voucherObj) {

                this.vs.getRedeemedVouchers(this.voucherObj[0].cardNumber, this.uid).pipe(take(1)).toPromise().then(data => {
                  console.log(data);
                  if (data.length === 0) {
                    const date = new Date();
                    console.log(moment(date).format('YYYY/MM/DD'));
                    const fullname = this.userDetails.fname + ' ' + this.userDetails.sname;
                    console.log(fullname);
                    // tslint:disable-next-line:max-line-length
                    this.vs.setRedeemed(this.voucherObj[0].cardNumber, this.voucherObj[0].amount, date, this.uid, this.userDetails.email, fullname).then(data1 => {
                      console.log(data1);
                      this.message = 'Successfully redeemed';

                      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                        this.closeResult = `Closed with: ${result}`;
                      }, (reason) => {
                        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                      });
                      this.hidetable = true;
                      this.amount = this.voucherObj[0].amount;
                      console.log(this.amount);
                      this.voucherCode = this.voucherObj[0].cardNumber;

                      this.fullvoucher = this.voucherCode;
                      console.log(this.fullvoucher.length);
                      const getIndex = this.fullvoucher.length - 5;
                      console.log(getIndex);
                      const newStr = this.fullvoucher.substr(0, getIndex);

                      // this.fullvoucher.replace()
                      console.log(newStr);

                      const hashedString = this.fullvoucher.replace(newStr, '**********');
                      console.log(hashedString);

                      const emailBody = `{"personalizations": [{"to": [{"email": "` + this.userDetails.email.toLowerCase().trim() + `"}]}],"from":
    { "email": "Admin@voucher24.co.za"  },
    "subject": "Voucher24 supplier transaction", "content":
     [{ "type":  "text/html", "value": "<div><p>Dear ` + this.userDetails.fname + ` ` + this.userDetails.sname + ` </p> </br> <p> Voucher has been succesfully redeemed</p> <p> Voucher code : ` + this.voucherCode + ` </p> <p> Amount : ` + this.amount + ` </p> <p> Date : ` + moment(date).format('YYYY/MM/DD, h:mm:ss a') + ` </p> </br> <p>Your Voucher24 team</p></br> <p>Website: https://www.voucher24.co.za</p> <img class='max-width' border='0' style='display:block;color:#000000;text-decoration:none;font-family:Helvetica, arial, sans-serif;font-size:16px;max-width:50% !important;width:50%;height:auto !important;' src='https://marketing-image-production.s3.amazonaws.com/uploads/acdcbead2e22931a554192093e3dcefc0a5289173988f81eab6e635f1748c3e9940e4a745b7f56de687b3356f58c2f7da1e65de44e792838fc1f7f22035a5846.png' alt='' width='300'></div>" }]}`;
                      console.log(emailBody);
                      this.operationsService.getKey().subscribe(data2 => {
                        console.log(data2);
                        this.operationsService.sendAttachment(emailBody, data2.key).subscribe(data3 => {
                          console.log(data3);
                        });
                      });
                    });
                  } else {
                    this.message = 'Voucher already redeemed';

                    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                      this.closeResult = `Closed with: ${result}`;
                    }, (reason) => {
                      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                    });
                  }
                });
              }
            }
          }
        }
      });
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  gotoPromotional() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/specials'], navigationExtras);
  }
  gotoCharge() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/bluelabel'], navigationExtras);
  }

  gotoEdit(x: any) {
    console.log(x);
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid,
        storeobj: JSON.stringify(x)
      }, skipLocationChange: true
    };
    this.router.navigate(['/editsupplier'], navigationExtras);
  }

  delete(x: any) {
    console.log(x);
    this.operationsService.getOneClient(x.logoUrl);
  }
  checkSupplier(data) {
    this.hideloading = false;
    this.userDetails = data;
    this.name = this.userDetails.name;
    this.TypeOfUser = this.userDetails.userType;
    if (!this.name) {
      this.name = this.userDetails.fname + ' ' + this.userDetails.sname;
    }
  }

  ngOnInit() {
    const storeAllvouchers = [];
    // alert('Coming soon');
    this.operationsService.getDetails(this.uid).subscribe(data => {
      console.log(data);
      if (!data) {
        this.operationsService.getClientsDetails(this.uid).subscribe(data2 => {
          this.checkSupplier(data2.payload.data());
        });
      } else {
        this.checkSupplier(data);
      }
    });

    this.operationsService.getVouchersToredeem(this.uid).subscribe((data: any) => {
      this.hideloading = false;
      console.log(data);
      this.vouchersToRedeem = data;
      // for (let i = 0; i < data.length; i++) {
      //   for (let z = 0; z < data[i].storeAllVouchers.length; z++) {
      //     storeAllvouchers.push(data[i].storeAllVouchers[z]);
      //     console.log(storeAllvouchers);
      //   }
      //   if (data.length - 1 === i) {
      //     console.log('loop ends');
      //     this.vouchersToRedeem = storeAllvouchers;
      //     console.log(this.vouchersToRedeem);
      //   }
      // }
    });
  }
  gotoLogin() {
    this.operationsService.updateSignInStatus(this.uid, false);
    this.afAuth.auth.signOut();
    this.router.navigateByUrl('/login');
  }

  gotohome() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/home'], navigationExtras);
  }
  gotoVouchersBought() {
    if (this.userDetails.userType === 'superAdmin') {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['selectbought'], navigationExtras);
    } else {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['/vouches'], navigationExtras);
    }
  }
  gotoAddClients() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-client'], navigationExtras);
  }
  gotoClients() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/clients'], navigationExtras);
  }
  gotoAddNewVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-new-vouchers'], navigationExtras);
  }
  gotoCardTemplates() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/card-templates'], navigationExtras);
  }
  gotoAddCardTemplate() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-card-template'], navigationExtras);
  }
  gotoAvailableVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/availvouchers'], navigationExtras);
  }
  gotoAddCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addsuppcat'], navigationExtras);
  }
  gotoAddtemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addtempcat'], navigationExtras);
  }
  gotoDeltemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/deltempcat'], navigationExtras);
  }
  gotoDelsupplierCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/delsuppcat'], navigationExtras);
  }
  gotoReemvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeem'], navigationExtras);
  }
  gotoRedeemedvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeemedvouchers'], navigationExtras);
  }

}

