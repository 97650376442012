import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFirestore } from 'angularfire2/firestore';
import { Client } from '../../models/clients';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as firebase from 'firebase';
import { OperationsService } from '../shared/operations.service';
import { first, take } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
    selector: 'app-add-client',
    templateUrl: './add-client.component.html',
    styleUrls: ['./add-client.component.css']
})

export class AddClientComponent implements OnInit {
    public isCollapsed = true;
    public isGarageCollapsed = true;
    public isAddCollapsed = true;
    public isVoucherCollapsed = true;
    public isRedeemed = true;
    closeResult: string;
    public client = {} as Client;
    file: any;
    message: string;
    categories: any;
    selectedCat: any;
    uid: any;
    name: string;
    searchText: any;
    userDetails: any;
    userType: any;
    selectedUserType: any;
    TypeOfUser: any;
    isFeatured: any;
    isComing: any;
    storecat: any;
    isMain: any;
    selectedShop: any;
    mainStores: any;
    hideloading: boolean;
    selectedShopObj: any;
    mainFranchiseUrl: any;
    customerNo: any;
    // province: string;

    constructor(private router: Router, private route: ActivatedRoute, private afAuth: AngularFireAuth, private afd: AngularFireDatabase
        , private modalService: NgbModal, private operationsService: OperationsService, private afs: AngularFirestore) {
        this.isFeatured = false;
        this.isMain = false;
        this.isComing = undefined;
        this.hideloading = true;
        $(document).ready(function () {

            // $('#sidebar').mCustomScrollbar({
            //   theme: 'minimal'
            // });

            $('#sidebarCollapse').on('click', function () {
                // open or close navbar
                $('#sidebar').toggleClass('active');
                // close dropdowns
                $('.collapse.in').toggleClass('in');
                // and also adjust aria-expanded attributes we use for the open/closed arrows
                // in our CSS
                $('a[aria-expanded=true]').attr('aria-expanded', 'false');
            });

        });
        // this.categories = [{ categoryName: 'TOP 20 Featured' }
        //   , { categoryName: 'House & Home' }
        //   , { categoryName: 'Prepaid services' }
        //   , { categoryName: 'Kids Toys' }
        //   , { categoryName: 'Restaurants' }
        //   , { categoryName: 'Beauty & Care' }
        //   , { categoryName: 'Baby' }
        //   , { categoryName: 'Sports' }
        //   , { categoryName: 'Computer HW' }];

        this.userType = [{ type: 'Franchise' }
            , { type: 'Shop' }];

        this.route.queryParams.subscribe(params => {
            this.uid = params['uid'];
        });

    }
    gotoPromotional() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/specials'], navigationExtras);
    }
    gotoCharge() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/bluelabel'], navigationExtras);
    }
    gotoLockuser() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/lockeduser'], navigationExtras);
    }
    checkFeatured(e) {
        console.log(e);
        if (e.checked === true) {
            this.isFeatured = true;
            console.log(this.isFeatured);
        } else if (e.checked === false) {
            this.isFeatured = false;
        }
    }

    checkMain(e) {
        console.log(e);
        if (e.checked === true) {
            this.isMain = true;
        } else if (e.checked === false) {
            this.isMain = false;
        }
    }

    checkComingSoon(e) {
        console.log(e);
        if (e.checked === true) {
            this.isComing = undefined;
            console.log(this.isComing);
        } else if (e.checked === false) {
            this.isComing = true;
        }
    }

    addClient(client: Client, content) {
        console.log(client);

        //This looks pointless, WHY IS THIS HERE?
        if (this.selectedCat === 'TOP 20 Featured') {
            this.storecat = 'topfeatured';
        } else if (this.selectedCat === 'House & Home') {
            this.storecat = 'houseandhome';
        } else if (this.selectedCat === 'Prepaid services') {
            this.storecat = 'prepaid';
        } else if (this.selectedCat === 'Kids Toys') {
            this.storecat = 'kids';
        } else if (this.selectedCat === 'Restaurants') {
            this.storecat = 'restaurants';
        } else if (this.selectedCat === 'Beauty & Care') {
            this.storecat = 'beauty';
        } else if (this.selectedCat === 'Baby') {
            this.storecat = 'baby';
        } else if (this.selectedCat === 'Sports') {
            this.storecat = 'sports';
        } else if (this.selectedCat === 'Computer HW') {
            this.storecat = 'computer';
        }

        //isMain is set when 
        if (this.isMain === true) {
            if (!client.storename) {
                //Does check but actions or changes nothing here....
            } else {
                this.selectedShop = client.storename.toLowerCase();
            }
        } else {
            this.selectedShop = this.selectedShop;
        }

        if (!this.selectedShop && this.isMain === false) {
            this.message = 'Please check main shop or select select a shop';

            this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        } else {
            if (!client.fname) {
                this.message = 'Please enter first name';

                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else if (!client.sname) {
                this.message = 'Please enter last name';

                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else if (!client.email) {
                this.message = 'Please enter email';

                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else if (!client.cellno) {
                this.message = 'Please enter cell number';

                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else if (!client.address) {
                this.message = 'Please enter physical address';

                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else if (!this.selectedUserType) {
                this.message = 'Please select user type';

                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else if (!client.storename) {
                this.message = 'Please type store name';

                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else if (!client.storecode) {
                this.message = 'Please enter store code';

                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else if (!client.phoneno) {
                this.message = 'Please enter phone store contact number';

                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else if (!this.selectedCat) {
                this.message = 'Please select category';

                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else if (!this.file) {
                this.message = 'Please upload a logo';

                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else {
                const tempPassword = Math.floor((Math.random() * 1005000) * 10);
                const emailBody = `{"personalizations": [{"to": [{"email": "` + client.email.toLowerCase().trim() + `"}]}],"from":
    { "email": "Admin@voucher24.co.za"  },
    "subject": "Voucher24 temporary password", "content":
    [{ "type":  "text/html", "value": "<p>Hi ` + client.fname + ` </p> </br> <p> Here is your temporary password ` + tempPassword + `</p> </br> <p>Your Voucher24 team</p></br> <p>Website: https://www.voucher24.co.za</p><img class='max-width' border='0' style='display:block;color:#000000;text-decoration:none;font-family:Helvetica, arial, sans-serif;font-size:16px;max-width:50% !important;width:50%;height:auto !important;' src='https://marketing-image-production.s3.amazonaws.com/uploads/acdcbead2e22931a554192093e3dcefc0a5289173988f81eab6e635f1748c3e9940e4a745b7f56de687b3356f58c2f7da1e65de44e792838fc1f7f22035a5846.png' alt='' width='300'></div>" }]}`;
                console.log(emailBody);
                this.operationsService.getKey().subscribe(data2 => {
                    this.operationsService.sendTemppassword(emailBody, data2.key).subscribe(data => {
                        console.log(data);
                    });
                });

                firebase
                    .auth()
                    .createUserWithEmailAndPassword(client.email.toLowerCase().trim(), tempPassword.toString()).then(newUser => {
                        console.log(newUser);
                        const ref1 = Math.floor((Math.random() * 1000000) + 10000);
                        const ref2 = Math.floor((Math.random() * 100000) + 10000);
                        // console.log((ref2);
                        this.customerNo = "219" + ref1.toString().substring(3, 2) + client.cellno.substring(5, 3) + ref2.toString().substring(1, 3);
                        // const storageRef: firebase.storage.Reference = firebase.storage().ref();
                        // console.log(storageRef);
                        console.log(newUser.user.uid);
                        this.operationsService.getStoreUid(newUser.user.uid, this.file, this.selectedCat.toUpperCase()
                            , this.isFeatured, this.isComing, client.storename.toLowerCase(), this.isMain, this.mainFranchiseUrl, client.address);

                        this.afs
                            .collection('/supplierProfile')
                            .doc(newUser.user.uid)
                            .set({
                                fname: client.fname,
                                sname: client.sname,
                                email: client.email,
                                cellno: client.cellno,
                                address: client.address,
                                storename: client.storename.toLowerCase(), // shoprite
                                storecode: client.storecode,
                                storecellno: client.phoneno,
                                catType: this.selectedCat.toUpperCase(),
                                tempPassword: tempPassword.toString(),
                                userType: this.selectedUserType,
                                isClient: 'true',
                                isMain: this.isMain,
                                mainFranchise: this.selectedShop,
                                category: this.selectedCat.toUpperCase(),
                                featured: this.isFeatured
                            });

                        // this.afs.collection('userProfile')
                        //   .doc(newUser.user.uid)
                        //   .set({
                        //     name: client.fname + ' ' + client.sname,
                        //     cellno: client.cellno,
                        //     email: client.email,
                        //     type: 'customer',
                        //     pin: "00000",
                        //     walletBalance: 0,
                        //     customerNo: this.customerNo,
                        //     option: true,
                        //     v24user: true
                        //   });

                        this.message = 'Successfully added, email with temporary password successfully sent';

                        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                            this.closeResult = `Closed with: ${result}`;
                        }, (reason) => {
                            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                        });
                        client.fname = '';
                        client.sname = '';
                        client.email = '';
                        client.cellno = '';
                        client.address = '';
                        client.storename = '';
                        client.storecode = '';
                        client.phoneno = '';

                    }, (error) => {
                        console.log(error);

                        this.operationsService.getUserbyemail(client.email).subscribe((data: any) => {
                            console.log(data);
                            const ref1 = Math.floor((Math.random() * 1000000) + 10000);
                            const ref2 = Math.floor((Math.random() * 100000) + 10000);
                            // console.log((ref2);
                            this.customerNo = "219" + ref1.toString().substring(3, 2) + client.cellno.substring(5, 3) + ref2.toString().substring(1, 3);
                            if (!data.error) {
                                const unsubCheckRegistered = this.operationsService.checkIfSupplierRegistered(data.data.uid).subscribe(dd1 => {
                                    unsubCheckRegistered.unsubscribe();
                                    if (!dd1) {

                                        // const storageRef: firebase.storage.Reference = firebase.storage().ref();
                                        // console.log(storageRef);
                                        console.log(data.data.uid);
                                        this.operationsService.getStoreUid(data.data.uid, this.file, this.selectedCat.toUpperCase()
                                            , this.isFeatured, this.isComing, client.storename.toLowerCase(), this.isMain, this.mainFranchiseUrl, client.address);

                                        this.afs
                                            .collection('/supplierProfile')
                                            .doc(data.data.uid)
                                            .set({
                                                fname: client.fname,
                                                sname: client.sname,
                                                email: client.email,
                                                cellno: client.cellno,
                                                address: client.address,
                                                storename: client.storename.toLowerCase(), // shoprite
                                                storecode: client.storecode,
                                                storecellno: client.phoneno,
                                                catType: this.selectedCat.toUpperCase(),
                                                tempPassword: tempPassword.toString(),
                                                userType: this.selectedUserType,
                                                isClient: 'true',
                                                isMain: this.isMain,
                                                mainFranchise: this.selectedShop,
                                                category: this.selectedCat.toUpperCase(),
                                                featured: this.isFeatured
                                            });

                                        this.message = 'Successfully added, email with temporary password successfully sent';

                                        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                                            this.closeResult = `Closed with: ${result}`;
                                        }, (reason) => {
                                            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                                        });
                                        client.fname = '';
                                        client.sname = '';
                                        client.email = '';
                                        client.cellno = '';
                                        client.address = '';
                                        client.storename = '';
                                        client.storecode = '';
                                        client.phoneno = '';
                                    } else {
                                        const unsubCheck = this.operationsService.checkIfClientRegistered(data.data.uid).subscribe(dd => {
                                            console.log(dd);
                                            unsubCheck.unsubscribe();
                                            if (!dd) {
                                                this.afs.collection('userProfile')
                                                    .doc(data.data.uid)
                                                    .set({
                                                        name: client.fname + ' ' + client.sname,
                                                        cellno: client.cellno,
                                                        email: client.email,
                                                        type: 'customer',
                                                        pin: "00000",
                                                        walletBalance: 0,
                                                        customerNo: this.customerNo,
                                                        option: true,
                                                        v24user: true
                                                    });

                                                this.message = 'Successfully added, email with temporary password successfully sent';

                                                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                                                    this.closeResult = `Closed with: ${result}`;
                                                }, (reason) => {
                                                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                                                });
                                                client.fname = '';
                                                client.sname = '';
                                                client.email = '';
                                                client.cellno = '';
                                                client.address = '';
                                                client.storename = '';
                                                client.storecode = '';
                                                client.phoneno = '';
                                            } else {
                                                this.message = error.message;

                                                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                                                    this.closeResult = `Closed with: ${result}`;
                                                }, (reason) => {
                                                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                                                });

                                            }
                                        });
                                    }

                                });
                            } else if (data.error.code === 'auth/user-not-found') {
                                this.message = error.message;

                                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                                    this.closeResult = `Closed with: ${result}`;
                                }, (reason) => {
                                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                                });
                            }
                        });

                    });

            }
        }
    }

    logoUpload(event: any) {
        this.file = event.target.files[0];
    }

    ngOnInit() {
        this.operationsService.getDetails(this.uid).pipe(first()).toPromise().then(data => {
            console.log(data);
            this.hideloading = false;
            this.userDetails = data;
            this.name = this.userDetails.name;
            this.TypeOfUser = this.userDetails.userType;
            if (!this.name) {
                this.name = this.userDetails.fname + ' ' + this.userDetails.sname;
            }
        });
        this.operationsService.getSupplierCat().subscribe(data => {
            console.log(data);
            this.categories = data;
            console.log(this.categories);
        });

        this.operationsService.getMainshops().subscribe(data => {
            console.log(data);
            this.mainStores = data;
            console.log(this.mainStores);
        });
    }
    getData(x) {
        this.selectedUserType = x.srcElement.value;
        console.log(this.selectedUserType);
    }
    dataChanged(x) {
        this.selectedCat = x.srcElement.value;
    }
    selectShop(x) {
        console.log(x);
        this.selectedShop = x.srcElement.value;

        this.selectedShopObj = _.filter(this.mainStores, (y) => {
            return y.storename === this.selectedShop;
        });
        console.log(this.selectedShopObj);
        this.mainFranchiseUrl = this.selectedShopObj[0].logoUrl;
        console.log(this.mainFranchiseUrl);
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    gotoLogin() {
        this.operationsService.updateSignInStatus(this.uid, false);
        this.afAuth.auth.signOut();
        this.router.navigateByUrl('/login');
    }

    gotohome() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/home'], navigationExtras);
    }
    gotoVouchersBought() {
        if (this.userDetails.userType === 'superAdmin') {
            const navigationExtras: NavigationExtras = {
                queryParams: {
                    uid: this.uid
                }, skipLocationChange: true
            };
            this.router.navigate(['selectbought'], navigationExtras);
        } else {
            const navigationExtras: NavigationExtras = {
                queryParams: {
                    uid: this.uid
                }, skipLocationChange: true
            };
            this.router.navigate(['/vouches'], navigationExtras);
        }
    }
    gotoAddClients() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/add-client'], navigationExtras);
    }
    gotoClients() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/clients'], navigationExtras);
    }
    gotoAddNewVouchers() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/add-new-vouchers'], navigationExtras);
    }
    gotoCardTemplates() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/card-templates'], navigationExtras);
    }
    gotoAddCardTemplate() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/add-card-template'], navigationExtras);
    }
    gotoAvailableVouchers() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/availvouchers'], navigationExtras);
    }
    gotoAddCategory() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/addsuppcat'], navigationExtras);
    }
    gotoAddtemplateCategory() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/addtempcat'], navigationExtras);
    }
    gotoDeltemplateCategory() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/deltempcat'], navigationExtras);
    }
    gotoDelsupplierCategory() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/delsuppcat'], navigationExtras);
    }
    gotoReemvouchers() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/redeem'], navigationExtras);
    }
    gotoRedeemedvouchers() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                uid: this.uid
            }, skipLocationChange: true
        };
        this.router.navigate(['/redeemedvouchers'], navigationExtras);
    }
}
