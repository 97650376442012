import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { TemplateServiceService } from '../template-service.service';
import * as _ from 'lodash';
import { OperationsService } from '../shared/operations.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { first, take } from 'rxjs/operators';
import { AngularFireAuth } from 'angularfire2/auth';
// import 'rxjs/add/operator/first';

@Component({
  selector: 'app-card-templates',
  templateUrl: './card-templates.component.html',
  styleUrls: ['./card-templates.component.css']
})
export class CardTemplatesComponent implements OnInit {
  public isCollapsed = true;
  public isGarageCollapsed = true;
  public isAddCollapsed = true;
  public isVoucherCollapsed = true;
  public isRedeemed = true;
  isRedeem: any;
  closeResult: string;
  templates: any;
  birthdayTemplates: any;
  christmasTemplates: any;
  AnnivesaryTemplates: any;
  CongratulationsTemplates: any;
  ThankyouTemplates: any;
  ValentinesTemplates: any;
  EasterTemplates: any;
  BoyTemplates: any;
  GirlTemplates: any;
  MothersTemplates: any;
  uid: any;
  name: string;
  searchText: any;
  userDetails: any;
  TypeOfUser: any;
  category: any;
  getResult: any;
  newCategory = [];
  storetemplates: any;
  pushtoobject = [];
  arr = [];
  message: any;
  hideloading: boolean;

  constructor(private router: Router, private route: ActivatedRoute, private ts: TemplateServiceService
    , private operationsService: OperationsService, private modalService: NgbModal, private afAuth: AngularFireAuth) {
    this.hideloading = true;
$(document).ready(function () {

      // $('#sidebar').mCustomScrollbar({
      //   theme: 'minimal'
      // });

      $('#sidebarCollapse').on('click', function () {
        // open or close navbar
        $('#sidebar').toggleClass('active');
        // close dropdowns
        $('.collapse.in').toggleClass('in');
        // and also adjust aria-expanded attributes we use for the open/closed arrows
        // in our CSS
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
      });

    });
    this.route.queryParams.subscribe(params => {
      this.uid = params['uid'];
    });

  }
  gotoLockuser() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/lockeduser'], navigationExtras);
  }
  loadTemplate() {
    this.ts.getTemplates().pipe(first()).toPromise().then(data => {
      this.templates = data;
      console.log(data);
      this.birthdayTemplates = _.filter(data, (x) => {
        console.log(x);
        return x.templateType === 'Birthday';
      });
      this.christmasTemplates = _.filter(data, (x) => {
        console.log(x);
        return x.templateType === 'Christmas';
      });
      this.AnnivesaryTemplates = _.filter(data, (x) => {
        console.log(x);
        return x.templateType === 'Anniversary';
      });
      this.CongratulationsTemplates = _.filter(data, (x) => {
        console.log(x);
        return x.templateType === 'Congratulations';
      });
      this.ThankyouTemplates = _.filter(data, (x) => {
        console.log(x);
        return x.templateType === 'Thank_you';
      });
      this.ValentinesTemplates = _.filter(data, (x) => {
        console.log(x);
        return x.templateType === 'Valentine';
      });
      this.BoyTemplates = _.filter(data, (x) => {
        console.log(x);
        return x.templateType === 'Boy';
      });
      this.GirlTemplates = _.filter(data, (x) => {
        console.log(x);
        return x.templateType === 'Girl';
      });
      this.EasterTemplates = _.filter(data, (x) => {
        // console.log((x);
        return x.templateType === 'Easter';
      });
      this.MothersTemplates = _.filter(data, (x) => {
        // console.log((x);
        return x.templateType === 'Mothers_day';
      });
    });
  }

  deleteCard(content, x) {
    this.arr = [];
    const finalArr = [];
    this.ts.getOneTemplate(x.logoUrl);
    this.message = 'Deleted';

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.operationsService.getTemplatesCat().subscribe(data => {
      console.log(data);
      this.category = data;
      this.ts.getTemplates().subscribe(data1 => {
        console.log(data1);
        this.arr = [];
        this.getResult = _.chain(data1)
          .groupBy('templateType')
          .toPairs()
          .map((currentItem) => {
            console.log(currentItem);
            this.arr.push(currentItem);
          })
          .value();
        console.log(this.arr);
        for (let z = 0; z < this.arr.length; z++) {
          console.log(this.arr[z][1]);
          const obje = {
            category: this.arr[z][0],
            template: this.arr[z][1]
          };
          finalArr.push(obje);
        }
        this.category = finalArr;
        console.log(this.category);
      });
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  checkSupplier(data) {
    this.hideloading = false;
    this.userDetails = data;
    this.name = this.userDetails.name;
    this.TypeOfUser = this.userDetails.userType;
    this.isRedeem = this.userDetails.isRedeem;
    if (!this.isRedeem) {
      this.isRedeem = false;
      console.log(this.isRedeem);
    } else {
      this.isRedeem = true;
      console.log(this.isRedeem);
    }
    if (!this.name) {
      this.name = this.userDetails.fname + ' ' + this.userDetails.sname;
    }
  }
  ngOnInit() {
    // categot = 1
    // categot = 1
    // categot = 2
    // categot = 4
    // this.loadTemplate();
    const arr = [];
    const finalArr = [];
    this.operationsService.getDetails(this.uid).subscribe(data => {
      console.log(data);
      if (!data) {
        this.operationsService.getClientsDetails(this.uid).subscribe(data2 => {
          this.checkSupplier(data2.payload.data());
        });
      } else {
        this.checkSupplier(data);
      }
    });
    this.operationsService.getTemplatesCat().subscribe(data => {
      console.log(data);
      this.ts.getTemplates().subscribe(data1 => {
        console.log(data1);
        this.getResult = _.chain(data1)
          .groupBy('templateType')
          .toPairs()
          .map((currentItem) => {
            console.log(currentItem);
            this.arr.push(currentItem);
          })
          .value();
        console.log(this.arr);
        for (let z = 0; z < this.arr.length; z++) {
          console.log(this.arr[z][1]);
          const obje = {
            category: this.arr[z][0],
            template: this.arr[z][1]
          };
          finalArr.push(obje);
        }
        this.category = finalArr;
        console.log(this.category);
      });
    });
  }
  gotoPromotional() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/specials'], navigationExtras);
  }
  gotoCharge() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/bluelabel'], navigationExtras);
  }
  gotoLogin() {
    this.operationsService.updateSignInStatus(this.uid, false);
    this.afAuth.auth.signOut();
    this.router.navigateByUrl('/login');
  }

  gotohome() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/home'], navigationExtras);
  }
  gotoVouchersBought() {
    if (this.userDetails.userType === 'superAdmin') {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['selectbought'], navigationExtras);
    } else {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['/vouches'], navigationExtras);
    }
  }
  gotoAddClients() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-client'], navigationExtras);
  }
  gotoClients() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/clients'], navigationExtras);
  }
  gotoAddNewVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-new-vouchers'], navigationExtras);
  }
  gotoCardTemplates() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/card-templates'], navigationExtras);
  }
  gotoAddCardTemplate() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-card-template'], navigationExtras);
  }
  gotoAvailableVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/availvouchers'], navigationExtras);
  }
  gotoAddCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addsuppcat'], navigationExtras);
  }
  gotoAddtemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addtempcat'], navigationExtras);
  }
  gotoDeltemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/deltempcat'], navigationExtras);
  }
  gotoDelsupplierCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/delsuppcat'], navigationExtras);
  }
  gotoReemvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeem'], navigationExtras);
  }
  gotoRedeemedvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeemedvouchers'], navigationExtras);
  }

}
