import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { OperationsService } from '../shared/operations.service';
import { VouchersService } from '../services/vouchers.service';
import * as XLSX from 'xlsx';
import { AngularFireDatabase } from 'angularfire2/database';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { AngularFireAuth } from 'angularfire2/auth';
import { first, take } from 'rxjs/operators';

@Component({
  selector: 'app-allvouchers',
  templateUrl: './allvouchers.component.html',
  styleUrls: ['./allvouchers.component.css']
})
export class AllvouchersComponent implements OnInit {

  public isCollapsed = true;
  public isGarageCollapsed = true;
  public isAddCollapsed = true;
  public isVoucherCollapsed = true;
  public isRedeemed = true;
  isRedeem: any;
  closeResult: string;
  uid: any;
  name: string;
  searchText: any;
  userDetails: any;
  file: File;
  arrayBuffer: any;
  comuid: any;
  message: any;
  TypeOfUser: any;
  logoUrl: any;
  currentVouchers: any;
  newerVouchers: any;
  loadedVouchers: any;
  loadedNewerVouchers: any;
  numVouchers: any;
  storename: any;

  countAmts = [];
  boughtAmts = [];
  countNewerAmts = [];
  countNewerByDate = [];
  checkVouchersLength: any;
  getResult: any;
  getResultAllAmt: any;
  getNewerResult: any;
  vcount: any;
  hideloading: boolean;
  originalLogoUrl: any;
  countAmt: any;
  boughtAmt: any;
  allBoughtAmt = [];
  vouchersSold: any;
  topDate: any;
  displayAllamt = [];
  displayAllShopsamt = [];
  totVouchers: any;
  totRemaining: any;
  totBought: any;
  totNewerBought: any;
  totAvailable: any;
  allStorenames: any;
  redeemedVouchers = [];
  totRedeemed: any;
  allRedeemedVouchers: any;

  constructor(private router: Router, private route: ActivatedRoute, private operationsService: OperationsService
    , private afd: AngularFireDatabase, private modalService: NgbModal, private afAuth: AngularFireAuth, private vs: VouchersService) {
    this.hideloading = true;
    this.totVouchers = 0;
    this.totRemaining = 0;
    this.totBought = 0;
    this.totRedeemed = 0;
    this.totNewerBought = 0;
    this.totAvailable = 0;

    console.log(this.displayAllamt);
    $(document).ready(function () {

      // $('#sidebar').mCustomScrollbar({
      //   theme: 'minimal'
      // });

      $('#sidebarCollapse').on('click', function () {
        // open or close navbar
        $('#sidebar').toggleClass('active');
        // close dropdowns
        $('.collapse.in').toggleClass('in');
        // and also adjust aria-expanded attributes we use for the open/closed arrows
        // in our CSS
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
      });

    });
    this.route.queryParams.subscribe(params => {
      this.uid = params['uid'];
      this.comuid = params['comuid'];
      this.logoUrl = params['logoUrl'];
      console.log(this.logoUrl);
      this.originalLogoUrl = params['originalLogoUrl'];
      this.storename = params['storename'];
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  public convertToTable(sheet: any) {
    const result = [];
    let row;
    let rowNum;
    let colNum;
    const range = XLSX.utils.decode_range(sheet['!ref']);
    for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
      row = [];
      for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
        const nextCell = sheet[
          XLSX.utils.encode_cell({ r: rowNum, c: colNum })
        ];
        if (typeof nextCell === 'undefined') {
          row.push(void 0);
        } else {
          row.push(nextCell.w);
        }
      }
      result.push(row);
    }
    return result;
  }
  gotoPromotional() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/specials'], navigationExtras);
  }
  gotoCharge() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/bluelabel'], navigationExtras);
  }
  goBack() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/availvouchers'], navigationExtras);
  }

  ngOnInit() {
    let counter = 0;
    let newerCounter = 0;
    let countNewerCounter = 0;
    let loadedVouchers = [];
    const allamts = [];
    const allboughtamts = [];
    const pushallamts = [];
    const totalLength = 0;
    this.vcount = 0;
    let sum = 0;
    let sumRedeem = 0;
    let sumAllShops = 0;
    this.numVouchers = 0;
    console.log(this.comuid);
    this.operationsService.getVoucher(this.comuid).pipe(take(1)).toPromise().then((data: any) => {
      console.log(data);
      this.hideloading = false;
      this.checkVouchersLength = data.length;
      console.log(this.checkVouchersLength);
      if (this.checkVouchersLength === 0) {

      } else {
        this.currentVouchers = _.first(data);
        // loadedVouchers.push(this.currentVouchers.storeAllVouchers);

        this.loadedVouchers = this.currentVouchers.storeAllVouchers;
        loadedVouchers = this.currentVouchers.storeAllVouchers;
        const a = loadedVouchers.slice(2, 10);
        console.log(a);
        console.log(this.loadedVouchers);
        this.getResult = _.chain(this.loadedVouchers)
          .groupBy('amount')
          .toPairs()
          .map((currentItem) => {
            console.log(currentItem);
            counter = counter + 1;
            const getItems = {
              i: counter,
              amount: currentItem[0],
              numberOfVouchers: currentItem[1].length
            };
            this.totRemaining = currentItem[1].length + this.totRemaining;
            this.totAvailable = currentItem[1].length + this.totAvailable;
            this.countAmts.push(getItems);
            allamts.push(getItems);
            console.log(allamts);
            return _.toPlainObject(_.zip(['amount', 'cardNumber', 'cvv'], currentItem));
          })
          .value();

        this.countAmts = _.orderBy(this.countAmts, ['numberOfVouchers'], ['desc']);
        // allamts.push(this.countAmts);
        // this.vouchersBought = data1.length;

        // =================================================================

        console.log(this.countAmts);
        // this.numVouchers = this.currentVouchers.storeAllVouchers.length;
        this.topDate = data[0].date;
        console.log(this.topDate);
        for (let z = 0; z < data.length; z++) {
          console.log(data[z]);
          this.numVouchers = this.numVouchers + data[z].storeAllVouchers.length;
          console.log(this.numVouchers);
        }

        data.splice(0, 1);
        console.log(data);

        for (let i = 0; i !== data.length; i++) {
          this.newerVouchers = data[i];
          console.log(this.newerVouchers);
          this.loadedNewerVouchers = this.newerVouchers.storeAllVouchers;

          this.getNewerResult = _.chain(this.loadedNewerVouchers)
            .groupBy('amount')
            .toPairs()
            .map((currentNewerItem) => {
              console.log(currentNewerItem);
              newerCounter = newerCounter + 1;
              const getItems = {
                i: newerCounter,
                date: this.newerVouchers.date,
                amount: currentNewerItem[0],
                numberOfVouchers: currentNewerItem[1].length
              };
              console.log(getItems);
              this.totRemaining = currentNewerItem[1].length + this.totRemaining;
              this.totNewerBought = currentNewerItem[1].length + this.totNewerBought;
              this.countNewerAmts.push(getItems);
              allamts.push(getItems);
              return _.toPlainObject(_.zip(['amount', 'cardNumber', 'cvv'], currentNewerItem));
            }).value();
          this.countNewerAmts = _.orderBy(this.countNewerAmts, ['numberOfVouchers'], ['desc']);
          console.log(this.countNewerAmts);

          if (data.length - 1 === i) {
            this.getNewerResult = _.chain(this.countNewerAmts)
              .groupBy('date')
              .toPairs()
              .map((currentDate) => {
                console.log(currentDate);
                countNewerCounter = countNewerCounter + 1;
                const getItems = {
                  i: countNewerCounter,
                  date: currentDate[0],
                  vouchers: currentDate[1]
                };
                console.log(getItems);
                this.countNewerByDate.push(getItems);
                return _.toPlainObject(_.zip(['date'], currentDate));
              }).value();

            console.log(this.countNewerByDate);
          }
        }
      }
      // for (let i = 0; i !== data.length; ++i) {
      //   console.log(data[i]);
      //   this.currentVouchers = data[i];
      //   loadedVouchers.push(this.currentVouchers.storeAllVouchers);
      //   console.log(loadedVouchers);
      //   // for (let x = 0; x !== this.currentVouchers.storeAllVouchers.length; ++x) {
      //   //   console.log(this.currentVouchers.storeAllVouchers);
      //   // }
      // }
    });

    this.vs.getAllRedeemedvouchers(this.comuid).subscribe(dat => {
      console.log(dat);
      this.allRedeemedVouchers = dat;
      const redeemResult = _.chain(dat)
        .groupBy('amount')
        .toPairs()
        .map((currItem) => {
          console.log(currItem);
          sumRedeem = currItem[1].length + sumRedeem;
          console.log(sumRedeem);
          const getRedeemItem = {
            amount: currItem[0],
            numberOfVouchers: currItem[1].length
          };
          this.totRedeemed = currItem[1].length + this.totRedeemed;
          // this.totVouchers = this.totRedeemed;
          console.log(this.totVouchers);
          this.redeemedVouchers.push(getRedeemItem);
          console.log(this.redeemedVouchers);

        }).value();
    });
    console.log(this.comuid);
    this.operationsService.getSubshops(this.logoUrl).subscribe((d: any) => {
      console.log(d[0].storename);
      this.allStorenames = d;
    });
    this.operationsService.getClientsDetails(this.comuid).pipe(take(1)).toPromise().then(data => {
      // this.storename = data.payload.data().storename;

      this.operationsService.getVouchers().pipe(first()).toPromise().then(data1 => {
        this.hideloading = false;
        console.log(data1);
        for (let d = 0; d < this.allStorenames.length; d++) {
          console.log(this.allStorenames[d].storename);
          this.boughtAmt = _.filter(data1, (x) => {
            return x.store === this.allStorenames[d].storename;
          });
          console.log(this.boughtAmt);
          for (let v = 0; v < this.boughtAmt.length; v++) {
            this.allBoughtAmt.push(this.boughtAmt[v]);
          }
          // this.boughtAmt = _.filter(data1, (x) => {
          //   return x.store === this.storename;
          // });
          // console.log(this.boughtAmt);

          this.getResult = _.chain(this.boughtAmt)
            .groupBy('originalAmount')
            .toPairs()
            .map((currentItem) => {
              console.log(currentItem);
              counter = counter + 1;
              const getItems = {
                i: counter,
                amount: currentItem[0],
                numberOfVouchers: currentItem[1].length
              };
              this.boughtAmts.push(getItems);
              this.totBought = currentItem[1].length + this.totBought;
              allamts.push(getItems);
              allboughtamts.push(getItems);
              console.log(allamts);
              return _.toPlainObject(_.zip(['date', 'ref', 'store', 'transactionAmmount'], currentItem));
            })
            .value();
          this.boughtAmts = _.orderBy(this.boughtAmts, ['numberOfVouchers'], ['desc']);
          // allamts.push(this.boughtAmts);
          console.log(allamts);
          console.log(allboughtamts);

          if (this.allStorenames.length - 1 === d) {
            const allboughtamt = _.chain(allboughtamts)
            .groupBy('amount')
            .toPairs()
            .map((curItem) => {
              console.log(curItem);

              for (let b = 0; b < curItem[1].length; b++) {
                sumAllShops = curItem[1][b].numberOfVouchers + sumAllShops;
                if (curItem[1].length - 1 === b) {
                  const getItemm = {
                    amount: curItem[0],
                    totNumber: sumAllShops
                  };
                  console.log(getItemm);
                  this.displayAllShopsamt.push(getItemm);
                  console.log(this.displayAllShopsamt);
                  // this.totVouchers = sumAllShops + this.totVouchers;
                  sumAllShops = 0;
                }
              }

            }).value();

            this.getResultAllAmt = _.chain(allamts)
              .groupBy('amount')
              .toPairs()
              .map((currItem) => {
                const storeAmt = [];

                for (let c = 0; c < currItem[1].length; c++) {
                  sum = currItem[1][c].numberOfVouchers + sum;
                  if (currItem[1].length - 1 === c) {
                    const getItem = {
                      amount: currItem[0],
                      totNumber: sum
                    };
                    console.log(getItem);
                    this.displayAllamt.push(getItem);
                    // this.displayAllamt = pushallamts;
                    console.log(this.displayAllamt);
                    this.totVouchers = sum + this.totVouchers;
                    sum = 0;
                  }
                }
              }).value();
            this.vouchersSold = this.boughtAmt.length;
          }
        }
      });
    });

    this.operationsService.getDetails(this.uid).pipe(take(1)).toPromise().then(data => {
      console.log(data);
      if (!data) {
        this.operationsService.getClientsDetails(this.uid).pipe(take(1)).toPromise().then(data2 => {
          this.checkSupplier(data2.payload.data());
        });
      } else {
        this.checkSupplier(data);
      }
    });
  }
  checkSupplier(data) {
    this.userDetails = data;
    this.name = this.userDetails.name;
    this.TypeOfUser = this.userDetails.userType;
    this.isRedeem = this.userDetails.isRedeem;
    if (!this.isRedeem) {
      this.isRedeem = false;
      console.log(this.isRedeem);
    } else {
      this.isRedeem = true;
      console.log(this.isRedeem);
    }
    if (!this.name) {
      this.name = this.userDetails.fname + ' ' + this.userDetails.sname;
    }
  }
  gotoLockuser() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/lockeduser'], navigationExtras);
  }

  gotoViewAllVouchers(x) {
    console.log(x);
    console.log(this.logoUrl);
    console.log(this.comuid);
    this.operationsService.getClientByLogourl(this.logoUrl).subscribe(data => {
      console.log(data);
    });
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid,
        comuid: this.comuid,
        amount: x,
        logoUrl: this.logoUrl,
        originalLogoUrl: this.originalLogoUrl,
        from: 'viewvouchershop',
        storename: this.storename
      }, skipLocationChange: true
    };
    this.router.navigate(['/viewallvouchers'], navigationExtras);
  }

  gotoAllNewerVouchers(x, date) {
    console.log(x);
    console.log(date);
    console.log(this.logoUrl);
    console.log(this.comuid);
    this.operationsService.getClientByLogourl(this.logoUrl).subscribe(data => {
      console.log(data);
    });
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid,
        comuid: this.comuid,
        amount: x,
        date: date,
        logoUrl: this.logoUrl,
        originalLogoUrl: this.originalLogoUrl,
        storename: this.storename
      }, skipLocationChange: true
    };
    this.router.navigate(['/allnewervouchers'], navigationExtras);
  }

  gotoBoughtVouchers(x) {
    console.log(this.logoUrl);
    console.log(this.allBoughtAmt);
    this.operationsService.getClientByLogourl(this.logoUrl).pipe(first()).toPromise().then(data => {
      console.log(data);
    });
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid,
        comuid: this.comuid,
        logoUrl: this.logoUrl,
        amount: x,
        boughtVouchers: JSON.stringify(this.allBoughtAmt),
        originalLogoUrl: this.originalLogoUrl,
        storename: this.storename
      }, skipLocationChange: true
    };
    this.router.navigate(['/viewallvouchersbought'], navigationExtras);
  }
  gotoRedeemedVouchers(x) {
    console.log(this.logoUrl);
    console.log(this.allRedeemedVouchers);
    this.operationsService.getClientByLogourl(this.logoUrl).pipe(first()).toPromise().then(data => {
      console.log(data);
    });
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid,
        comuid: this.comuid,
        logoUrl: this.logoUrl,
        amount: x,
        boughtVouchers: JSON.stringify(this.allRedeemedVouchers),
        originalLogoUrl: this.originalLogoUrl,
        storename: this.storename
      }, skipLocationChange: true
    };
    this.router.navigate(['/viewredeemedvouchers'], navigationExtras);
  }
  notClickable(content) {
    console.log(content);
    this.message = 'Please click on available vouchers or bought vouchers to view vouchers details';

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  gotoLogin() {
    this.operationsService.updateSignInStatus(this.uid, false);
    this.afAuth.auth.signOut();
    this.router.navigateByUrl('/login');
  }

  gotohome() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/home'], navigationExtras);
  }
  gotoVouchersBought() {
    if (this.userDetails.userType === 'superAdmin') {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['selectbought'], navigationExtras);
    } else {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['/vouches'], navigationExtras);
    }
  }
  gotoAddClients() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-client'], navigationExtras);
  }
  gotoClients() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/clients'], navigationExtras);
  }
  gotoAddNewVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-new-vouchers'], navigationExtras);
  }
  gotoCardTemplates() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/card-templates'], navigationExtras);
  }
  gotoAddCardTemplate() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-card-template'], navigationExtras);
  }
  gotoAvailableVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/availvouchers'], navigationExtras);
  }
  gotoAddCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addsuppcat'], navigationExtras);
  }
  gotoAddtemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addtempcat'], navigationExtras);
  }
  gotoDeltemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/deltempcat'], navigationExtras);
  }
  gotoDelsupplierCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/delsuppcat'], navigationExtras);
  }
  gotoReemvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeem'], navigationExtras);
  }
  gotoRedeemedvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeemedvouchers'], navigationExtras);
  }
}
