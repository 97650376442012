import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { VouchersService } from '../services/vouchers.service';
import { OperationsService } from '../shared/operations.service';
import * as _ from 'lodash';
import { AngularFireAuth } from 'angularfire2/auth';

@Component({
  selector: 'app-available-vouchers',
  templateUrl: './available-vouchers.component.html',
  styleUrls: ['./available-vouchers.component.css']
})
export class AvailableVouchersComponent implements OnInit {
  public isCollapsed = true;
  public isGarageCollapsed = true;
  public isAddCollapsed = true;
  public isVoucherCollapsed = true;
  public isRedeemed = true;
  isRedeem: any;
  closeResult: string;
  vouchers: any;
  uid: any;
  name: string;
  searchText: any;
  userDetails: any;
  stores: any;
  TypeOfUser: any;
  hideloading: boolean;
  setFalse: boolean;

  mainStores: any;
  normalStores: any;
  unsubClient: any;

  constructor(private router: Router, private route: ActivatedRoute, private vs: VouchersService
    , private operationsService: OperationsService, private afAuth: AngularFireAuth) {
    this.hideloading = true;
    this.setFalse = true;
    $(document).ready(function () {

      // $('#sidebar').mCustomScrollbar({
      //   theme: 'minimal'
      // });

      $('#sidebarCollapse').on('click', function () {
        // open or close navbar
        $('#sidebar').toggleClass('active');
        // close dropdowns
        $('.collapse.in').toggleClass('in');
        // and also adjust aria-expanded attributes we use for the open/closed arrows
        // in our CSS
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
      });

    });
    this.route.queryParams.subscribe(params => {
      this.uid = params['uid'];
    });
   }
  gotoViewCurrentStock(x, y) {
    console.log(x);
    this.unsubClient = this.operationsService.getClientByLogourl(y).subscribe(data => {
      console.log(data);
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid,
          comuid: data[0].payload.doc.id,
          originalLogoUrl: x,
          logoUrl: y
        }, skipLocationChange: true
      };
      this.router.navigate(['/viewvouchershop'], navigationExtras).then(dd => {
        console.log('Unsub');
        this.unsubClient.unsubscribe();
      });
    });
  }
  gotoViewAllVouchers(x, y, i) {
    console.log(x);
    this.unsubClient = this.operationsService.getClientByLogourl(y).subscribe(data => {
      console.log(data);
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid,
          comuid: data[0].payload.doc.id,
          originalLogoUrl: x,
          logoUrl: y,
          storename: i.storename
        }, skipLocationChange: true
      };
      this.router.navigate(['/allvouchers'], navigationExtras).then(dd => {
        console.log('Unsub');
        this.unsubClient.unsubscribe();
      });
    });
  }
  gotoPromotional() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/specials'], navigationExtras);
  }
  gotoCharge() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/bluelabel'], navigationExtras);
  }
  gotoLockuser() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/lockeduser'], navigationExtras);
  }
  gotoDeleteVoucher(x, y) {
    console.log(x);
    console.log(y);
    this.unsubClient = this.operationsService.getClientByLogourl(y).subscribe(data => {
      console.log(data);
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid,
          comuid: data[0].payload.doc.id,
          originalLogoUrl: x,
          logoUrl: y
        }, skipLocationChange: true
      };
      this.router.navigate(['/deletevouchers'], navigationExtras).then(dd => {
        console.log('Unsub');
        this.unsubClient.unsubscribe();
      });
    });
  }
  gotoDeltemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/deltempcat'], navigationExtras);
  }
  gotoDelsupplierCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/delsuppcat'], navigationExtras);
  }
  gotoReemvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeem'], navigationExtras);
  }
  gotoRedeemedvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeemedvouchers'], navigationExtras);
  }

  gotoLogin() {
    this.operationsService.updateSignInStatus(this.uid, false);
    this.afAuth.auth.signOut();
    this.router.navigateByUrl('/login');
  }
  gotohome() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/home'], navigationExtras);
  }
  gotoVouchersBought() {
    if (this.userDetails.userType === 'superAdmin') {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['selectbought'], navigationExtras);
    } else {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['/vouches'], navigationExtras);
    }
  }
  gotoAddClients() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-client'], navigationExtras);
  }
  gotoClients() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/clients'], navigationExtras);
  }
  gotoAddNewVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-new-vouchers'], navigationExtras);
  }
  gotoCardTemplates() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/card-templates'], navigationExtras);
  }
  gotoAddCardTemplate() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-card-template'], navigationExtras);
  }
  gotoAvailableVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/availvouchers'], navigationExtras);
  }

  gotoEditVoucher(x, y, storeobj) {
    console.log(x);
    console.log(y);
    console.log(storeobj);
    this.unsubClient = this.operationsService.getClientByLogourl(y).subscribe(data => {
      console.log(data);
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid,
          comuid: data[0].payload.doc.id,
          originalLogoUrl: x,
          logoUrl: y,
          storeobj: JSON.stringify(storeobj)
        }, skipLocationChange: true
      };
      this.router.navigate(['/edit-voucher'], navigationExtras).then(dd => {
        console.log('Unsub');
        this.unsubClient.unsubscribe();
      });
    });
  }
  gotoAddCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addsuppcat'], navigationExtras);
  }
  gotoAddtemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addtempcat'], navigationExtras);
  }
  checkSupplier(data) {
    this.userDetails = data;
    this.name = this.userDetails.name;
    this.TypeOfUser = this.userDetails.userType;

    this.isRedeem = this.userDetails.isRedeem;
    if (!this.isRedeem) {
      this.isRedeem = false;
      console.log(this.isRedeem);
    } else {
      this.isRedeem = true;
      console.log(this.isRedeem);
    }
    if (!this.name) {
      this.name = this.userDetails.fname + ' ' + this.userDetails.sname;
    }
    if (data.userType === 'superAdmin') {
      this.operationsService.getAllSuppliers().subscribe(data1 => {
        console.log(data1);
        this.vouchers = data1;
        this.hideloading = false;
        // this.vouchers = _.filter(data1, (x) => {
        //   return x.isClient === 'true';
        // });
        // console.log(this.vouchers);

        this.mainStores = _.filter(this.vouchers, (x) => {
          return x.isMain === true;
        });

        this.normalStores = _.filter(this.vouchers, (x) => {
          return x.isMain === false;
        });
        // this.vouchers = _.reverse(this.vouchers);
      });
    } else {
      this.operationsService.getAllSuppliers().subscribe((data2: any) => {
        this.vouchers = data2;
        console.log(this.vouchers);
        this.hideloading = false;
        console.log(this.vouchers);
        this.mainStores = _.filter(data2, (x) => {
          console.log(x);
          return x.storename === data.storename;
        });
        console.log(this.mainStores);
        // this.vouchers = _.reverse(this.vouchers);
      });
    }
  }
  ngOnInit() {
    this.operationsService.getDetails(this.uid).subscribe(data => {
      console.log(data);

      if (!data) {
        this.operationsService.getClientsDetails(this.uid).subscribe(data2 => {
          this.checkSupplier(data2.payload.data());
        });
      } else {
        this.checkSupplier(data);
      }
    });
  }

}
