import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { VouchesBoughtComponent } from './vouches-bought/vouches-bought.component';
import { ClientsComponent } from './clients/clients.component';
import { AddClientComponent } from './add-client/add-client.component';
import { AddNewVouchersComponent } from './add-new-vouchers/add-new-vouchers.component';
import { CardTemplatesComponent } from './card-templates/card-templates.component';
import { EditVoucherComponent } from './edit-voucher/edit-voucher.component';
import { EditCardComponent } from './edit-card/edit-card.component';
import { AddCardTemplateComponent } from './add-card-template/add-card-template.component';
import { AvailableVouchersComponent } from './available-vouchers/available-vouchers.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SelectstorevouchersComponent } from './selectstorevouchers/selectstorevouchers.component';
import { ViewvouchersComponent } from './viewvouchers/viewvouchers.component';
import { ClickvouchersComponent } from './clickvouchers/clickvouchers.component';
import { ViewclickedvouchersComponent } from './viewclickedvouchers/viewclickedvouchers.component';
import { SelectboughtvouchersComponent } from './selectboughtvouchers/selectboughtvouchers.component';
import { EditSuppliersComponent } from './edit-suppliers/edit-suppliers.component';
import { AddtemplatecategoryComponent } from './addtemplatecategory/addtemplatecategory.component';
import { AddsuppliercategoryComponent } from './addsuppliercategory/addsuppliercategory.component';
import { DeletetempcatComponent } from './deletetempcat/deletetempcat.component';
import { DeletesuppliercatComponent } from './deletesuppliercat/deletesuppliercat.component';
import { ViewnewervouchersComponent } from './viewnewervouchers/viewnewervouchers.component';
import { EditsuppcategoryComponent } from './editsuppcategory/editsuppcategory.component';
import { EdittemplatecategoryComponent } from './edittemplatecategory/edittemplatecategory.component';
import { BlockeduserComponent } from './blockeduser/blockeduser.component';
import { BlueServiceChargeComponent } from './blue-service-charge/blue-service-charge.component';
import { OutofstockComponent } from './outofstock/outofstock.component';
import { SpecialsComponent } from './specials/specials.component';
import { ViewvouchersbyshopComponent } from './viewvouchersbyshop/viewvouchersbyshop.component';
import { ViewvouchershopComponent } from './viewvouchershop/viewvouchershop.component';
import { DeletevouchersComponent } from './deletevouchers/deletevouchers.component';
import { ViewlowstockvouchersComponent } from './viewlowstockvouchers/viewlowstockvouchers.component';
import { LowstockdenomComponent } from './lowstockdenom/lowstockdenom.component';
import { GroupshopsComponent } from './groupshops/groupshops.component';
import { RedeemComponent } from './redeem/redeem.component';
import { RedeemedvouchersComponent } from './redeemedvouchers/redeemedvouchers.component';
import { AllvouchersComponent } from './allvouchers/allvouchers.component';
import { ViewallvouchersComponent } from './viewallvouchers/viewallvouchers.component';
import { ViewallvouchersboughtComponent } from './viewallvouchersbought/viewallvouchersbought.component';
import { AllnewervouchersComponent } from './allnewervouchers/allnewervouchers.component';
import { ViewredeemedvouchersComponent } from './viewredeemedvouchers/viewredeemedvouchers.component';

const appRoutes: Routes = [
    { path: '', component: LoginComponent },
    { path: 'home', component: HomeComponent },
    { path: 'login', component: LoginComponent },
    { path: 'vouches', component: VouchesBoughtComponent },
    { path: 'clients', component: ClientsComponent },
    { path: 'add-client', component: AddClientComponent },
    { path: 'add-new-vouchers', component: AddNewVouchersComponent },
    { path: 'card-templates', component: CardTemplatesComponent },
    { path: 'edit-voucher', component: EditVoucherComponent },
    { path: 'edit-card', component: EditCardComponent },
    { path: 'add-card-template', component: AddCardTemplateComponent },
    { path: 'availvouchers', component: AvailableVouchersComponent },
    { path: 'reset', component: ResetPasswordComponent },
    { path: 'selectstore', component: SelectstorevouchersComponent },
    { path: 'viewvouchers', component: ViewvouchersComponent },
    { path: 'clickvouchers', component: ClickvouchersComponent },
    { path: 'viewclicked', component: ViewclickedvouchersComponent },
    { path: 'selectbought', component: SelectboughtvouchersComponent },
    { path: 'editsupplier', component: EditSuppliersComponent },
    { path: 'addtempcat', component: AddtemplatecategoryComponent },
    { path: 'addsuppcat', component: AddsuppliercategoryComponent },
    { path: 'deltempcat', component: DeletetempcatComponent },
    { path: 'delsuppcat', component: DeletesuppliercatComponent },
    { path: 'viewnewer', component: ViewnewervouchersComponent },
    { path: 'editsuppcat', component: EditsuppcategoryComponent },
    { path: 'editsupptemp', component: EdittemplatecategoryComponent },
    { path: 'lockeduser', component: BlockeduserComponent },
    { path: 'bluelabel', component: BlueServiceChargeComponent },
    { path: 'outofstock', component: OutofstockComponent },
    { path: 'specials', component: SpecialsComponent },
    { path: 'viewvouchersbyshop', component: ViewvouchersbyshopComponent },
    { path: 'viewvouchershop', component: ViewvouchershopComponent },
    { path: 'deletevouchers', component: DeletevouchersComponent },
    { path: 'viewlowstock', component: ViewlowstockvouchersComponent },
    { path: 'lowstockdom', component: LowstockdenomComponent },
    { path: 'groupstores', component: GroupshopsComponent },
    { path: 'redeem', component: RedeemComponent },
    { path: 'redeemedvouchers', component: RedeemedvouchersComponent },
    { path: 'allvouchers', component: AllvouchersComponent },
    { path: 'viewallvouchers', component: ViewallvouchersComponent },
    { path: 'viewallvouchersbought', component: ViewallvouchersboughtComponent },
    { path: 'allnewervouchers', component: AllnewervouchersComponent },
    { path: 'viewredeemedvouchers', component: ViewredeemedvouchersComponent },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);
