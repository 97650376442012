import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { OperationsService } from '../shared/operations.service';
import * as XLSX from 'xlsx';
import { AngularFireDatabase } from 'angularfire2/database';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { AngularFireAuth } from 'angularfire2/auth';

@Component({
  selector: 'app-lowstockdenom',
  templateUrl: './lowstockdenom.component.html',
  styleUrls: ['./lowstockdenom.component.css']
})
export class LowstockdenomComponent implements OnInit {

  public isCollapsed = true;
  public isGarageCollapsed = true;
  public isAddCollapsed = true;
  public isVoucherCollapsed = true;
  public isRedeemed = true;
  closeResult: string;
  uid: any;
  name: string;
  searchText: any;
  userDetails: any;
  file: File;
  arrayBuffer: any;
  comuid: any;
  message: any;
  TypeOfUser: any;
  logoUrl: any;
  currentVouchers: any;
  loadedVouchers: any;
  numVouchers: any;
  storename: any;
  isShowCvv: any;
  countAmts = [];
  getResult: any;
  hideloading: boolean;

  amount: any;
  companyName: any;
  outofstock = [];
  displayOutofstock: any;
  showlowStock: boolean;
  isRedeem: any;

  constructor(private router: Router, private route: ActivatedRoute, private operationsService: OperationsService
    , private afd: AngularFireDatabase, private modalService: NgbModal, private afAuth: AngularFireAuth) {
    this.hideloading = true;
    this.showlowStock = false;
    $(document).ready(function () {

      // $('#sidebar').mCustomScrollbar({
      //   theme: 'minimal'
      // });

      $('#sidebarCollapse').on('click', function () {
        // open or close navbar
        $('#sidebar').toggleClass('active');
        // close dropdowns
        $('.collapse.in').toggleClass('in');
        // and also adjust aria-expanded attributes we use for the open/closed arrows
        // in our CSS
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
      });

    });
    this.route.queryParams.subscribe(params => {
      this.uid = params['uid'];
      this.comuid = params['comuid'];
      this.companyName = params['companyName'];
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  gotoLockuser() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/lockeduser'], navigationExtras);
  }
  gotoCharge() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/bluelabel'], navigationExtras);
  }
  gotoDeltemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/deltempcat'], navigationExtras);
  }
  gotoPromotional() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/specials'], navigationExtras);
  }
  goBack() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/outofstock'], navigationExtras);
  }

  gotoViewVouchers(x) {
    console.log(x);
    console.log(this.comuid);
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid,
        comuid: this.comuid,
        amount: x,
      }, skipLocationChange: true
    };
    this.router.navigate(['/viewlowstock'], navigationExtras);
  }

  ngOnInit() {

    this.operationsService.getDetails(this.uid).subscribe(data => {
      console.log(data);
      if (!data) {
        this.operationsService.getClientsDetails(this.uid).subscribe(data2 => {
          this.checkSupplier(data2.payload.data());
        });
      } else {
        this.checkSupplier(data);
      }
    });
  }
  checkSupplier(dat) {
    this.userDetails = dat;
    this.name = this.userDetails.name;
    this.TypeOfUser = this.userDetails.userType;
    this.isRedeem = this.userDetails.isRedeem;
    if (!this.isRedeem) {
      this.isRedeem = false;
      console.log(this.isRedeem);
    } else {
      this.isRedeem = true;
      console.log(this.isRedeem);
    }
    if (!this.name) {
      this.name = this.userDetails.fname + ' ' + this.userDetails.sname;
    }

    let counter = 0;
    const loadedVouchers = [];
    const storeAmounts = [];
    let countZeros = 0;
    let countAllZeros = 0;
    const objMap = {};
    console.log(this.comuid);
    this.operationsService.getVoucher(this.comuid).subscribe((data: any) => {
      console.log(data);
      this.hideloading = false;
      if (data[0].companyName === 'woolworths') {
        console.log('set true');
        this.isShowCvv = true;
      }
      this.currentVouchers = data[0];

      this.loadedVouchers = this.currentVouchers.storeAllVouchers;
      console.log(this.loadedVouchers);
      const vouchersAmount = this.currentVouchers.voucherAmt;
      console.log(vouchersAmount);

      for (let b = 0; b < this.loadedVouchers.length; b++) {
        const zeroAmt1 = _.filter(vouchersAmount, (k: any) => {

          if (this.loadedVouchers[b].amount.includes(k) === false) {
            countZeros = 0;
            storeAmounts.push({ amt: k, numberOfZeros: countZeros });
          } else {
            countZeros = countZeros + 1;
            storeAmounts.push({ amt: k, numberOfZeros: countZeros });
          }
          return this.loadedVouchers[b].amount.indexOf(k) === -1;
        });
        console.log(zeroAmt1);
        if (this.loadedVouchers.length - 1 === b) {
          console.log(storeAmounts);
          this.getResult = _.chain(storeAmounts)
            .groupBy('amt')
            .toPairs()
            .map((currentItem) => {
              console.log(currentItem);
              counter = counter + 1;
              for (let z = 0; z < currentItem[1].length; z++) {
                countAllZeros = countAllZeros + currentItem[1][z].numberOfZeros;
              }
              const getItems = {
                i: counter,
                amount: currentItem[0],
                counter: countAllZeros
              };
              console.log(getItems);
              this.outofstock.push(getItems);
              countAllZeros = 0;
              return _.toPlainObject(_.zip(['amount', 'cardNumber', 'cvv'], currentItem));
            })
            .value();

          this.displayOutofstock = _.filter(this.outofstock, (k: any) => {
            console.log(k);
            return k.counter === 0;
          });
          console.log(this.displayOutofstock);
          if (this.displayOutofstock.length >= 1) {
            this.showlowStock = true;
          } else {
            this.showlowStock = false;
          }
        }
      }

      this.getResult = _.chain(this.loadedVouchers)
        .groupBy('amount')
        .toPairs()
        .map((currentItem) => {
          console.log(currentItem);
          counter = counter + 1;
          const getItems = {
            i: counter,
            amount: currentItem[0],
            numberOfVouchers: currentItem[1].length
          };
          this.countAmts.push(getItems);
          return _.toPlainObject(_.zip(['amount', 'cardNumber', 'cvv'], currentItem));
        })
        .value();

      this.countAmts = _.orderBy(this.countAmts, ['numberOfVouchers'], ['desc']);
      console.log(this.countAmts);
      // this.loadedVouchers = _.filter(this.loadedVouchers, (x: any) => {
      //   console.log(x);
      //   return x.amount === this.amount;
      // });
    });

  }

  gotoLogin() {
    this.operationsService.updateSignInStatus(this.uid, false);
    this.afAuth.auth.signOut();
    this.router.navigateByUrl('/login');
  }

  gotohome() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/home'], navigationExtras);
  }
  gotoVouchersBought() {
    if (this.userDetails.userType === 'superAdmin') {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['selectbought'], navigationExtras);
    } else {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['/vouches'], navigationExtras);
    }
  }
  gotoReemvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeem'], navigationExtras);
  }
  gotoRedeemedvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeemedvouchers'], navigationExtras);
  }
  gotoAddClients() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-client'], navigationExtras);
  }
  gotoClients() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/clients'], navigationExtras);
  }
  gotoAddNewVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-new-vouchers'], navigationExtras);
  }
  gotoCardTemplates() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/card-templates'], navigationExtras);
  }
  gotoAddCardTemplate() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-card-template'], navigationExtras);
  }
  gotoAvailableVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/availvouchers'], navigationExtras);
  }
  gotoAddCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addsuppcat'], navigationExtras);
  }
  gotoAddtemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addtempcat'], navigationExtras);
  }
}
