import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { OperationsService } from '../shared/operations.service';
import * as XLSX from 'xlsx';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFirestore } from 'angularfire2/firestore';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { AngularFireAuth } from 'angularfire2/auth';
import { first, take } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-voucher',
  templateUrl: './edit-voucher.component.html',
  styleUrls: ['./edit-voucher.component.css']
})
export class EditVoucherComponent implements OnInit {
  public isCollapsed = true;
  public isGarageCollapsed = true;
  public isAddCollapsed = true;
  public isVoucherCollapsed = true;
  public isRedeemed = true;
  isRedeem: any;
  closeResult: string;
  checkCvv: string;
  checkAmount: string;
  uid: any;
  name: string;
  searchText: any;
  userDetails: any;
  file: File;
  arrayBuffer: any;
  comuid: any;
  message: any;
  TypeOfUser: any;
  logoUrl: any;
  currentVouchers: any;
  newerVouchers: any;
  loadedVouchers: any;
  loadedNewerVouchers: any;
  numVouchers: any;
  storename: any;
  showuploadVouchers: any;
  countAmts = [];
  countNewerAmts = [];
  countNewerByDate = [];
  checkVouchersLength: any;
  getResult: any;
  getNewerResult: any;
  vcount: any;
  hideloading: boolean;
  originalLogoUrl: any;
  commission: any;
  discount: any;
  serviceCharge: any;
  storeobj: any;
  checkIsShop: any;
  validUntil: any;
  months: any;
  topDate: any;
  storeAmt = [];
  storeAmtsKey: any;

  constructor(private router: Router, private route: ActivatedRoute, private operationsService: OperationsService
    , private afd: AngularFireDatabase, private afs: AngularFirestore, private modalService: NgbModal, private afAuth: AngularFireAuth) {
    this.hideloading = true;
    $(document).ready(function () {

      // $('#sidebar').mCustomScrollbar({
      //   theme: 'minimal'
      // });

      $('#sidebarCollapse').on('click', function () {
        // open or close navbar
        $('#sidebar').toggleClass('active');
        // close dropdowns
        $('.collapse.in').toggleClass('in');
        // and also adjust aria-expanded attributes we use for the open/closed arrows
        // in our CSS
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
      });

    });
    this.route.queryParams.subscribe(params => {
      this.uid = params['uid'];
      this.comuid = params['comuid'];
      this.logoUrl = params['logoUrl'];
      this.originalLogoUrl = params['originalLogoUrl'];
      this.storeobj = params['storeobj'];
      this.checkIsShop = JSON.parse(this.storeobj);
      console.log(this.checkIsShop);
    });

    this.months = [{ num: 3 }, { num: 6 }, { num: 9 }, { num: 12 }, { num: 18 }, { num: 24 }];

  }
  invokeUpload(event: any, content) {
    if (this.checkIsShop.userType === 'Shop') {
      this.spreadsheetUploadShop(event, content);
    } else if (this.checkIsShop.userType === 'Franchise') {
      this.spreadsheetUpload(event, content);
    }
  }
  dateSelected(event: any, content) {
    console.log(event);
    this.validUntil = event.target.value;
  }
  selectMonth(x) {
    console.log(x);
    this.validUntil = x.srcElement.value;
  }

  spreadsheetUpload(event: any, content) {
    const storeVouchers = [];
    let extractedExcel = [];
    const voucherAmt = [];
    let reservedAmt = [];
    console.log(voucherAmt);
    reservedAmt = this.storeAmt;
    console.log(reservedAmt);
    const row1 = [];
    const row2 = [];
    const row3 = [];
    const storeAllVouchers = [];
    this.file = event.target.files[0];
    console.log(this.file);
    // if (!this.validUntil) {
    //   this.message = 'Please select validity date first to continue';

    //   this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    //     this.closeResult = `Closed with: ${result}`;
    //   }, (reason) => {
    //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    //   });
    // } else {
      if (!this.validUntil) {
        this.validUntil = 'N/A';
      }
      if (this.file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const fileReader = new FileReader();
        console.log(fileReader);
        fileReader.onload = (e) => {
          console.log(e);
          this.arrayBuffer = fileReader.result;

          const data = new Uint8Array(this.arrayBuffer);
          const arr = new Array();
          for (let i = 0; i !== data.length; ++i) {
            arr[i] = String.fromCharCode(data[i]);
          }
          const bstr = arr.join('');
          console.log(bstr);
          const workbook = XLSX.read(bstr, { type: 'binary' });
          console.log(workbook);
          const first_sheet_name = workbook.SheetNames[0];
          console.log(first_sheet_name);
          const worksheet = workbook.Sheets[first_sheet_name];
          console.log(worksheet);
          const res = this.convertToTable(worksheet);
          console.log(res);
          for (let x = 0; x < res[3].length; x += 3) {
            console.log(x);
            const amt = res[3][x];
            console.log(amt);
            if (amt === undefined) {

            } else {

              console.log(amt.length);
              const stripAmt = amt.substr(4, amt.length);
              console.log(stripAmt);
              let lastStrip = stripAmt;
              lastStrip = lastStrip.slice(0, -3);
              console.log(lastStrip);
              if (!reservedAmt || reservedAmt.length === 0) {
                voucherAmt.push(lastStrip);
                reservedAmt.push(lastStrip);
              } else {
                if (reservedAmt.includes(lastStrip) === true) {
                  console.log('exists');
                  voucherAmt.push(lastStrip);
                } else {
                  voucherAmt.push(lastStrip);
                  reservedAmt.push(lastStrip);
                }
              }
            }

          }
          console.log(voucherAmt);
          console.log(res[3].length);
          for (let y = 5; y !== res.length; ++y) {
            for (let z = 0; z < res[3].length; z += 3) {
              const addOne = z + 1;
              console.log(res[y][addOne]);
              if (res[y][addOne] === undefined) {
                const amt = res[3][z];
                console.log(amt);
                console.log(res[y][z]);
                if (res[y][z] === undefined || res[y][z] === ' ') {
                  console.log(res[y][z]);
                } else {
                  console.log('no value');
                  if (amt === undefined) {

                  } else {
                    console.log(amt.length);
                    const stripAmt = amt.substr(4, amt.length);
                    let lastStrip = stripAmt;
                    lastStrip = lastStrip.slice(0, -3);
                    console.log(res[y][z]);

                    const letterNumber = /^[0-9]+$/;
                    this.checkAmount = lastStrip;

                    if (this.checkAmount.match(/[.]/)) {
                      this.message = 'Wrong format on amount';

                      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                        this.closeResult = `Closed with: ${result}`;
                      }, (reason) => {
                        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                      });
                      return;
                    } else {
                      if (this.checkAmount.match(letterNumber)) {
                        storeAllVouchers.push({ cardNumber: res[y][z], amount: lastStrip });
                      } else {
                        this.message = 'Wrong format on amount';

                        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                          this.closeResult = `Closed with: ${result}`;
                        }, (reason) => {
                          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                        });
                        return;
                      }
                    }
                  }
                }
              } else {
                const amt = res[3][z];
                console.log(amt);
                if (amt === undefined) {

                } else {
                  console.log(amt.length);
                  const stripAmt = amt.substr(4, amt.length);
                  let lastStrip = stripAmt;
                  lastStrip = lastStrip.slice(0, -3);

                  if (res[0][0] === 'Woolworths' || res[0][0] === 'woolworths') {
                    console.log(typeof res[y][addOne]);

                    const letterNumber = /^[0-9]+$/;
                    // if (cc )
                    this.checkCvv = res[y][addOne];

                    if (this.checkCvv.match(letterNumber)) {
                      console.log(true);
                      this.checkAmount = lastStrip;

                      const checkPoints = ['.'];
                      if (this.checkAmount.match(/[.]/)) {
                        this.message = 'Wrong format on amount';

                        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                          this.closeResult = `Closed with: ${result}`;
                        }, (reason) => {
                          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                        });
                        return;
                      } else {
                        if (this.checkAmount.match(letterNumber)) {
                          storeAllVouchers.push({ cardNumber: res[y][z], cvv: res[y][addOne], amount: lastStrip });
                        } else {
                          this.message = 'Wrong format on amount';

                          this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                            this.closeResult = `Closed with: ${result}`;
                          }, (reason) => {
                            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                          });
                          return;
                        }
                      }
                    } else {
                      console.log(false);
                      this.message = 'Wrong format on CVV number';

                      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                        this.closeResult = `Closed with: ${result}`;
                      }, (reason) => {
                        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                      });
                      return;
                    }
                  } else {
                    const letterNumber1 = /^[0-9]+$/;
                    this.checkAmount = lastStrip;

                    if (this.checkAmount.match(/[.]/)) {
                      this.message = 'Wrong format on amount';

                      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                        this.closeResult = `Closed with: ${result}`;
                      }, (reason) => {
                        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                      });
                      return;
                    } else {
                      if (this.checkAmount.match(letterNumber1)) {
                        storeAllVouchers.push({ cardNumber: res[y][z], amount: lastStrip });
                      } else {
                        this.message = 'Wrong format on amount';

                        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                          this.closeResult = `Closed with: ${result}`;
                        }, (reason) => {
                          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                        });
                        return;
                      }
                    }
                  }
                }
              }
            }

          }
          console.log(storeAllVouchers);
          const newObject = {
            companyName: res[0][0].toLowerCase(),
            campaignNo: res[1][0],
          };
          console.log(newObject);
          extractedExcel = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          console.log(extractedExcel);
          for (let i = 3; i !== extractedExcel.length; ++i) {
            // console.log(i);
            // console.log(JSON.parse(JSON.stringify(extractedExcel[i]) + '.Woolworths'));
            console.log(worksheet.A1.h);
            if (worksheet.A1.h === 'Woolworths') {
              storeVouchers.push({ cvv: extractedExcel[i]['5%'], cardNumber: extractedExcel[i].Woolworths, amount: worksheet.A4.v });
            } else if (worksheet.A1.h === 'Shoprite') {
              storeVouchers.push({ cvv: extractedExcel[i]['5%'], cardNumber: extractedExcel[i].Shoprite, amount: worksheet.A4.v });
            } else if (worksheet.A1.h === 'Takealot') {
              storeVouchers.push({ cvv: extractedExcel[i]['5%'], cardNumber: extractedExcel[i].Takealot, amount: worksheet.A4.v });
            }

          }
          console.log(storeVouchers);
          const com = res[0][1];
          const slicedCom = com.slice(0, -1);
          const dis = res[0][2];
          const slicedDis = dis.slice(0, -1);
          console.log(slicedDis);

          const voucherObj = {
            companyName: res[0][0].toLowerCase(),
            campaignNo: res[1][0],
            commission: slicedCom,
            discount: slicedDis,
            voucherAmt,
            storeAllVouchers
          };
          console.log(voucherObj);
          if (this.storename.toLowerCase() === res[0][0].toLowerCase()) {

            // this.afs.collection('supplierVouchers').doc(this.comuid)
            // .collection('vouchers')
            // .add({
            //   date: moment().format('MMMM Do YYYY, h:mm:ss'),
            //   companyName: res[0][0].toLowerCase(),
            //   campaignNo: res[1][0],
            //   commission: this.commission,
            //   discount: this.discount,
            //   serviceCharge: this.serviceCharge,
            //   voucherAmt,
            //   storeAllVouchers
            // })

            // this.afd.database
            //   .ref('/userProfile')
            //   .child(this.comuid)
            //   .child('/vouchers')
            //   .push({
            //     date: moment().format('MMMM Do YYYY, h:mm:ss'),
            //     companyName: res[0][0].toLowerCase(),
            //     campaignNo: res[1][0],
            //     commission: this.commission,
            //     discount: this.discount,
            //     serviceCharge: this.serviceCharge,
            //     voucherAmt,
            //     storeAllVouchers
            //   })

            this.afs.collection('supplierVouchers').doc(this.comuid)
              .collection('vouchers')
              .add({
                date: moment().format('MMMM Do YYYY, h:mm:ss'),
                validUntil: this.validUntil,
                companyName: res[0][0].toLowerCase(),
                campaignNo: res[1][0],
                commission: this.commission,
                discount: this.discount,
                serviceCharge: this.serviceCharge,
                voucherAmt,
                storeAllVouchers
              })
              .then(data1 => {
                console.log(data1);
                if (this.commission === '-1') {
                  console.log('Elo');
                  if (!this.storeAmtsKey) {
                    this.afs.collection('supplierVouchers').doc(this.comuid)
                      .collection('amounts')
                      .add({
                        reservedAmt
                      });
                  } else {
                    this.afs.collection('supplierVouchers').doc(this.comuid)
                      .collection('amounts')
                      .doc(this.storeAmtsKey)
                      .set({
                        reservedAmt
                      });
                  }
                  const navigationExtras: NavigationExtras = {
                    queryParams: {
                      uid: this.uid
                    }, skipLocationChange: true
                  };
                  this.router.navigate(['/availvouchers'], navigationExtras);
                  this.message = `Successfully added,
                Commission for vouchers is not up-to-date, please set it by going to the supplier and update commission`;

                  this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                  }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                  });
                } else {
                  console.log('Elo');
                  if (!this.storeAmtsKey) {
                    this.afs.collection('supplierVouchers').doc(this.comuid)
                      .collection('amounts')
                      .add({
                        reservedAmt
                      });
                  } else {
                    this.afs.collection('supplierVouchers').doc(this.comuid)
                      .collection('amounts')
                      .doc(this.storeAmtsKey)
                      .set({
                        reservedAmt
                      });
                  }
                  const navigationExtras: NavigationExtras = {
                    queryParams: {
                      uid: this.uid
                    }, skipLocationChange: true
                  };
                  this.router.navigate(['/availvouchers'], navigationExtras);
                  this.message = 'Successfully added';

                  this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                  }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                  });
                }
              });
          } else {
            this.message = `Please ensure that the store name on the spreadsheet is
          the same as the one you are uploading to, the name of the store is -> ` + this.storename + ` but uploaded one is ` + res[0][0];

            this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          }
        };
        fileReader.readAsArrayBuffer(this.file);
        console.log(fileReader);
      } else {
        this.message = 'Wrong document format, only excel documents allowed';

        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  public convertToTable(sheet: any) {
    const result = [];
    let row;
    let rowNum;
    let colNum;
    const range = XLSX.utils.decode_range(sheet['!ref']);
    for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
      row = [];
      for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
        const nextCell = sheet[
          XLSX.utils.encode_cell({ r: rowNum, c: colNum })
        ];
        if (typeof nextCell === 'undefined') {
          row.push(void 0);
        } else {
          row.push(nextCell.w);
        }
      }
      result.push(row);
    }
    return result;
  }
  gotoPromotional() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/specials'], navigationExtras);
  }
  gotoCharge() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/bluelabel'], navigationExtras);
  }
  goBack() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/availvouchers'], navigationExtras);
  }

  ngOnInit() {
    let counter = 0;
    let newerCounter = 0;
    let countNewerCounter = 0;
    let loadedVouchers = [];
    const totalLength = 0;
    this.vcount = 0;
    this.numVouchers = 0;
    console.log(this.comuid);
    const unsubAmt = this.operationsService.getAmounts(this.comuid).subscribe((data) => {
      unsubAmt.unsubscribe();
      if (data.length === 0) {

      } else {
        this.storeAmtsKey = data[0].payload.doc.id;
        console.log(this.storeAmtsKey);
        console.log(data[0].payload.doc.data());
        const voucherAmt = data[0].payload.doc.data();
        this.storeAmt = voucherAmt.reservedAmt;
        console.log(this.storeAmt);
      }
    });
    this.operationsService.getVoucher(this.comuid).pipe(take(1)).toPromise().then((data: any) => {
      this.hideloading = false;
      console.log(data);
      if (data.length >= 1 ) {
        this.commission = data[0].commission;
        this.discount = data[0].discount;
        this.serviceCharge = data[0].serviceCharge;
        console.log(this.serviceCharge);
      } else {
        console.log();
        this.commission = '-1';
        this.discount = '0';
        this.serviceCharge = '0';
      }

      this.checkVouchersLength = data.length;
      console.log(this.checkVouchersLength);
      if (this.checkVouchersLength === 0) {

      } else {
        this.currentVouchers = _.first(data);
        // loadedVouchers.push(this.currentVouchers.storeAllVouchers);

        this.loadedVouchers = this.currentVouchers.storeAllVouchers;
        loadedVouchers = this.currentVouchers.storeAllVouchers;
        const a = loadedVouchers.slice(2, 10);
        console.log(a);
        console.log(this.loadedVouchers);
        this.getResult = _.chain(this.loadedVouchers)
          .groupBy('amount')
          .toPairs()
          .map((currentItem) => {
            console.log(currentItem);
            counter = counter + 1;
            const getItems = {
              i: counter,
              amount: currentItem[0],
              numberOfVouchers: currentItem[1].length
            };
            this.countAmts.push(getItems);
            return _.toPlainObject(_.zip(['amount', 'cardNumber', 'cvv'], currentItem));
          })
          .value();

        this.countAmts = _.orderBy(this.countAmts, ['numberOfVouchers'], ['desc']);
        // this.vouchersBought = data1.length;

        // =================================================================

        console.log(this.countAmts);
        // this.numVouchers = this.currentVouchers.storeAllVouchers.length;
        this.topDate = data[0].date;
        console.log(this.topDate);
        for (let z = 0; z < data.length; z++) {
          console.log(data[z]);
          this.numVouchers = this.numVouchers + data[z].storeAllVouchers.length;
          console.log(this.numVouchers);
        }

        data.splice(0, 1);
        console.log(data);

        for (let i = 0; i !== data.length; i++) {
          this.newerVouchers = data[i];
          console.log(this.newerVouchers);
          this.loadedNewerVouchers = this.newerVouchers.storeAllVouchers;

          this.getNewerResult = _.chain(this.loadedNewerVouchers)
            .groupBy('amount')
            .toPairs()
            .map((currentNewerItem) => {
              console.log(currentNewerItem);
              newerCounter = newerCounter + 1;
              const getItems = {
                i: newerCounter,
                date: this.newerVouchers.date,
                amount: currentNewerItem[0],
                numberOfVouchers: currentNewerItem[1].length
              };
              console.log(getItems);
              this.countNewerAmts.push(getItems);
              return _.toPlainObject(_.zip(['amount', 'cardNumber', 'cvv'], currentNewerItem));
            }).value();
          this.countNewerAmts = _.orderBy(this.countNewerAmts, ['numberOfVouchers'], ['desc']);
          console.log(this.countNewerAmts);

          if (data.length - 1 === i) {
            this.getNewerResult = _.chain(this.countNewerAmts)
              .groupBy('date')
              .toPairs()
              .map((currentDate) => {
                console.log(currentDate);
                countNewerCounter = countNewerCounter + 1;
                const getItems = {
                  i: countNewerCounter,
                  date: currentDate[0],
                  vouchers: currentDate[1]
                };
                console.log(getItems);
                this.countNewerByDate.push(getItems);
                return _.toPlainObject(_.zip(['date'], currentDate));
              }).value();

            console.log(this.countNewerByDate);
          }
        }
      }
      // for (let i = 0; i !== data.length; ++i) {
      //   console.log(data[i]);
      //   this.currentVouchers = data[i];
      //   loadedVouchers.push(this.currentVouchers.storeAllVouchers);
      //   console.log(loadedVouchers);
      //   // for (let x = 0; x !== this.currentVouchers.storeAllVouchers.length; ++x) {
      //   //   console.log(this.currentVouchers.storeAllVouchers);
      //   // }
      // }
    });
    this.operationsService.getClientsDetails(this.comuid).pipe(take(1)).toPromise().then(data => {
      console.log(data);
      this.storename = data.payload.data().storename;

      if (!data.payload.data().isMain) {
        this.showuploadVouchers = false;
      } else if (data.payload.data().isMain === true) {
        this.showuploadVouchers = true;
      } else if (data.payload.data().isMain === false) {
        this.showuploadVouchers = false;
      }
    });

    this.operationsService.getDetails(this.uid).pipe(take(1)).toPromise().then(data => {
      console.log(data);
      if (!data) {
        this.operationsService.getClientsDetails(this.uid).pipe(take(1)).toPromise().then(data2 => {
          this.checkSupplier(data2);
        });
      } else {
        this.checkSupplier(data);
      }
    });
  }

  checkSupplier(data) {
    this.userDetails = data;
    this.name = this.userDetails.name;
    this.TypeOfUser = this.userDetails.userType;
    this.isRedeem = this.userDetails.isRedeem;
    if (!this.isRedeem) {
      this.isRedeem = false;
      console.log(this.isRedeem);
    } else {
      this.isRedeem = true;
      console.log(this.isRedeem);
    }
    if (!this.name) {
      this.name = this.userDetails.fname + ' ' + this.userDetails.sname;
    }
  }
  gotoLockuser() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/lockeduser'], navigationExtras);
  }

  gotoViewVouchers(x) {
    console.log(x);
    console.log(this.logoUrl);
    console.log(this.comuid);
    // this.operationsService.getClientByLogourl(this.logoUrl).subscribe(data => {
    //   console.log(data);
    // });
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid,
        comuid: this.comuid,
        amount: x,
        logoUrl: this.logoUrl,
        originalLogoUrl: this.originalLogoUrl,
        from: 'edit-voucher',
        storeobj: this.storeobj
      }, skipLocationChange: true
    };
    this.router.navigate(['/viewvouchers'], navigationExtras);
  }

  gotoNewerVouchers(x, date) {
    console.log(x);
    console.log(date);
    console.log(this.logoUrl);
    console.log(this.comuid);
    // this.operationsService.getClientByLogourl(this.logoUrl).then(data => {
    //   console.log(data);
    // });
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid,
        comuid: this.comuid,
        amount: x,
        date: date
      }, skipLocationChange: true
    };
    this.router.navigate(['/viewnewer'], navigationExtras);
  }

  gotoLogin() {
    this.operationsService.updateSignInStatus(this.uid, false);
    this.afAuth.auth.signOut();
    this.router.navigateByUrl('/login');
  }

  gotohome() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/home'], navigationExtras);
  }
  gotoVouchersBought() {
    if (this.userDetails.userType === 'superAdmin') {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['selectbought'], navigationExtras);
    } else {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          uid: this.uid
        }, skipLocationChange: true
      };
      this.router.navigate(['/vouches'], navigationExtras);
    }
  }
  gotoAddClients() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-client'], navigationExtras);
  }
  gotoClients() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/clients'], navigationExtras);
  }
  gotoAddNewVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-new-vouchers'], navigationExtras);
  }
  gotoCardTemplates() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/card-templates'], navigationExtras);
  }
  gotoAddCardTemplate() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/add-card-template'], navigationExtras);
  }
  gotoAvailableVouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/availvouchers'], navigationExtras);
  }
  gotoAddCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addsuppcat'], navigationExtras);
  }
  gotoAddtemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/addtempcat'], navigationExtras);
  }
  gotoDeltemplateCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/deltempcat'], navigationExtras);
  }
  gotoDelsupplierCategory() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/delsuppcat'], navigationExtras);
  }
  gotoReemvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeem'], navigationExtras);
  }
  gotoRedeemedvouchers() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        uid: this.uid
      }, skipLocationChange: true
    };
    this.router.navigate(['/redeemedvouchers'], navigationExtras);
  }
  spreadsheetUploadShop(event: any, content) {
    const storeVouchers = [];
    let extractedExcel = [];
    const voucherAmt = [];
    let reservedAmt = [];
    console.log(voucherAmt);
    reservedAmt = this.storeAmt;
    console.log(reservedAmt);
    const row1 = [];
    const row2 = [];
    const row3 = [];
    const storeAllVouchers = [];
    this.file = event.target.files[0];
    console.log(this.file);
    // if (!this.validUntil) {
    //   this.message = 'Please select validity date first to continue';

    //   this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    //     this.closeResult = `Closed with: ${result}`;
    //   }, (reason) => {
    //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    //   });
    // } else {
    if (!this.validUntil) {
      this.validUntil = 'N/A';
    }
      if (this.file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const fileReader = new FileReader();
        console.log(fileReader);
        fileReader.onload = (e) => {
          console.log(e);
          this.arrayBuffer = fileReader.result;

          const data = new Uint8Array(this.arrayBuffer);
          const arr = new Array();
          for (let i = 0; i !== data.length; ++i) {
            arr[i] = String.fromCharCode(data[i]);
          }
          const bstr = arr.join('');
          console.log(bstr);
          const workbook = XLSX.read(bstr, { type: 'binary' });
          console.log(workbook);
          const first_sheet_name = workbook.SheetNames[0];
          console.log(first_sheet_name);
          const worksheet = workbook.Sheets[first_sheet_name];
          console.log(worksheet);
          const res = this.convertToTable(worksheet);
          console.log(res);
          for (let x = 0; x < res[3].length; x += 3) {
            console.log(x);
            const amt = res[3][x];
            console.log(amt);
            if (amt === undefined) {

            } else {

              console.log(amt.length);
              const stripAmt = amt.substr(4, amt.length);
              console.log(stripAmt);
              let lastStrip = stripAmt;
              lastStrip = lastStrip.slice(0, -3);
              console.log(lastStrip);
              if (!reservedAmt || reservedAmt.length === 0) {
                voucherAmt.push(lastStrip);
                reservedAmt.push(lastStrip);
              } else {
                if (reservedAmt.includes(lastStrip) === true) {
                  console.log('exists');
                  voucherAmt.push(lastStrip);
                } else {
                  voucherAmt.push(lastStrip);
                  reservedAmt.push(lastStrip);
                }
              }
            }

          }
          console.log(voucherAmt);
          console.log(res[3].length);
          for (let y = 5; y !== res.length; ++y) {
            for (let z = 0; z < res[3].length; z += 3) {
              const addOne = z + 1;
              console.log(res[y][addOne]);
              if (res[y][addOne] === undefined) {
                const amt = res[3][z];
                console.log(amt);
                console.log(res[y][z]);
                if (res[y][z] === undefined || res[y][z] === ' ') {
                  console.log(res[y][z]);
                } else {
                  console.log('no value');
                  if (amt === undefined) {

                  } else {
                    console.log(amt.length);
                    const stripAmt = amt.substr(4, amt.length);
                    let lastStrip = stripAmt;
                    lastStrip = lastStrip.slice(0, -3);
                    console.log(res[y][z]);

                    const letterNumber = /^[0-9]+$/;
                    this.checkAmount = lastStrip;

                    if (this.checkAmount.match(/[.]/)) {
                      this.message = 'Wrong format on amount';

                      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                        this.closeResult = `Closed with: ${result}`;
                      }, (reason) => {
                        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                      });
                      return;
                    } else {
                      if (this.checkAmount.match(letterNumber)) {
                        storeAllVouchers.push({ cardNumber: res[y][z], amount: lastStrip, isRedeemed: false, isBought: false });
                      } else {
                        this.message = 'Wrong format on amount';

                        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                          this.closeResult = `Closed with: ${result}`;
                        }, (reason) => {
                          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                        });
                        return;
                      }
                    }
                  }
                }
              } else {
                const amt = res[3][z];
                console.log(amt);
                if (amt === undefined) {

                } else {
                  console.log(amt.length);
                  const stripAmt = amt.substr(4, amt.length);
                  let lastStrip = stripAmt;
                  lastStrip = lastStrip.slice(0, -3);

                  if (res[0][0] === 'Woolworths' || res[0][0] === 'woolworths') {
                    console.log(typeof res[y][addOne]);

                    const letterNumber = /^[0-9]+$/;
                    // if (cc )
                    this.checkCvv = res[y][addOne];

                    if (this.checkCvv.match(letterNumber)) {
                      console.log(true);
                      this.checkAmount = lastStrip;

                      const checkPoints = ['.'];
                      if (this.checkAmount.match(/[.]/)) {
                        this.message = 'Wrong format on amount';

                        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                          this.closeResult = `Closed with: ${result}`;
                        }, (reason) => {
                          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                        });
                        return;
                      } else {
                        if (this.checkAmount.match(letterNumber)) {
                          // tslint:disable-next-line:max-line-length
                          storeAllVouchers.push({ cardNumber: res[y][z], cvv: res[y][addOne], amount: lastStrip, isRedeemed: false, isBought: false });
                        } else {
                          this.message = 'Wrong format on amount';

                          this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                            this.closeResult = `Closed with: ${result}`;
                          }, (reason) => {
                            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                          });
                          return;
                        }
                      }
                    } else {
                      console.log(false);
                      this.message = 'Wrong format on CVV number';

                      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                        this.closeResult = `Closed with: ${result}`;
                      }, (reason) => {
                        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                      });
                      return;
                    }
                  } else {
                    const letterNumber1 = /^[0-9]+$/;
                    this.checkAmount = lastStrip;

                    if (this.checkAmount.match(/[.]/)) {
                      this.message = 'Wrong format on amount';

                      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                        this.closeResult = `Closed with: ${result}`;
                      }, (reason) => {
                        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                      });
                      return;
                    } else {
                      if (this.checkAmount.match(letterNumber1)) {
                        storeAllVouchers.push({ cardNumber: res[y][z], amount: lastStrip, isRedeemed: false, isBought: false });
                      } else {
                        this.message = 'Wrong format on amount';

                        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                          this.closeResult = `Closed with: ${result}`;
                        }, (reason) => {
                          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                        });
                        return;
                      }
                    }
                  }
                }
              }
            }

          }
          console.log(storeAllVouchers);
          const newObject = {
            companyName: res[0][0].toLowerCase(),
            campaignNo: res[1][0],
          };
          console.log(newObject);
          extractedExcel = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          console.log(extractedExcel);
          for (let i = 3; i !== extractedExcel.length; ++i) {
            // console.log(i);
            // console.log(JSON.parse(JSON.stringify(extractedExcel[i]) + '.Woolworths'));
            console.log(worksheet.A1.h);
            if (worksheet.A1.h === 'Woolworths') {
              storeVouchers.push({ cvv: extractedExcel[i]['5%'], cardNumber: extractedExcel[i].Woolworths, amount: worksheet.A4.v });
            } else if (worksheet.A1.h === 'Shoprite') {
              storeVouchers.push({ cvv: extractedExcel[i]['5%'], cardNumber: extractedExcel[i].Shoprite, amount: worksheet.A4.v });
            } else if (worksheet.A1.h === 'Takealot') {
              storeVouchers.push({ cvv: extractedExcel[i]['5%'], cardNumber: extractedExcel[i].Takealot, amount: worksheet.A4.v });
            }

          }
          console.log(storeVouchers);
          const com = res[0][1];
          const slicedCom = com.slice(0, -1);
          const dis = res[0][2];
          const slicedDis = dis.slice(0, -1);
          console.log(slicedDis);

          const voucherObj = {
            companyName: res[0][0].toLowerCase(),
            campaignNo: res[1][0],
            commission: slicedCom,
            discount: slicedDis,
            voucherAmt,
            storeAllVouchers
          };
          console.log(voucherObj);
          if (this.storename.toLowerCase() === res[0][0].toLowerCase()) {

            // this.afd.database
            //   .ref('/userProfile')
            //   .child(this.comuid)
            //   .child('/vouchers')
            //   .push({
            //     date: moment().format('MMMM Do YYYY, h:mm:ss'),
            //     companyName: res[0][0].toLowerCase(),
            //     campaignNo: res[1][0],
            //     commission: this.commission,
            //     discount: this.discount,
            //     serviceCharge: this.serviceCharge,
            //     voucherAmt,
            //     storeAllVouchers
            //   })

            this.afs.collection('supplierVouchers').doc(this.comuid)
              .collection('vouchers')
              .add({
                date: moment().format('MMMM Do YYYY, h:mm:ss'),
                validUntil: this.validUntil,
                companyName: res[0][0].toLowerCase(),
                campaignNo: res[1][0],
                commission: this.commission,
                discount: this.discount,
                serviceCharge: this.serviceCharge,
                voucherAmt,
                storeAllVouchers
              })
              .then(data1 => {
                console.log(data1);
                if (this.commission === '-1') {

                  if (!this.storeAmtsKey) {
                    this.afs.collection('supplierVouchers').doc(this.comuid)
                      .collection('amounts')
                      .add({
                        reservedAmt
                      });
                  } else {
                    this.afs.collection('supplierVouchers').doc(this.comuid)
                      .collection('amounts')
                      .doc(this.storeAmtsKey)
                      .set({
                        reservedAmt
                      });
                  }
                  const navigationExtras: NavigationExtras = {
                    queryParams: {
                      uid: this.uid
                    }, skipLocationChange: true
                  };
                  this.router.navigate(['/availvouchers'], navigationExtras);
                  this.message = `Successfully added,
                Commission for vouchers is not up-to-date, please set it by going to the supplier and update commission`;

                  this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                  }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                  });
                } else {
                  if (!this.storeAmtsKey) {
                    this.afs.collection('supplierVouchers').doc(this.comuid)
                      .collection('amounts')
                      .add({
                        reservedAmt
                      });
                  } else {
                    this.afs.collection('supplierVouchers').doc(this.comuid)
                      .collection('amounts')
                      .doc(this.storeAmtsKey)
                      .set({
                        reservedAmt
                      });
                  }
                  const navigationExtras: NavigationExtras = {
                    queryParams: {
                      uid: this.uid
                    }, skipLocationChange: true
                  };
                  this.router.navigate(['/availvouchers'], navigationExtras);
                  this.message = 'Successfully added';

                  this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                  }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                  });
                }
              });
          } else {
            this.message = `Please ensure that the store name on the spreadsheet is
          the same as the one you are uploading to, the name of the store is -> ` + this.storename + ` but uploaded one is ` + res[0][0];

            this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          }
        };
        fileReader.readAsArrayBuffer(this.file);
        console.log(fileReader);
      } else {
        this.message = 'Wrong document format, only excel documents allowed';

        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
  }
}
